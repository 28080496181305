import { FC, ReactElement, useEffect, useState } from "react";
import {
  Box,
  Button,
  Paper,
  TextField,
  Typography,
  Checkbox,
  InputAdornment,
  IconButton,
  Grid,
  Pagination,
} from "@mui/material";
import CustomBreadcrumbs from "../../../../components/CustomBreadcrumbs/CustomBreadcrumbs";
import CustomPopup from "../../../../components/CustomPopup/CustomPopup";
import useWindowSize from "../../../../hooks/useWindowSize";
import { Colors } from "../../../../styles/constant";
import CloseIcon from "@mui/icons-material/Close";
import backArrow from "../../../../assets/icons/back-arrow.svg";
import DeleteIcon from "../../../../assets/icons/trash-can.svg";
import {
  useLazyAddUpdateCustomerMobileQuery,
  useLazyFetchCustomerMobileFromProfileIDQuery,
  useLazyInativeMobileFromProfileQuery,
  useLazyFetchCustomerDetailsFromMobileNumberQuery,
} from "../../UserManagement_Api";
import { API_STATUS_CODE } from "../../../../utils/ApiEndpoints";
import { setError } from "../../../../redux-store/reducers/CsErrorSlice";
import { useDispatch } from "react-redux";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import "./AddUserMobile.scss";
import CustomCheckbox from "../../../../components/CustomCheckbox/CustomCheckbox";
import { CustomFailurePopupBody } from "../../../../components/CustomFailurePopupBody/CustomFailurePopupBody";
import CustomSuccessPopupBody from "../../../../components/CustomSuccessPopupBody/CustomSuccessPopupBody";
import CustomThreeDotsLoader from "../../../../components/CustomThreeDotsLoader/CustomThreeDotsLoader";

type AddUserMobileProps = {
  onBackButtonClick: () => void;
  customerData: any;
};

const AddUserMobile: FC<AddUserMobileProps> = ({
  onBackButtonClick,
  customerData,
}) => {
  const dispatch = useDispatch();
  const { width } = useWindowSize();
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalElements, setTotalElements]=useState<number>(0);
  // const [pageSize] = useState<number>(30);
  const [addUpdateCustomerMobile] = useLazyAddUpdateCustomerMobileQuery();
  const [inactiveMobileFromProfile] = useLazyInativeMobileFromProfileQuery();
  const [fetchCustomerMobileFromProfileID] =
    useLazyFetchCustomerMobileFromProfileIDQuery();
  const [fetchCustomerDetailsFromMobileNumber] =
    useLazyFetchCustomerDetailsFromMobileNumberQuery();
  const [mobileNumbers, setMobileNumbers] = useState<string[]>([
    // "123-456-7890",
    // "234-567-8901",
    // "345-678-9012",
    // "456-789-0123",
    // "567-890-1234",
    // "678-901-2345",
    // "789-012-3456",
    // "890-123-4567",
    // "901-234-5678",
  ]);
  const [selectedNumbers, setSelectedNumbers] = useState<string[]>([]);
  const [deleteNumbers, setDeleteNumbers] = useState<string[]>([]);
  const [mobileWithCsUserIds, setMobileWithCsUserIds] = useState<{
    [key: string]: string;
  }>({});
  const [number, setNumber] = useState<string>();
  const [successpopupOpen, setsuccessPopupOpen] = useState<boolean>(false);
  const [failurepopupOpen, setFailurepopupOpen] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false); // State for loading
  const pageSize=30;

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await fetchCustomerMobileFromProfileID({
        profileId: customerData?.profileId,
        pageSize: pageSize,
        pageNumber: currentPage,
      }).unwrap();

      if (res.payload.data) {
        console.log("Response Payload for customer:", res.payload);
        const mobileMapping = res.payload.data.reduce(
          (
            acc: { [key: string]: string },
            item: { mobileNumber: string; csUserId: string }
          ) => {
            acc[item.mobileNumber] = item.csUserId;
            return acc;
          },
          {}
        );
        setMobileWithCsUserIds(mobileMapping);

        setMobileNumbers(res.payload.data.map((item) => item.mobileNumber));
        setTotalElements(res.payload.totalElements);
        console.log("mobileNumbers", mobileNumbers);
        
      } else {
        console.error("Unexpected response structure:", res);
      }
    } catch (error) {
      console.error("Error fetching features:", error);
    } finally {
      setLoading(false); // Set loading to false after fetching
    }
  };
  console.log("Props passed to AddnewMobile:", customerData);

  useEffect(() => {
    fetchData();
  }, [fetchCustomerMobileFromProfileID,pageSize,currentPage]);

  const handleAdd = async () => {
    console.log("Adding selected numbers:", selectedNumbers);
    setLoading(true);
    try {
      const payload = {
        profileId: customerData?.profileId,
        // profileId:2,
        mobile: phoneNumber,
        adminId: 1,
      };

      const res = await addUpdateCustomerMobile(payload); // Call the mutation and unwrap the response
      console.log(res);
      if (res.error && res.isError) {
        setLoading(false);
        dispatch(
          setError({
            error: new Error("Error"),
            message: "addUpdateCustomerMobile api failed",
          })
        );
      }
      if (res.data?.code === API_STATUS_CODE.SUCCESS) {
        setLoading(false);
        fetchData();
        setsuccessPopupOpen(true);
        setMobileNumbers((prevNumbers) => [...prevNumbers, phoneNumber]);
      } else {
        setLoading(false);
        setFailurepopupOpen(true);
      }
      //   setPhoneNumber(""); // Reset phone number input after adding
    } catch (error) {
      setLoading(false);
      dispatch(
        setError({
          error: new Error("Error"),
          message: "addUpdateCustomerMobile api failed",
        })
      );
      console.error("Error updating customer Mobile Number:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleBackButtonClick = () => {
    onBackButtonClick();
  };

  const handleSelectChange = (number: string) => {
    // Toggle the selection of the number
    setSelectedNumbers((prevSelected) => {
      const updatedSelection = prevSelected.includes(number)
        ? prevSelected.filter((n) => n !== number)
        : [...prevSelected, number];
      console.log("Updated selected numbers:", updatedSelection);
      return updatedSelection;
    });
    console.log("selected number handle select", selectedNumbers);
  };

  console.log("selected number", selectedNumbers);
  useEffect(() => {
    console.log("useEff", selectedNumbers);
    setDeleteNumbers(selectedNumbers);
  }, [selectedNumbers]);
  useEffect(() => {
    if (number === "") {
      fetchData();
    }
  }, [number]);

  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const nameInput = event.target.value;

    setNumber(nameInput);
  };

  const handleSearch = async () => {
    setLoading(true);

    const payload = {
      mobileNumber: number,
    };

    console.log("Deleting numbers with payload:", payload);
    try {
      const res = await fetchCustomerDetailsFromMobileNumber(payload);
      console.log("Response from search request:", res.data?.payload);
      if (res.error && res.isError) {
        setLoading(false);
        dispatch(
          setError({
            error: new Error("Error"),
            message: "fetchCustomerDetailsFromMobileNumber api failed",
          })
        );
      }

      // Step 4: Assuming successful deletion, update the mobileNumbers state
      if (res.data?.code === API_STATUS_CODE.SUCCESS) {
        setLoading(false);
        const searchedMobile = res.data.payload.mobileNumber;
        setMobileNumbers([searchedMobile]);
      }

      // fetchData();
      // else {
      //     setFailurepopupOpen(true);
      //     }
    } catch (error) {
      setLoading(false);
      dispatch(
        setError({
          error: new Error("Error"),
          message: "fetchCustomerDetailsFromMobileNumber api failed",
        })
      );
      console.error("Error deleting selected numbers:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    setLoading(true);
    // if (selectedNumbers.length === 0) {
    //     alert("No numbers selected to delete.");
    //     return;
    // }
    const selectedCsUserIds = selectedNumbers
      .map((number) => mobileWithCsUserIds[number]) // Map the number to its csUserId
      .filter((csUserId) => csUserId !== undefined);

    if (selectedCsUserIds.length === 0) {
      alert("No valid user IDs found for the selected mobile numbers.");
      return;
    }

    const payload = {
      profileId: customerData?.profileId,
      csUserIds: selectedCsUserIds,
    };

    console.log("Deleting numbers with payload:", payload);
    try {
      const res = await inactiveMobileFromProfile(payload);
      console.log("Response from delete request:", res);
      if (res.error && res.isError) {
        setLoading(false);
        dispatch(
          setError({
            error: new Error("Error"),
            message: "inactiveMobileFromProfile api failed",
          })
        );
      }


      if (res.data?.code === API_STATUS_CODE.SUCCESS) {
        setsuccessPopupOpen(true);
        setMobileNumbers((prevNumbers) =>
          prevNumbers.filter((number) => !selectedNumbers.includes(number))
        );
        setSelectedNumbers([]); 
      } 
      if (res.data?.code !== API_STATUS_CODE.SUCCESS) {
        setFailurepopupOpen(true);
      }
    } catch (error) {
      setLoading(false);
      dispatch(
        setError({
          error: new Error("Error"),
          message: "inactiveMobileFromProfile api failed",
        })
      );
      console.error("Error deleting selected numbers:", error);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };

  if (loading) {
    return (
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{ height: "100vh" }}
      >
        <CustomThreeDotsLoader />
      </Box>
    );
  } else {
    return (
      <Box className="add-user-mobile">
        <Box className="nav-bar-breadcrumbs">
          <Button
            className="add-user-back-button"
            startIcon={<img src={backArrow} alt="Expand Icon" />}
            onClick={handleBackButtonClick}
          ></Button>
          <CustomBreadcrumbs
            data={["Customer User Profile", "Add user (Mobile Nos)"]}
          />
        </Box>
        <Box className="add-new-user-container">
          <Typography variant="h3">User List</Typography>

          <Box className="user-phone-number">
            <Typography variant="subtitle2">
              Please enter the Phone Number
            </Typography>
            <Box className="user-phone-number-box">
              <TextField
                className="user-phone-number-box-textfield"
                label="Phone Number"
                variant="outlined"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
              <Button
                variant="contained"
                onClick={handleAdd}
                sx={{ width: "98px" }}
              >
                Add
              </Button>
            </Box>
          </Box>
          <Box className="user-list-delete-box">
            <Typography variant="subtitle2" color={Colors.descriptionGray}>
              List of Phone Numbers
            </Typography>

            <Button
              className={"mobile-delete-selected"}
              disabled={selectedNumbers.length === 0}
              onClick={() => {
                handleDelete();
              }}
            >
              <img src={DeleteIcon} alt="icon" />
              <Typography variant="h6">Delete Selected</Typography>
            </Button>
          </Box>
          <Box className="search-number-box">
            <TextField
              className="search-number-box-text-field"
              placeholder={"Search Number"}
              InputProps={{
                startAdornment: (
                  <InputAdornment sx={{ marginRight: 0 }} position="start">
                    <IconButton sx={{ padding: 0 }}>
                      <SearchOutlinedIcon sx={{ color: "#797979" }} />
                    </IconButton>
                  </InputAdornment>
                ),
                sx: {
                  backgroundColor: "#FFFFFF",
                  border: "1px solid #F1F3F6",
                  boxShadow: "0px -4px 12px 0px #AEAEB70A",
                  "& input::placeholder": {
                    color: "#797979",
                    fontSize: "14px",
                    fontWeight: "400",
                  },
                },
              }}
              value={number}
              onChange={handleChangeName}
            />
            <Button
              variant="contained"
              sx={{ width: "93px" }}
              onClick={() => {
                handleSearch();
              }}
            >
              search
            </Button>
          </Box>

          <Box
            sx={{
              borderRadius: "15px",
              overflow: "hidden",
              border: "2px solid #ddd",
            }}
          >
            <Grid container spacing={0}>
              {mobileNumbers.map((number, index) => {
                return (
                  <Grid item xs={12} sm={4} key={number}>
                    <Paper
                      sx={{
                        gap: "8px",
                        padding: "12px",
                        display: "flex",
                        alignItems: "center",
                        boxShadow: "none",
                        border: "1px solid #ddd",
                        borderRadius: 0,
                      }}
                    >
                      <CustomCheckbox
                        checked={selectedNumbers.includes(number)}
                        onChange={() => handleSelectChange(number)}
                        name={""}
                        label={""}
                        value={""}
                        type={""}
                      />
                      <Typography variant="body2">{number}</Typography>
                    </Paper>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
          <Box className="customer-profile-footer">
            <Pagination
              className="custom-pagination"
              // count={Math.ceil(mobileNumbers.length)}
              count={Math.ceil(totalElements/pageSize)}
              page={currentPage+1}
              onChange={(event, newPage) =>
                handlePageChange(event, newPage - 1)
              }
              variant="outlined"
              shape="rounded"
            />
          </Box>
        </Box>
        {successpopupOpen && (
          <CustomPopup open={successpopupOpen} handleOpen={() => {}}>
            <CustomSuccessPopupBody
              title="Success"
              des="your changes have been saved"
              btnLabel="Continue"
              // btnOnClick={handleClosePopup}
              btnOnClick={() => {
                setsuccessPopupOpen(false);
              }}
            />
          </CustomPopup>
        )}
        {failurepopupOpen && (
          <CustomPopup open={failurepopupOpen} handleOpen={() => {}}>
            <CustomFailurePopupBody
              btnClick={() => setFailurepopupOpen(false)}
            />
          </CustomPopup>
        )}
      </Box>
    );
  }
};

export default AddUserMobile;
