import { FC, useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import "./AdminAddNewUser.scss";

import { Colors } from "../../../../styles/constant";

import AlertRhombus from "../../../../assets/icons/alert-rhombus.svg";
import CustomBreadcrumbs from "../../../../components/CustomBreadcrumbs/CustomBreadcrumbs";

import useWindowSize from "../../../../hooks/useWindowSize";
import { isValidateEmail } from "../../../../utils/helper";
import CustomPopup from "../../../../components/CustomPopup/CustomPopup";
import CommonSuccessPopupBody from "../../../../components/CustomSuccessPopupBody/CustomSuccessPopupBody";

import {
  useLazyCreateUpdateAdminDetailsQuery,
  useLazyFetchAllAdminDetailsQuery,
  useLazyFetchAllProfileDetailsQuery,
} from "../../UserManagement_Api";
import { API_STATUS_CODE } from "../../../../utils/ApiEndpoints";
import { setError } from "../../../../redux-store/reducers/CsErrorSlice";
import { useDispatch } from "react-redux";
import LockIcon from "../../../../assets/icons/lockIcon.svg";

import CustomSelect from "../../../../components/CustomSelect/CustomSelect";
import CustomSuccessPopupBody from "../../../../components/CustomSuccessPopupBody/CustomSuccessPopupBody";

import backArrow from "../../../../assets/icons/back-arrow.svg";
import CustomThreeDotsLoader from "../../../../components/CustomThreeDotsLoader/CustomThreeDotsLoader";
import CustomAPIFailurePopup from "../../../../components/CustomAPIFailurePopup/CustomAPIFailurePopup";
import { CustomFailurePopupBody } from "../../../../components/CustomFailurePopupBody/CustomFailurePopupBody";
interface Feature {
  featureName: string;
  isView: boolean;
  isModify: boolean;
}

interface Lob {
  title: string;
  description: string;
  isChecked: boolean;
  features: Feature[];
}

interface Profile {
  profileId: number; // Assuming profileId is a string, change to number if needed
  profileName: string;
  isActive: boolean;
  isAdmin: boolean;
}

interface Admin {
  // id: string;
  name: string;
  email: string;
}

interface FetchResponse {
  payload: Profile[] | null;
}

type AddNewUserProps = {
  userData: any;
  handleBackBtn: () => void;
};

const AdminAddNewUser: FC<AddNewUserProps> = ({ userData, handleBackBtn }) => {
  const dispatch = useDispatch();
  const { width } = useWindowSize();
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [nameErrorMessage, setNameErrorMessage] = useState<string>("");
  const [emailErrorMessage, setmailErrorMessage] = useState<string>("");
  const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
  const [profileFieldSelected, setProfileFieldSelected] = useState<string>("");

  const [profileId, setProfileId] = useState<number>(0); //gave default as 0 incase user has not selected any

  const [profiles, setProfiles] = useState<Profile[]>([]);

  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [showFailure, setShowFailure] = useState<boolean>(false);

  const [fetchProfileDetailsLoading, setFetchProfileDetailsLoading] =
    useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [duplicateCheck, setDuplicateCheck] = useState<string | undefined>("");
  const [pageNumberValue, setPageNumberValue] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);

  const [createUpdateAdminDetails] = useLazyCreateUpdateAdminDetailsQuery();
  const [fetchAllProfileDetails] = useLazyFetchAllProfileDetailsQuery();

  const fetchData = async () => {
    setFetchProfileDetailsLoading(true); // Set loading to true before fetching
    try {
      const res = await fetchAllProfileDetails({
        // isActive: true,
        isAdmin: true,
        pageNumber: pageNumberValue,
        pageSize: 10,
      }).unwrap(); // Use unwrap to handle errors easily

      if (res.payload.data) {
        setTotalPages(res.payload?.totalPages);
        let data = [...profiles, ...res.payload?.data];
        setProfiles(data);
      } else {
        setFetchProfileDetailsLoading(false);
        dispatch(
          setError({
            error: new Error("Error"),
            message: "fetchAllProfileDetails api failed",
          })
        );
        console.error("Unexpected response structure:", res);
      }
    } catch (error) {
      setFetchProfileDetailsLoading(false);
      dispatch(
        setError({
          error: new Error("Error"),
          message: "fetchAllProfileDetails api failed",
        })
      );
      console.error("Error fetching features:", error);
    } finally {
      setFetchProfileDetailsLoading(false); // Set loading to false after fetching
    }
  };
  useEffect(() => {
    fetchData();
  }, [pageNumberValue]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     setFetchProfileDetailsLoading(true); // Set loading to true before fetching
  //     try {
  //       const res = await fetchAllProfileDetails({
  //         // isActive: true,
  //         isAdmin: true,
  //       }).unwrap(); // Use unwrap to handle errors easily

  //       if (res.payload.data) {
  //         setProfiles(res.payload?.data);
  //       } else {
  //         console.error("Unexpected response structure:", res);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching features:", error);
  //     } finally {
  //       setFetchProfileDetailsLoading(false); // Set loading to false after fetching
  //     }
  //   };

  //   fetchData();
  // }, [fetchAllProfileDetails]);

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    if (fetchProfileDetailsLoading) return;
    const bottom =
      event.currentTarget.scrollHeight ===
      event.currentTarget.scrollTop + event.currentTarget.clientHeight;
    console.log(bottom, "bottom");
    console.log(event.currentTarget.clientHeight, "client height");
    console.log(event.currentTarget.scrollTop, "scroll top");
    console.log(event.currentTarget.scrollHeight, "scroll height");

    if (bottom && pageNumberValue < totalPages) {
      // Fetch more data when the user scrolls to the bottom
      setPageNumberValue(pageNumberValue + 1);
      // fetchData();
    }
  };

  const backBtnFunc = () => {
    handleBackBtn();
  };

  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const nameInput = event.target.value;

    const regex = /^[A-Za-z\s]*$/; // Only allows letters, no special characters
    if (nameInput.trim() === "" || !regex.test(nameInput)) {
      setNameErrorMessage("Enter a valid name");
    } else {
      setNameErrorMessage("");
    }

    setName(nameInput);
  };

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const emailInput = event.target.value;
    if (!isValidateEmail(emailInput) || !emailInput) {
      setmailErrorMessage(
        "Please enter a valid Email ID of domain adityabirlacapital.com"
      );
    } else {
      setmailErrorMessage("");
    }

    setEmail(emailInput);
    setIsEmailValid(isValidateEmail(emailInput));
  };

  const isSubmitButtonEnabled = () => {
    return (
      !name ||
      !email ||
      !!nameErrorMessage ||
      !!emailErrorMessage ||
      !profileFieldSelected
    );
  };

  const isSubmitEnabledforModifyUser = () => {
    return !profileFieldSelected;
  };

  const selectProfileDropDownOptions = profiles
    // .filter((profile) => profile.isAdmin && profile.isActive)
    .map((profile) => ({
      label: profile.profileName,
      value: profile.profileName,
    }));

  useEffect(() => {
    if (profileFieldSelected) {
      // Find the profile with the matching profileName
      const selectedProfile = profiles.find(
        (profile) => profile.profileName === profileFieldSelected
      );

      if (selectedProfile) {
        // Update profileId with the corresponding profileId
        setProfileId(selectedProfile.profileId);
      }
    }
  }, [profileFieldSelected]); // This effect runs when profileFieldSelected changes

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const payload = {
        profileId: profileId,
        email: userData === null ? email : userData.email,
        phoneNumber: "",
        name: userData === null ? name : userData.name,
        isUpdate: userData === null ? false : true,
        isDeleted: false,
        createOrUpdatedBy: 1,
        adminId: userData === null ? "" : userData.adminId,
      };

      const res = await createUpdateAdminDetails(payload); // Call the mutation and unwrap the response
      if (res.error && res.isError) {
        setLoading(false);
        dispatch(
          setError({
            error: new Error("Error"),
            message: "createupdateadmindetails api failed",
          })
        );
      }
      if (res.data?.code === API_STATUS_CODE.SUCCESS) {
        setShowSuccess(true);
      } else {
        if (res.data?.errorCode === "GAAS_ABFSSL_MS_AUAD_400") {
          setDuplicateCheck(res.data?.rootCause);
        } else {
          setDuplicateCheck(undefined);
        }
        setShowFailure(true);
      }
    } catch (error) {
      setLoading(false);
      dispatch(
        setError({
          error: new Error("Error"),
          message: "createupdateadmindetails api failed",
        })
      );
      console.error("Error creating user:", error);
    } finally {
      setLoading(false); // Set loading to false after fetching
    }
  };

  return (
    <>
      {showFailure && (
        <CustomPopup open={showFailure} handleOpen={() => {}}>
          <CustomFailurePopupBody
            message={duplicateCheck}
            btnLabel="Try again"
            btnClick={() => {
              setShowFailure(false);
            }}
          />
        </CustomPopup>
      )}
      {showSuccess && (
        <CustomPopup open={showSuccess} handleOpen={() => {}}>
          <CustomSuccessPopupBody
            title="Success"
            des="your changes have been saved"
            btnLabel="Continue"
            btnOnClick={handleBackBtn}
          />
        </CustomPopup>
      )}
      <Box>
        <Container>
          <Box className="header-container">
            <Button
              className="back-button-admin-user"
              startIcon={<img src={backArrow} alt="Expand Icon" />}
              onClick={backBtnFunc}
            ></Button>
            <CustomBreadcrumbs
              data={[
                "User Management-Admin User List",
                userData === null ? "Add New User" : "Modify Existing User",
              ]}
            />
          </Box>
          <Box className="admin-user-header-title">
            <Typography variant="h3">
              {userData === null ? "Add New User" : "Modify Existing User"}
            </Typography>
          </Box>
          <Box className="admin-add-new-user">
            <Box className="admin-add-new-user-header">
              <Typography variant="subtitle2">Please enter name</Typography>
              <TextField
                label="Name"
                value={userData === null ? name : userData?.name}
                disabled={userData === null ? false : true}
                onChange={handleChangeName}
                fullWidth
                InputProps={{
                  endAdornment: userData?.name ? (
                    <InputAdornment position="end">
                      <img
                        src={LockIcon} // Path to your image
                        alt="lock icon"
                      />
                    </InputAdornment>
                  ) : null,
                }}
              />
              {nameErrorMessage && (
                <InputAdornment sx={{ marginTop: "1.5rem" }} position="start">
                  {<img src={AlertRhombus} />}
                  <Typography
                    sx={{ marginLeft: ".3125rem" }}
                    color={Colors.red}
                    variant="h6"
                  >
                    {" "}
                    {nameErrorMessage}
                  </Typography>
                </InputAdornment>
              )}

              <Typography variant="subtitle2">Please enter email</Typography>
              <TextField
                label="Email"
                value={userData === null ? email : userData?.email}
                disabled={userData === null ? false : true}
                onChange={handleChangeEmail}
                fullWidth
                InputProps={{
                  endAdornment: userData?.email ? (
                    <InputAdornment position="end">
                      <img
                        src={LockIcon} // Path to your image
                        alt="lock icon"
                        // style={{ width: 24, height: 24, cursor: 'pointer' }}  // Adjust size as needed
                      />
                    </InputAdornment>
                  ) : null,
                }}
              />
              {emailErrorMessage && (
                <InputAdornment sx={{ marginTop: "1.5rem" }} position="start">
                  {<img src={AlertRhombus} />}
                  <Typography
                    sx={{ marginLeft: ".3125rem" }}
                    color={Colors.red}
                    variant="h6"
                  >
                    {" "}
                    {emailErrorMessage}
                  </Typography>
                </InputAdornment>
              )}
              <Typography variant="subtitle2">Please select profile</Typography>
              <Box sx={{ width: "100%" }}>
                <CustomSelect
                  value={profileFieldSelected}
                  onChange={setProfileFieldSelected}
                  label={"Please select profile"}
                  options={selectProfileDropDownOptions}
                  onScroll={handleScroll}
                />
              </Box>
              {profiles.length <= 0 && (
                <InputAdornment
                  sx={{ marginTop: "1rem", marginBottom: "1rem" }}
                  position="start"
                >
                  {<img src={AlertRhombus} />}
                  <Typography
                    sx={{ marginLeft: ".3125rem" }}
                    color={Colors.red}
                    variant="h6"
                  >
                    {"No profiles found.Create a profile first."}
                  </Typography>
                </InputAdornment>
              )}

              {loading ? (
                <CustomThreeDotsLoader />
              ) : (
                <Button
                  variant="contained"
                  fullWidth
                  disabled={
                    userData === null
                      ? isSubmitButtonEnabled()
                      : isSubmitEnabledforModifyUser()
                  }
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              )}
            </Box>
          </Box>

          {/* <CustomPopup
              className={`admin-common-popup ${
                width > tabViewPoint ? "centered" : ""
              }`}
              open={false}
              handleOpen={() => {}}
              btnLabel="Close"
              btnStartIcon={<CloseIcon sx={{ color: Colors.red }} />}
            >
              <CommonSuccessPopupBody
                title={"Successful"}
                des={"Lorem ipsum"}
                btnLabel={"Close"}
              />
            </CustomPopup> */}
        </Container>
      </Box>
    </>
  );
};

export default AdminAddNewUser;
