import { FC, useEffect, useState } from "react";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import "./ChannelManagement.scss";
import CustomPopup from "../../../components/CustomPopup/CustomPopup";
import { Colors } from "../../../styles/constant";
import { useNavigate } from "react-router-dom";
import CustomCheckbox from "../../../components/CustomCheckbox/CustomCheckbox";
import UserAccordion from "../../../components/UserAccordion/UserAccordion";
import {
  useLazyCreateUpdateChannelQuery,
  useLazyFetchAllChannelsQuery,
  useLazyFetchFeaturesByChannelQuery,
} from "../UserManagement_Api";
import CustomThreeDotsLoader from "../../../components/CustomThreeDotsLoader/CustomThreeDotsLoader";
import CustomSuccessPopupBody from "../../../components/CustomSuccessPopupBody/CustomSuccessPopupBody";
import { CustomFailurePopupBody } from "../../../components/CustomFailurePopupBody/CustomFailurePopupBody";
import { debounce, isEmpty } from "lodash";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import { API_STATUS_CODE } from "../../../utils/ApiEndpoints";
import { useAppSelector } from "../../../redux-store/CustomReduxHooks";
import { useDispatch } from "react-redux";
import { setAllChannelDetails } from "./ChannelManagement_Slice";
import { setError } from "../../../redux-store/reducers/CsErrorSlice";
import { T_ApiResponse } from "../../../globalTypes/GlobalTypes";
import { T_FetchDetailsByChannel_ResponseObject, T_FetchFeaturesByChannel_Response } from "../T_UserManagementTypes";



interface Option {
  label: string;
  value: string;
}

const ChannelManagement: FC = () => {
  const dispatch = useDispatch();
  const [channelFeaturesData, setChannelFeaturesData] = useState<
  T_FetchDetailsByChannel_ResponseObject[]
  >([]);
  const [channelName, setChannelName] = useState<string>("");
  const [channelID, setChannelID] = useState<string>("");
  const [channelOptions, setChannelOptions] = useState<Option[]>([]);
  const [fetchAllChannels] = useLazyFetchAllChannelsQuery();
  const [fetchFeaturesByChannel] = useLazyFetchFeaturesByChannelQuery();
  const [createUpdateChannel] = useLazyCreateUpdateChannelQuery();
  const [btnLoading, setBtnLoading] = useState<boolean>(false); // State for loading
  const [successPopupOpen, setSuccessPopupOpen] = useState<boolean>(false); // State to track the popup
  const [failurePopupOpen, setFailurePopupOpen] = useState<boolean>(false);

  const channelDetailsFromSlice = useAppSelector(
    (state) => state.ChannelManagementSlice.allChannelDetails
  );

  useEffect(() => {
    setBtnLoading(true);
    fetchAllChannels({})
      .then((res) => {
        if (res.error && res.isError) {
          setBtnLoading(false);
          dispatch(
            setError({
              error: res.error as T_ApiResponse<any>,
              message: "fetchAllChannels api failed",
            })
          );
          return;
        }
        if (
          res?.data?.code === API_STATUS_CODE.SUCCESS &&
          !isEmpty(res.data?.payload)
        ) {
          const channelData = res?.data?.payload.map((channel) => ({
            label: channel.channelName,
            value: channel.channelName,
          }));
          setBtnLoading(false);
          setChannelOptions(channelData);
          dispatch(setAllChannelDetails(res?.data?.payload));
        }
      })
      .catch((err) => {
        setBtnLoading(false);
        dispatch(
          setError({
            error: new Error(err),
            message: "fetchAllChannels api failed",
          })
        );
      });
  }, []);

  useEffect(() => {
    if (channelName) {
      const channelId =
        channelDetailsFromSlice.find((channel) => {
          return channel.channelName === channelName;
        })?.channelId || "";
      setBtnLoading(true);
      fetchFeaturesByChannel({ channelId: channelId })
        .then((res) => {
          if (res.error && res.isError) {
            setBtnLoading(false);
            dispatch(
              setError({
                error: res.error as T_ApiResponse<any>,
                message: "fetchFeaturesByChannel api failed",
              })
            );
            return;
          }
          if (
            res?.data?.code === API_STATUS_CODE.SUCCESS &&
            !isEmpty(res?.data.payload) 
          ) {
            setBtnLoading(false);
            console.log("res?.data?.payload",res?.data?.payload.details)
            setChannelID(res?.data?.payload?.channelId)
            setChannelFeaturesData(res?.data?.payload.details);
          } else {
           
            setBtnLoading(false);
            setChannelFeaturesData([]);
          }
        })
        .catch((err) => {
          setBtnLoading(false);
          dispatch(
            setError({
              error: new Error(err),
              message: "fetchFeaturesByChannel api failed",
            })
          );
        });
    }
  }, [channelName]);

  useEffect(() => {
    console.log("channelFeaturesData123",channelFeaturesData)
    if (channelFeaturesData.length > 0) {
 
    setChannelFeaturesData(channelFeaturesData);
    }
  }, [setChannelFeaturesData]);

  const isSubmitButtonEnabled = !channelName;

  useEffect(() => {
    setChannelFeaturesData([]);
  }, [channelName]);

  const handleSubmit = async () => {
    const formattedFeatures = channelFeaturesData
    .map((f) => f.features.map((i:any) => ({
      featureId: i.featureId,
      isActive: i.access,
    })))
    .flat();

    const payload = {
      channelId: channelID,
      features: formattedFeatures,
      adminId: "106", // currently static id has been send once login implemented dynamic id will be send
    };
    setBtnLoading(true);
    await createUpdateChannel(payload)
      .then((res) => {
        if (res.error && res.isError) {
          setBtnLoading(false);
          dispatch(
            setError({
              error: res.error as T_ApiResponse<any>,
              message: "fetchFeaturesByChannel api failed",
            })
          );
          return;
        }
        if (res?.data?.code === API_STATUS_CODE.SUCCESS) {
          setBtnLoading(false);
          setSuccessPopupOpen(true); // Show success popup only when API is successful
        } else {
          setBtnLoading(false);
          setFailurePopupOpen(true);
        }
      })
      .catch((err) => {
        dispatch(
          setError({
            error: err,
            message: err.message,
          })
        );
      });
  };

  const handleSelect = (value: boolean, identifier: string, type: string) => {
    const [channelIndex, featureIndex] = identifier.split(",").map(Number);

    setChannelFeaturesData((prevChannelData: any) => {
      // Create a copy of the lobList to ensure we don't mutate the original state
      let updatedChannelFeatureData = [...prevChannelData];
    
      // Deep copy of the lob object and its features to avoid mutating directly
      let updatedChannelFeature = {
        ...updatedChannelFeatureData[channelIndex],
      };
      updatedChannelFeature.features = [
        ...updatedChannelFeatureData[channelIndex].features,
      ]; // Create a copy of features

      if (type === "Access") {
        updatedChannelFeature.features[featureIndex] = {
          ...updatedChannelFeature.features[featureIndex], // Create a copy of the feature object
          access: !value, // Toggle the access property
        };
      }

      // Replace the original lobList entry with the updated one
      updatedChannelFeatureData[channelIndex] = updatedChannelFeature;
      return updatedChannelFeatureData; // Return the updated lobList with new references
    });

  
  };

  return (
    <Box className="channel-management-main-box">
      <Box className="channel-header-title">
        <Typography variant="h3" color={Colors.darkCharcoal}>
          Channel Management
        </Typography>
      </Box>
      <Box className="channel-management-selection">
        <Typography variant="subtitle2" color={Colors.descriptionGray}>
          Please select channel
        </Typography>
        <CustomSelect
          value={channelName}
          onChange={setChannelName}
          options={channelOptions}
        />
      </Box>
      <Box className="channel-management-features-list">
        {/* <Box className="channel-management-items-view"> */}
          {channelFeaturesData.map((data: any, index: number) => {
            return (
              <UserAccordion key={index} title={data.downstreamKey}>
                <TableContainer
                  className="customer-lob-table"
                  component={Paper}
                >
                  <Table>
                    <TableHead>
                      <TableRow style={{ display: "flex", width: "100%" }}>
                        <TableCell
                          style={{
                            flex: 1,
                            textAlign: "left",
                            padding: ".625rem",
                          }}
                        >
                          <Typography variant="h5">Features</Typography>
                        </TableCell>
                        <TableCell
                          style={{
                            flex: 3,
                            textAlign: "left",
                            padding: ".625rem",
                          }}
                        >
                          <Typography variant="h5">Access</Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.features.map((data: any, dIndex: number) => {
                        return (
                          <TableRow
                            key={dIndex}
                            style={{ display: "flex", width: "100%" }}
                          >
                            <TableCell
                              style={{
                                flex: 1,
                                textAlign: "left",
                                padding: ".625rem",
                              }}
                            >
                              <Typography
                                variant="h6"
                                fontWeight="500"
                                lineHeight="1.125rem"
                                color="#212121"
                              >
                                {data.featureDisplayName}
                              </Typography>
                            </TableCell>

                            <TableCell
                              style={{
                                flex: 3,
                                textAlign: "left",
                                padding: ".625rem",
                              }}
                            >
                              <CustomCheckbox
                                checked={data.access}
                                onChange={() =>
                                  handleSelect(
                                    data.access,
                                    `${index},${dIndex}`,
                                    "Access"
                                  )
                                }
                                label=""
                                name=""
                                value={`${index},${dIndex}`}
                                type="Access"
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </UserAccordion>
            );
          })}

          {btnLoading ? (
          <Box className="loader" sx={{display:"flex",justifyContent:"center"}}><CustomThreeDotsLoader /></Box>
          ) : (
            <Button
              variant="contained"
              onClick={handleSubmit}
              fullWidth
              disabled={isSubmitButtonEnabled}
            >
              Submit
            </Button>
          )}
        {/* </Box> */}
      </Box>

      <CustomPopup open={successPopupOpen} handleOpen={() => {}}>
        <CustomSuccessPopupBody
          title="Success"
          des="your changes have been saved"
          btnLabel="Continue"
          btnOnClick={() => {
            setSuccessPopupOpen(false);
          }}
        />
      </CustomPopup>

      {failurePopupOpen && (
        <CustomPopup open={failurePopupOpen} handleOpen={() => {}}>
          <CustomFailurePopupBody btnClick={() => setFailurePopupOpen(false)} />
        </CustomPopup>
      )}
    </Box>
  );
};

export default ChannelManagement;
