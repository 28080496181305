import { FC, useCallback } from "react";
import CustomPopup from "../CustomPopup/CustomPopup";
import { Box, Button, Typography } from "@mui/material";
import noConnectionImage from "../../assets/images/no-connection.svg";
import ErrorImg from "../../assets/images/Error.png";
import { Colors } from "../../styles/constant";

interface Props {
  open: boolean;
  handleOpen: Function;
  customTitle?: string;
  customSubTitle?: string;
  customButtonLabel?: string;
}

const APIFailurePopup: FC<Props> = ({
  open,
  handleOpen,
  customTitle = "failure",
  customSubTitle = `Your changes could not be saved`,
  customButtonLabel = `Try again`,
}) => {
  const handleClick = useCallback(() => {
    handleOpen(false);
  }, [handleOpen]);
  return (
    <CustomPopup
      open={open}
      handleOpen={handleOpen}
      className="api-failure-popup"
    >
      <Box padding="1.5rem 1rem">
        <Box display="flex" justifyContent="center">
          <img src={noConnectionImage} alt="no-connection" />
        </Box>
        <Box marginTop="1.5rem">
          <Typography textAlign="center" variant="h3">
            {customTitle}
          </Typography>
          <Typography
            marginTop="1rem"
            textAlign="center"
            variant="h5"
            paddingX="1.25rem"
            color={Colors.textGray}
          >
            {customSubTitle}
          </Typography>
        </Box>
        {
          <Button
            fullWidth
            variant="contained"
            sx={{ marginTop: "1rem" }}
            onClick={handleClick}
          >
            {customButtonLabel}
          </Button>
        }
      </Box>
    </CustomPopup>
  );
};

export default APIFailurePopup;
