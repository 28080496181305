import { FC, useState } from "react";
import "./CustomHelpComponent.scss";
import HelpIcon from "../../assets/icons/web-help-icon.svg";
import CustomHelpPopup from "../CustomHelpPopup/CustomHelpPopup";

const CustomHelpComponent: FC = () => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <img
        src={HelpIcon}
        onClick={() => setOpen(true)}
        alt="help-icon"
        className="help-icon"
      />
      {open && <CustomHelpPopup open={open} handleOpen={setOpen} />}
    </>
  );
};

export default CustomHelpComponent;
