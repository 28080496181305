import { FC } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import CustomBreadcrumbs from "../../components/CustomBreadcrumbs/CustomBreadcrumbs";
import CustomHelpComponent from "../../components/CustomHelpComponent/CustomHelpComponent";
import { useLocation } from "react-router-dom";
import "./ReportsList.scss";
import { reportsListArray } from "../../utils/constants";
import reportIcon from "../../assets/icons/reportIcon.svg";
import totalReportIcon from "../../assets/icons/total-report-black.svg";

const ReportsList: FC = () => {
  const { state } = useLocation();
  console.log("state", state);
  return (
    <Box className="report-list-page">
      <Box display="flex" alignItems="center" justifyContent="space-evenly">
        <CustomBreadcrumbs
          data={[
            `${state?.title} ${state?.description}`,
            "Reports",
            "List Reports",
          ]}
        />
        <CustomHelpComponent />
      </Box>
      <Box className="content-wrapper">
        <Typography marginY="1.5rem" variant="h4">
          Report List
        </Typography>
        <Box className="content-card-wrapper">
          <Box
            display="flex"
            alignItems="center"
            marginBottom="1rem"
            columnGap="0.5rem"
          >
            <img src={totalReportIcon} />
            <Typography variant="h5">Total number of Reports (23)</Typography>
          </Box>
          <Box>
            <Grid container sx={{ margin: 0 }} rowGap="1rem">
              {reportsListArray.map((item) => {
                return (
                  <Grid
                    item
                    xs={6}
                    key={item.id}
                    sx={{ paddingRight: item.id % 2 !== 0 ? "1rem" : "0" }}
                  >
                    <Box className="report-card">
                      <Box className="card-content-wrapper">
                        <Box className="img-wrapper">
                          <img src={reportIcon} />
                        </Box>
                        <Box>
                          <Typography variant="h5">{item.title}</Typography>
                          <Typography
                            marginTop="0.25rem"
                            variant="h5"
                            fontWeight={400}
                          >
                            {item.description}
                          </Typography>
                        </Box>
                      </Box>
                      <Box display="flex" columnGap="1rem">
                        <Button className="email-btn" variant="outlined">
                          Email
                        </Button>
                        <Button className="download-btn" variant="outlined">
                          Download
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          marginTop={"1rem"}
        >
          <Typography variant="h5" fontWeight={500} marginRight="auto">
            Showing 0-10 of 30
          </Typography>
          <Button
            sx={{ marginRight: "auto", marginLeft: "-7.5rem" }}
            variant="text"
          >
            View All
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ReportsList;
