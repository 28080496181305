import { FC, useState, useEffect } from "react";
import "./MPINchangeReport.scss";

import ReportDuration from "../ReportDuration";
import CustomEmail from "../../../components/CustomEmail/CustomEmail";
import { downloadExcelFromBase64 } from "../../../utils/helper";
import { setError } from "../../../redux-store/reducers/CsErrorSlice";
import { useAppDispatch } from "../../../redux-store/CustomReduxHooks";
import {
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useLazyFetchUserRegistrationReportByEmailQuery } from "../SRRaiseReport/SRRaiseReports_Api";
import {
  useLazyChangeMPINDetailsQuery,
  useLazyChangeMPINDownloadQuery,
} from "./MPINChangeReportApi";

interface OnboardReport {
  abcApp: number;
  abcWeb: number;
  abcdApp: number;
}

const MPINChangeReport: FC = () => {
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [reportType, setReportType] = useState("");
  const [showReport, setShowReport] = useState(false);
  const [fetchUserRegistrationReportByEmail] =
    useLazyFetchUserRegistrationReportByEmailQuery();
  const [fetchChangeMPINDetailDownload] = useLazyChangeMPINDownloadQuery();
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [emailToSend, setEmailToSend] = useState("");
  const [mpindetail, setMPINDetail] = useState(Number);
  const dispatch = useAppDispatch();
  const [reportDumpFlag, setReportDumpFlag] = useState(false);
  const [fetchChangeMPINDetailReport] = useLazyChangeMPINDetailsQuery();
  const fetchReport = async () => {
    try {
      console.log(
        "first column",
        fromDate,
        toDate,
        typeof fromDate,
        typeof toDate
      );
      const res = await fetchChangeMPINDetailReport({
        fromDate: fromDate,
        toDate: toDate,
      });
      if (res && res?.data?.payload) {
        setMPINDetail(res.data.payload.changeMpinCount);
        console.log("Response Payload:", res.data.payload);
      } else {
        console.error("Unexpected response structure:", res);
      }
    } catch (error) {
      console.error("Error fetching report:", error);
    }
  };

  console.log(mpindetail, "mpindetail>>");

  const fetchReportEmail = async (email: string) => {
    try {
      const res = await fetchUserRegistrationReportByEmail({
        emailId: email,
        fromDate: fromDate,
        toDate: toDate,
        reportDumpFlag: reportDumpFlag,
      });

      if (res && res?.data?.payload) {
        console.log("Email sent to:", email);
        console.log("Response Payload:", res.data.payload);
      } else {
        console.error("Unexpected response structure:", res);
      }
    } catch (error) {
      console.error("Error sending report via email:", error);
    }
  };

  const fetchReportDownload = async () => {
    try {
      const res = await fetchChangeMPINDetailDownload({
        fromDate: fromDate,
        toDate: toDate,
      });

      if (res && res?.data) {
        console.log("res?.data", res?.data?.payload?.fileDetails);
        downloadExcelFromBase64(
          res?.data?.payload?.fileDetails?.attachment,
          res?.data?.payload?.fileDetails?.filename
        );
      } else {
        return dispatch(
          setError({
            error: new Error("Error"),
            message: "XLS Download failed",
          })
        );
      }
    } catch (error) {
      return dispatch(
        setError({
          error: new Error("Error"),
          message: "XLS Download failed",
        })
      );
    }
  };

  useEffect(() => {
    if (showReport) {
      fetchReport();
    }
  }, [showReport, fromDate, toDate]); // Fetch report when showReport, fromDate, or toDate changes

  const handleGenerateReport = (
    selectedFromDate: string,
    selectedToDate: string
  ) => {
    setFromDate(selectedFromDate);
    setToDate(selectedToDate);
    setShowReport(true);
    console.log("From Date:", selectedFromDate, "To Date:", selectedToDate);
  };

  const handleEmail = (email: string) => {
    setEmailToSend(email);
    fetchReportEmail(email);
    setIsEmailModalOpen(false); // Close the modal after sending the email
  };

  const handleDownload = () => {
    fetchReportDownload();
  };
  return (
    <Box className="report-details-page">
      <Typography variant="h3">MPIN Change Report</Typography>
      <ReportDuration onGenerate={handleGenerateReport} />

      {showReport && (
        <Box className="report-table">
          <TableContainer className="table-container" component={Box}>
            <Table>
              <TableHead className="table-header">
                <TableRow>
                  <TableCell className="table-header-cell">
                    MPIN Change Count
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell className="table-body-cell">
                    {mpindetail}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          {/* <FormControl fullWidth variant="outlined">
            <InputLabel id="select-label">Select</InputLabel>
            <Select
              labelId="select-label"
              value={reportType}
              onChange={(e) => {
                const selectedValue = e.target.value;
                setReportType(selectedValue);
                setReportDumpFlag(selectedValue === "selectReportDump"); // Update reportDumpFlag based on selection
              }}
            >
              <MenuItem value="">Select Report</MenuItem>
              <MenuItem value="selectReportDump">Select Report Dump</MenuItem>
            </Select>
          </FormControl> */}
          <Box className="email-download-buttons">
            {/* <Button
              onClick={() => setIsEmailModalOpen(true)}
              className="button"
              variant="outlined"
              fullWidth
            >
              Email
            </Button> */}
            <Button
              onClick={handleDownload}
              className="button"
              variant="outlined"
              fullWidth
            >
              Download
            </Button>
          </Box>
        </Box>
      )}
      {/* <CustomEmail
        isOpen={isEmailModalOpen}
        onClose={() => setIsEmailModalOpen(false)}
        onSubmit={handleEmail}
      /> */}
    </Box>
  );
};

export default MPINChangeReport;
