import { FC, useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  Pagination,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
// import CustomBreadcrumbs from "../../../components/CustomBreadcrumbs/CustomBreadcrumbs";
// import CustomHelpComponent from "../../../components/CustomHelpComponent/CustomHelpComponent";
import PlusIcon from "../../../../assets/icons/plus.svg";
import DeleteIcon from "../../../../assets/icons/trash-can.svg";
import EditIcon from "../../../../assets/icons/edit.svg";
import errorimg from "../../../../assets/icons/error-outline.svg";
import { useLazyDeactivateProfileQuery, useLazyFetchAllProfileDetailsQuery } from "../../UserManagement_Api";
import { useDispatch } from "react-redux";
import { API_STATUS_CODE } from "../../../../utils/ApiEndpoints";
import { setError } from "../../../../redux-store/reducers/CsErrorSlice";
import CustomBreadcrumbs from "../../../../components/CustomBreadcrumbs/CustomBreadcrumbs";
import CustomHelpComponent from "../../../../components/CustomHelpComponent/CustomHelpComponent";
import CustomButton from "../../../../components/CustomAdminButton/CustomAdminButton";
import CustomThreeDotsLoader from "../../../../components/CustomThreeDotsLoader/CustomThreeDotsLoader";
import CustomPopup from "../../../../components/CustomPopup/CustomPopup";
import CustomSuccessPopupBody from "../../../../components/CustomSuccessPopupBody/CustomSuccessPopupBody";
import CustomAPIFailurePopup from "../../../../components/CustomAPIFailurePopup/CustomAPIFailurePopup";

type AdminUserDetailListProps = {
  onAddNewProfileClick: (profileData: any) => void;
};
interface Profile {
  profileName: string;
  profileId: number;
}

const AdminProfileDetailList: FC<AdminUserDetailListProps> = ({
  onAddNewProfileClick,
}) => {
  const dispatch = useDispatch();
  const [profiles, setProfiles] = useState<Profile[]>([]);
  const [fetchAllProfileDetails] = useLazyFetchAllProfileDetailsQuery();
  
  const [selectedProfileId, setSelectedProfileId] = useState<number>(0);
  const [deactiveProfile] = useLazyDeactivateProfileQuery();
  const [deletesuccesspopupOpen, setDeletesuccessPopupOpen] =
    useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [pageSize] = useState<number>(10);
    const [totalElements, setTotalElements]=useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false); // State for loading
  const [deletefailurepopupOpen, setDeleteFailurePopupOpen] =
    useState<boolean>(false);
  const handleAddNewProfileClick = (profileData:any) => {
    onAddNewProfileClick(profileData);
  };
  const [deleteConfirmationPopup, setDeleteConfirmationPopup] =
  useState<boolean>(false);
  
  const handledeletefailurepopup = () => {
    setDeleteFailurePopupOpen(false);
  };
  const fetchData = async () => {
    setLoading(true); // Set loading to true before fetching
    try {
      const res = await fetchAllProfileDetails({
        isAdmin: true,
        pageNumber: currentPage,
        pageSize: pageSize,
      }).unwrap(); // Use unwrap to handle errors easily

      if (res.payload.data) {
        console.log("Response Payload:", res.payload);
        console.log("Response Payload:", res.payload.data);
        // let filteredProfiles = res.payload.data.filter(
        //   (profile) => profile.isAdmin === true
        // );
        setProfiles(res.payload.data);
        setTotalElements(res.payload.totalElements);
      } else {
        setLoading(false);
        dispatch(
          setError({
            error: new Error("Error"),
            message: "fetchAllProfileDetails api failed",
          })
        );
        console.error("Unexpected response structure:", res);
        // setDeleteFailurePopupOpen(true);
      }
    } catch (error) {
      setLoading(false);
      dispatch(
        setError({
          error: new Error("Error"),
          message: "fetchAllProfileDetails api failed",
        })
      );
      console.error("Error fetching features:", error);
    } finally {
      setLoading(false); // Set loading to false after fetching
    }
  };
  useEffect(() => {
    fetchData();
  }, [fetchAllProfileDetails,pageSize, currentPage]); // Add lobID to the dependency array

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };
  const closeDeletePopup = () => {
    setDeleteConfirmationPopup(false);
    setSelectedProfileId(0); // Reset selected profile when popup closes
  };


  const handleDelete = (deletedId: number) => {
    setSelectedProfileId(deletedId);
    setDeleteConfirmationPopup(true);
  };
  const deleteapicall = async (profileid: number) => {
    setLoading(true); // Set loading to true before fetching
    console.log("deleted profile id ",profileid);
    try {
      const payload = {
        profileId: profileid,
        // profileId:1
      };

      const res = await deactiveProfile(payload); // Call the mutation and unwrap the response
      
      console.log(res);
      if (res.error && res.isError) {
        setLoading(false);
        dispatch(
          setError({
            error: new Error("Error"),
            message: "deactiveProfile api failed",
          })
        );
      }
      if (res.data?.code === API_STATUS_CODE.SUCCESS) {
        setDeletesuccessPopupOpen(true);
      }
      if (res.data?.code !== API_STATUS_CODE.SUCCESS) {
        setDeleteFailurePopupOpen(true);
      }
      closeDeletePopup();
      console.log("printing profileid after setting to 0", profileid);
      // Close the popup after deletion is successful
      fetchData();

    } catch (error) {
      setLoading(false);
      dispatch(
        setError({
          error: new Error("Error"),
          message: "deactiveProfile api failed",
        })
      );
      console.error("Error fetching features:", error);
    } finally {
      setLoading(false); // Set loading to false after fetching
    }
  };
  // return (
  //   <Box>
  //     <Container>
  //       <Box
  //         display="flex"
  //         alignItems="center"
  //         gap={{
  //           sm: 10,
  //         }}
  //         sx={{
  //           justifyContent: {
  //             xs: "space-between",
  //             sm: "center",
  //           },
  //         }}
  //       >
  //         <CustomBreadcrumbs data={["User Management", "LAN"]} />
  //         <CustomHelpComponent />
  //       </Box>
  //       <Box className="header-title">
  //         <Typography variant="h3">{"Admin Profile List"}</Typography>
  //         <CustomButton
  //           btnValue={"Add New Profile"}
  //           icon={PlusIcon}
  //           handleClick={()=>{handleAddNewProfileClick(null)}}
  //         />
  //       </Box>
  //       <Box className="list-box">
  //         <Box className="list-box-header">
  //           <Typography variant="h5">
  //             Total Profiles ({profiles.length})
  //           </Typography>
  //         </Box>
  //         <Box className="list-container" display="flex" flexDirection="column">
  //           {profiles.map((profile, index) => (
  //             <Box key={profile.profileId} sx={{ mb: 2 }}>
  //               <Typography
  //                 variant="h6"
  //                 sx={{
  //                   py: 1,
  //                   px: 2,
  //                   justifyContent: "space-between",
  //                   display: "flex",
  //                   alignItems: "center",
  //                 }}
  //                 className="title-value"
  //               >
  //                 <span style={{ width: "30%", margin: 0 }}>Profile Name</span>
  //               </Typography>

  //               <Box
  //                 sx={{
  //                   display: "flex",
  //                   justifyContent: "space-between",
  //                   alignItems: "center",
  //                   py: 1,
  //                   px: 2,
  //                   backgroundColor: "#fff",
  //                 }}
  //               >
  //                 <Typography variant="body1" sx={{ width: "30%", margin: 0 }}>
  //                   {profile.profileName}
  //                 </Typography>
  //                 <Box className="list-box-items-2-buttons">
  //                   <CustomButton
  //                     btnValue={"Modify"}
  //                     icon={EditIcon}
  //                     handleClick={()=>{handleAddNewProfileClick(profile)}}
  //                   />
  //                   <CustomButton
  //                     btnValue={"Delete"}
  //                     className="delete-icon"
  //                     icon={DeleteIcon}
  //                     handleClick={() => {}}
  //                   />
  //                 </Box>
  //                 <Divider
  //                     orientation="horizontal"
  //                     sx={{ color: "#F1F3F6" }}
  //                   />
  //               </Box>
  //             </Box>
  //           ))}
  //         </Box>
  //       </Box>
  //     </Container>
  //   </Box>
  // );
  if (loading) {
    return (
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{ height: "100vh" }}
      >
        <CustomThreeDotsLoader />
      </Box>
    );
  } else {
    return (
      <Box>
        <Container>
          <Box className="customer-profile-header-title">
            <Typography variant="h3">Admin Profile List</Typography>
            <CustomButton
              btnValue={"Add New Profile"}
              icon={PlusIcon}
              handleClick={() => {
                handleAddNewProfileClick(null);
              }}
            />
          </Box>
          <Box className="customer-user-list-box">
            <Box className="customer-user-list-box-header">
              <Typography variant="h5">
                Total Profiles ({profiles.length})
              </Typography>
            </Box>
            <Box>
              <Box className="customer-user-list-container">
                {profiles.map((profile, index) => (
                  <Box key={profile.profileId} sx={{ mb: 2 }}>
                    <Box className="customer-user-list-container-1">
                      <Box className="customer-profile">
                        {/* <Avatar className="customer-otp-avatar">OP</Avatar> */}
                        <Box className="customer-profile-name">
                          <Typography variant="h5" className="title-value">
                            {/* <span style={{ width: '30%', margin: 0 }}>Profile Name</span> */}
                            Profile Name
                          </Typography>
                          <Typography variant="subtitle2">
                            {profile.profileName}
                          </Typography>
                        </Box>
                      </Box>

                      <Box className="customer-user-list-box-items-2-buttons">
                        <CustomButton
                          btnValue={"Modify"}
                          icon={EditIcon}
                          handleClick={() => {
                            handleAddNewProfileClick(profile);
                          }}
                        />
                        <CustomButton
                          btnValue={"Delete"}
                          className="customer-delete-icon"
                          icon={DeleteIcon}
                          handleClick={() => {handleDelete(profile.profileId)}}
                        />
                      </Box>
                    </Box>
                    {/* {profile.profileId % 2 === 0 && <Divider sx={{ width: "100%", color:"blueviolet" }} />} */}
                    <Divider
                      orientation="horizontal"
                      sx={{ color: "#F1F3F6" }}
                    />
                  </Box>
                ))}
              </Box>
            </Box>
            <Box className="customer-profile-footer">
              <Pagination
                className="custom-pagination"
                // count={Math.ceil(profiles.length)}
                count={Math.ceil(totalElements/10)}
                page={currentPage+1}
                onChange={(event, newPage) =>
                  handlePageChange(event, newPage - 1)
                }
                variant="outlined"
                shape="rounded"
              />
            </Box>
          </Box>
          <CustomPopup open={deletesuccesspopupOpen} handleOpen={() => {}}>
            <CustomSuccessPopupBody
              title="Success"
              des="your changes have been saved"
              btnLabel="Continue"
              btnOnClick={()=>setDeletesuccessPopupOpen(false)}
            />
          </CustomPopup>
          <CustomAPIFailurePopup
            open={deletefailurepopupOpen}
            handleOpen={handledeletefailurepopup}
          ></CustomAPIFailurePopup>

          <CustomPopup open={deleteConfirmationPopup} handleOpen={() => {}}>
            <Box className="customer-list-delete-popup">
              <img src={errorimg} alt="delete alert" />
              <Typography
                variant="h4"
                color={"#252525"}
                marginTop={"1.5rem"}
                marginBottom=".9375rem"
              >
                Delete
              </Typography>
              <Typography
                variant="h5"
                color={"#797979"}
                marginBottom={"1.5rem"}
              >
                Are you sure you want to delete?
              </Typography>
              <Box display="flex" gap={"1rem"}>
                <Button
                  variant="outlined"
                  sx={{
                    border: "1px solid #C7222A",
                    padding: "16px 42px 16px 42px",
                    width: "16rem",
                  }}
                  onClick={()=>setDeleteConfirmationPopup(false)}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    padding: "16px 42px 16px 42px",
                    width: "16rem",
                  }}
                  onClick={() => {
                    deleteapicall(selectedProfileId);
                  }}
                >
                  Delete
                </Button>
              </Box>
            </Box>
          </CustomPopup>
        </Container>
      </Box>
    );
  }

};

export default AdminProfileDetailList;
