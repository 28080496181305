import { FC, useState, useEffect } from "react";
import ReportDuration from "../ReportDuration";
import {
  useLazyFetchUserRegistrationReportByDownloadQuery,
  useLazyFetchUserRegistrationReportByEmailQuery,
  useLazyFetchUserRegistrationReportQuery,
} from "./OnboardingCompletionReportAPI";
import CustomEmail from "../../../components/CustomEmail/CustomEmail";
import { downloadExcelFromBase64 } from "../../../utils/helper";
import { setError } from "../../../redux-store/reducers/CsErrorSlice";
import { useAppDispatch } from "../../../redux-store/CustomReduxHooks";
import "./OnboardingCompletionReport.scss";
import {
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import NativeSelect from "@mui/material/NativeSelect";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

interface OnboardReport {
  abcApp: number;
  abcWeb: number;
  abcdApp: number;
}

const OnboardingCompletionReport: FC = () => {
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [reportType, setReportType] = useState("");
  const [showReport, setShowReport] = useState(false);
  const [fetchUserRegistrationReportByEmail] =
    useLazyFetchUserRegistrationReportByEmailQuery();
  const [fetchUserRegistrationReportDownload] =
    useLazyFetchUserRegistrationReportByDownloadQuery();
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [emailToSend, setEmailToSend] = useState("");
  const [onboardReport, setOnboardReport] = useState<OnboardReport>({
    abcApp: 0,
    abcWeb: 0,
    abcdApp: 0,
  });

  const dispatch = useAppDispatch();
  const [reportDumpFlag, setReportDumpFlag] = useState(false);
  const [fetchUserRegistrationReport] =
    useLazyFetchUserRegistrationReportQuery();
  const fetchReport = async () => {
    try {
      console.log(
        "first column",
        fromDate,
        toDate,
        typeof fromDate,
        typeof toDate
      );
      const res = await fetchUserRegistrationReport({
        fromDate: fromDate,
        toDate: toDate,
      });
      if (res && res?.data?.payload) {
        setOnboardReport(res.data.payload);
        console.log("Response Payload:", res.data.payload);
      } else {
        console.error("Unexpected response structure:", res);
      }
    } catch (error) {
      console.error("Error fetching report:", error);
    }
  };

  const fetchReportDownload = async () => {
    try {
      const res = await fetchUserRegistrationReportDownload({
        fromDate: fromDate,
        toDate: toDate,
        reportDumpFlag: reportDumpFlag,
      });

      if (res && res?.data) {
        console.log("res?.data", res?.data?.payload?.fileDetails);
        downloadExcelFromBase64(
          res?.data?.payload?.fileDetails?.attachment,
          res?.data?.payload?.fileDetails?.filename
        );
      } else {
        return dispatch(
          setError({
            error: new Error("Error"),
            message: "XLS Download failed",
          })
        );
      }
    } catch (error) {
      return dispatch(
        setError({
          error: new Error("Error"),
          message: "XLS Download failed",
        })
      );
    }
  };

  useEffect(() => {
    if (showReport) {
      fetchReport();
    }
  }, [showReport, fromDate, toDate]); // Fetch report when showReport, fromDate, or toDate changes

  const handleGenerateReport = (
    selectedFromDate: string,
    selectedToDate: string
  ) => {
    setFromDate(selectedFromDate);
    setToDate(selectedToDate);
    setShowReport(true);
    console.log("From Date:", selectedFromDate, "To Date:", selectedToDate);
  };

  const handleDownload = () => {
    fetchReportDownload();
  };
  return (
    <Box className="report-details-page">
      <Typography variant="h5">Onboarding Completion Report</Typography>
      <ReportDuration onGenerate={handleGenerateReport} />

      {showReport && (
        <Box className="report-table">
          <TableContainer className="table-container" component={Box}>
            <Table>
              <TableHead className="table-header">
                <TableRow>
                  <TableCell className="table-header-cell">
                    CS App User Count{" "}
                  </TableCell>
                  <TableCell className="table-header-cell">ABC App</TableCell>
                  <TableCell className="table-header-cell">ABCD App</TableCell>
                  <TableCell className="table-header-cell">ABC Web</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell className="table-body-cell">
                    New Users Onboarded
                  </TableCell>
                  <TableCell className="table-body-cell">
                    {onboardReport.abcApp}
                  </TableCell>
                  <TableCell className="table-body-cell">
                    {onboardReport.abcdApp}
                  </TableCell>
                  <TableCell className="table-body-cell">
                    {onboardReport.abcWeb}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <Box className="email-download-buttons">
            <Button
              onClick={handleDownload}
              className="button"
              variant="outlined"
              fullWidth
            >
              Download
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default OnboardingCompletionReport;
