import { FC, useState } from "react";
import AdminProfileDetailList from "./AdminProfileDetailList/AdminProfileDetailList";
import AddNewProfile from "./AddNewProfile/AddNewProfile";


const AdminProfileList: FC = () => {
  const [screen, setScreen] = useState<string>("AdminProfileDetailList");
  
  const [profileData, setProfileData] = useState(null);

  const handleAddNewprofile = () => {
    setScreen("AddNewProfile");
  };
  const handleBackButtonClick = () => {
    setScreen("AdminProfileDetailList");
  };
  return (
    <div>
      {screen === "AdminProfileDetailList" ? (
        <AdminProfileDetailList
          onAddNewProfileClick={(list) => {
            console.log(list);
            setProfileData(list);
            handleAddNewprofile();
          }}
         
        />
      ) : (
        <AddNewProfile onBackButtonClick={handleBackButtonClick} profileData={profileData} />
      )}
    </div>
  );
};

export default AdminProfileList;
