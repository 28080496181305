import React from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import "./CustomCheckbox.scss";
import CheckedBox from "../../assets/icons/CheckedBox.svg";
import UnCheckedBox from "../../assets/icons/CheckBox.svg";
interface CustomCheckboxProps {
  checked: boolean;
  onChange: Function;
  name: string;
  label: string;
  value: string;
  type: string;
  isModifyChecked?: boolean;
}

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({
  checked,
  onChange,
  name,
  label,
  value,
  type,
  isModifyChecked,
}) => {
  const handleChange = () => {
    if (type === "View") {
      if (!isModifyChecked) onChange(value, type);
    } else onChange(value, type);
  };

  return (
    <FormControlLabel
      className={`custom-checkbox-form ${label !== "" ? "with-label" : ""}`}
      control={
        <Checkbox
          icon={<img src={UnCheckedBox} alt="unchecked" />}
          checkedIcon={<img src={CheckedBox} alt="checked" />}
          checked={checked}
          onChange={handleChange}
          name={name}
          className={`custom-checkbox`}
        />
      }
      label={label}
    />
  );
};

export default CustomCheckbox;
