import { FC, useState, useEffect } from "react";
import "./RedirectionCountReport.scss";
import ReportDuration from "../ReportDuration";
import {
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  useLazyRedirectionDetailsDownloadQuery,
  useLazyRedirectionDetailsQuery,
} from "./RedirectionCountReport_Api";
import { downloadExcelFromBase64 } from "../../../utils/helper";
import { useAppDispatch } from "../../../redux-store/CustomReduxHooks";
import { setError } from "../../../redux-store/reducers/CsErrorSlice";

// Define the interface for the Redirection Count Report data
interface RedirectionData {
  lob: string;
  feature: string;
  count: number;
}

const RedirectionCountReport: FC = () => {
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [showReport, setShowReport] = useState(false);
  const [onboardReport, setOnboardReport] = useState<RedirectionData[]>([]); // Ensuring it's initialized as an empty array
  const [fetchRedirectionCountReport] = useLazyRedirectionDetailsQuery();
  const [fetchRedirectionReportDownload] =
    useLazyRedirectionDetailsDownloadQuery();
  console.log(onboardReport, "onboardReport>>>");

  const dispatch = useAppDispatch();

  // Fetch the report data
  const fetchReport = async () => {
    try {
      const res = await fetchRedirectionCountReport({
        fromDate: fromDate,
        toDate: toDate,
      });

      if (res && res?.data?.payload) {
        // Ensure the payload is an array before updating the state
        const payload = res.data.payload;
        if (Array.isArray(payload)) {
          setOnboardReport(payload); // Update state with the array
        } else {
          console.error("Payload is not an array:", payload);
          setOnboardReport([]); // Set it to an empty array if the payload is not an array
        }
      } else {
        console.error("Unexpected response structure:", res);
        setOnboardReport([]); // Ensure it's empty if response doesn't have payload
      }
    } catch (error) {
      console.error("Error fetching report:", error);
      setOnboardReport([]); // Ensure it's empty on error
    }
  };

  useEffect(() => {
    if (showReport) {
      fetchReport();
    }
  }, [showReport, fromDate, toDate]);

  const handleGenerateReport = (
    selectedFromDate: string,
    selectedToDate: string
  ) => {
    setFromDate(selectedFromDate);
    setToDate(selectedToDate);
    setShowReport(true);
  };

  const handleGenerateReportDownload = async () => {
    try {
      const res = await fetchRedirectionReportDownload({
        fromDate: fromDate,
        toDate: toDate,
      });

      if (res && res?.data) {
        console.log("res?.data", res?.data?.payload?.fileDetails);
        downloadExcelFromBase64(
          res?.data?.payload?.fileDetails?.attachment,
          res?.data?.payload?.fileDetails?.filename
        );
      } else {
        return dispatch(
          setError({
            error: new Error("Error"),
            message: "XLS Download failed",
          })
        );
      }
    } catch (error) {
      return dispatch(
        setError({
          error: new Error("Error"),
          message: "XLS Download failed",
        })
      );
    }
  };
  return (
    <Box className="report-details-page">
      <Typography variant="h3">Redirection Count Report</Typography>
      <ReportDuration onGenerate={handleGenerateReport} />

      {showReport && (
        <Box className="report-table">
          <TableContainer className="table-container" component={Box}>
            <Table>
              <TableHead className="table-header">
                <TableRow>
                  <TableCell className="table-header-cell">LOB</TableCell>
                  <TableCell className="table-header-cell">Feature</TableCell>
                  <TableCell className="table-header-cell">
                    Redirection Count
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* Check if onboardReport is an array before using .map() */}
                {Array.isArray(onboardReport) &&
                  onboardReport.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell className="table-body-cell">
                        {item.lob}
                      </TableCell>
                      <TableCell className="table-body-cell">
                        {item.feature}
                      </TableCell>
                      <TableCell className="table-body-cell">
                        {item.count}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Box className="email-download-buttons">
            <Button
              onClick={handleGenerateReportDownload}
              // Uncomment and implement download functionality if needed
              className="button"
              variant="outlined"
              fullWidth
            >
              Download
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default RedirectionCountReport;
