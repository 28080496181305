import {
  FetchAllAdminDetails,
  API_TOKEN,
  Access_Token,
  FetchAllProfile,
  CreateUpdateProfile,
  AddUpdateCustomerMobile,
  FetchCustomerProfileByiD,
  Api_CreateUpdateAdminDetails,
  GetFeatureLobwiseByAdminProfile,
  GetMenusModulewiseByAdminProfile,
  FetchCustomerMobileFromProfileID,
  AddUpdateCustomerProfile,
  SubmitAdminProfileMenu,
  DeactivateProfile,
  CheckExistingProfile,
  InativeMobileFromProfile,
  FetchCustomerDetailsFromMobileNumber,
  Api_FetchAllChannels,
  Api_CreateUpdateChannel,
  Api_FetchFeaturesByChannel,
} from "../../utils/ApiEndpoints";
import { T_ApiResponse } from "../../globalTypes/GlobalTypes";
import { CSApiService } from "../../redux-store/CSApiService";
import {
  T_addUpdateCustomerMobile_Request,
  T_addUpdateCustomerMobile_Response,
  T_addUpdateCustomerProfile_Request,
  T_addUpdateCustomerProfile_Response,
  T_checkExistingProfile_Request,
  T_checkExistingProfile_Response,
  T_createUpdateAdminDetails_Request,
  T_createUpdateAdminDetails_Response,
  T_CreateUpdateChannelFeature_Response,
  T_createUpdateProfile_Request,
  T_createUpdateProfile_Response,
  T_deactivateProfile_Request,
  T_deactivateProfile_Response,
  T_FetchAllAdminDetails_Request,
  T_FetchAllAdminDetails_Response,
  T_FetchAllChannels_Response,
  T_FetchAllProfileDetails_Request,
  T_FetchAllProfileDetails_Response,
  T_fetchCustomerDetailsFromMobileNumber_Request,
  T_fetchCustomerDetailsFromMobileNumber_Response,
  T_FetchCustomerMobileFromProfileID_Request,
  T_FetchCustomerMobileFromProfileID_Response,
  T_FetchCustomerProfileById_Request,
  T_FetchCustomerProfileById_Response,
  T_FetchFeaturesByChannel_Request,
  T_FetchFeaturesByChannel_Response,
  T_FetchFeaturesByChannelForSubmit_Request,
  T_GetFeatureMenusLobwiseByAdminProfile_Request,
  T_GetFeatureMenusLobwiseByAdminProfile_Response,
  T_GetMenusModulewiseByAdminProfile_Response,
  T_inativeMobileFromProfile_Request,
  T_inativeMobileFromProfile_Response,
  T_submitAdminProfileMenu_Request,
  T_submitAdminProfileMenu_Response,
} from "./T_UserManagementTypes";
import {
  T_FetchFeatureHistoryDetails_Request,
  T_FetchFeatureHistoryDetails_Response,
} from "../Features/T_FeaturesDetailsTypes";

export const UserManagementAPI = CSApiService.injectEndpoints({
  endpoints: (builder) => ({
    fetchAllAdminDetails: builder.query<
      T_ApiResponse<T_FetchAllAdminDetails_Response>,
      T_FetchAllAdminDetails_Request
    >({
      query: ({ isActive, pageSize, pageNumber }) => {
        return {
          url: `${FetchAllAdminDetails}?isActive=${isActive}`,
          method: "GET",
          params: {
            pageNumber,
            pageSize,
          },
          headers: {
            Authorization: `Bearer ${API_TOKEN}`,
            source: "abc", // Add traceId to headers
            csUserId: "abc",
            traceId: "abc",
            pageName: "abc",
          },
        };
      },
    }),

    fetchCustomerProfileById: builder.query<
      T_ApiResponse<T_FetchCustomerProfileById_Response>,
      T_FetchCustomerProfileById_Request
    >({
      query: (params) => {
        return {
          url: `${FetchCustomerProfileByiD}?profileId=${params.profileId}`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${API_TOKEN}`,
            source: "abc", // Add traceId to headers
            csUserId: "abc",
            traceId: "abc",
            pageName: "abc",
          },
        };
      },
    }),

    getMenusModulewiseByAdminProfile: builder.query<
      T_ApiResponse<T_GetMenusModulewiseByAdminProfile_Response>,
      T_GetFeatureMenusLobwiseByAdminProfile_Request
    >({
      query: (params) => {
        return {
          url: `${GetMenusModulewiseByAdminProfile}?profileId=${params.profileId}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${API_TOKEN}`,
            source: "abc", // Add traceId to headers
            csUserId: "abc",
            traceId: "abc",
            pageName: "abc",
          },
        };
      },
    }),

    getFeatureLobwiseByAdminProfile: builder.query<
      T_ApiResponse<T_GetFeatureMenusLobwiseByAdminProfile_Response>,
      T_GetFeatureMenusLobwiseByAdminProfile_Request
    >({
      query: (params) => {
        return {
          url: GetFeatureLobwiseByAdminProfile,
          method: "GET",
          headers: {
            Authorization: `Bearer ${API_TOKEN}`,
            source: "abc", // Add traceId to headers
            csUserId: "abc",
            traceId: "abc",
            pageName: "abc",
          },
        };
      },
    }),

    fetchCustomerMobileFromProfileID: builder.query<
      T_ApiResponse<T_FetchCustomerMobileFromProfileID_Response>,
      T_FetchCustomerMobileFromProfileID_Request
    >({
      query: (payload) => ({
        url: FetchCustomerMobileFromProfileID,
        method: "POST",
        body: JSON.stringify(payload),
        params: {
          pageNumber: payload.pageNumber,
          pageSize: payload.pageSize,
        },
        headers: {
          Authorization: `Bearer ${API_TOKEN}`,
          traceId: "abc", // Example user ID, modify as necessary
          source: "abc",
          pageName: 1,
        },
      }),
    }),

    inativeMobileFromProfile: builder.query<
      T_ApiResponse<T_inativeMobileFromProfile_Response>,
      T_inativeMobileFromProfile_Request
    >({
      query: (payload) => ({
        url: InativeMobileFromProfile,
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          Authorization: `Bearer ${API_TOKEN}`,
          // traceId: "abc", // Example user ID, modify as necessary
          // source: "abc",
          // pageName: 1,
        },
      }),
    }),

    fetchCustomerDetailsFromMobileNumber: builder.query<
      T_ApiResponse<T_fetchCustomerDetailsFromMobileNumber_Response>,
      T_fetchCustomerDetailsFromMobileNumber_Request
    >({
      query: (payload) => ({
        url: FetchCustomerDetailsFromMobileNumber,
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          Authorization: `Bearer ${API_TOKEN}`,
          // traceId: "abc", // Example user ID, modify as necessary
          // source: "abc",
          // pageName: 1,
        },
      }),
    }),

    createUpdateProfile: builder.query<
      T_ApiResponse<T_createUpdateProfile_Response>,
      T_createUpdateProfile_Request
    >({
      query: (payload) => ({
        url: CreateUpdateProfile,
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          Authorization: `Bearer ${API_TOKEN}`,
          traceId: "abc", // Example user ID, modify as necessary
          source: "abc",
          pageName: 1,
        },
      }),
    }),

    submitAdminProfileMenu: builder.query<
      T_ApiResponse<T_submitAdminProfileMenu_Response>,
      T_submitAdminProfileMenu_Request
    >({
      query: (payload) => ({
        url: SubmitAdminProfileMenu,
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          Authorization: `Bearer ${API_TOKEN}`,
          traceId: "abc", // Example user ID, modify as necessary
          source: "abc",
          pageName: 1,
        },
      }),
    }),
    addUpdateCustomerMobile: builder.query<
      T_ApiResponse<T_addUpdateCustomerMobile_Response>,
      T_addUpdateCustomerMobile_Request
    >({
      query: (payload) => ({
        url: AddUpdateCustomerMobile,
        method: "POST",
        body: JSON.stringify(payload),
        // params:{
        //   pageNumber,
        //   pageSize,
        // },
        headers: {
          Authorization: `Bearer ${API_TOKEN}`,
          traceId: "abc", // Example user ID, modify as necessary
          source: "abc",
          pageName: 1,
        },
      }),
    }),

    fetchAllProfileDetails: builder.query<
      T_ApiResponse<T_FetchAllProfileDetails_Response>,
      T_FetchAllProfileDetails_Request
    >({
      query: ({ pageNumber, pageSize, isAdmin }) => {
        return {
          url: FetchAllProfile,
          method: "GET",
          params: {
            pageNumber,
            pageSize,
            isAdmin,
          },
          headers: {
            Authorization: `Bearer ${API_TOKEN}`,
            source: "abc", // Add traceId to headers
            csUserId: "abc",
            traceId: "abc",
            pageName: "abc",
          },
        };
      },
    }),

    addUpdateCustomerProfile: builder.query<
      T_ApiResponse<T_addUpdateCustomerProfile_Response>,
      T_addUpdateCustomerProfile_Request
    >({
      query: (payload) => {
        return {
          url: AddUpdateCustomerProfile,
          method: "POST",
          body: JSON.stringify(payload),
        };
      },
    }),

    checkExistingProfile: builder.query<
      T_ApiResponse<T_checkExistingProfile_Response>,
      T_checkExistingProfile_Request
    >({
      query: (payload) => {
        return {
          url: CheckExistingProfile,
          method: "POST",
          body: JSON.stringify(payload),
        };
      },
    }),

    deactivateProfile: builder.query<
      T_ApiResponse<T_deactivateProfile_Response>,
      T_deactivateProfile_Request
    >({
      query: (profileId) => {
        return {
          url: `${DeactivateProfile}?profileId=${profileId.profileId}`,
          method: "POST",
        };
      },
    }),
    createUpdateAdminDetails: builder.query<
      T_ApiResponse<T_createUpdateAdminDetails_Response>,
      T_createUpdateAdminDetails_Request
    >({
      query: (payload) => ({
        url: Api_CreateUpdateAdminDetails,
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          Authorization: `Bearer ${API_TOKEN}`,
          traceId: "abc", // Example user ID, modify as necessary
          source: "abc",
          pageName: 1,
        },
      }),
    }),
    fetchAllChannels: builder.query<
      T_ApiResponse<T_FetchAllChannels_Response>,
      any
    >({
      query: (payload) => ({
        url: Api_FetchAllChannels,
        method: "GET",
        body: JSON.stringify(payload),
        headers: {
          Authorization: `Bearer ${API_TOKEN}`,
        },
      }),
    }),
    createUpdateChannel: builder.query<
      T_ApiResponse<T_CreateUpdateChannelFeature_Response>,
      T_FetchFeaturesByChannelForSubmit_Request
    >({
      query: (payload) => ({
        url: Api_CreateUpdateChannel,
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          Authorization: `Bearer ${API_TOKEN}`,
        },
      }),
    }),
    fetchFeaturesByChannel: builder.query<
      T_ApiResponse<T_FetchFeaturesByChannel_Response>,
      T_FetchFeaturesByChannel_Request
    >({
      query: (payload) => ({
        url: Api_FetchFeaturesByChannel,
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          Authorization: `Bearer ${API_TOKEN}`,
        },
      }),
    }),
  }),
});

export const {
  useLazyFetchCustomerMobileFromProfileIDQuery,
  useLazySubmitAdminProfileMenuQuery,
  useLazyGetFeatureLobwiseByAdminProfileQuery,
  useLazyGetMenusModulewiseByAdminProfileQuery,
  useLazyFetchCustomerProfileByIdQuery,
  useLazyAddUpdateCustomerMobileQuery,
  useLazyInativeMobileFromProfileQuery,
  useLazyFetchCustomerDetailsFromMobileNumberQuery,
  useLazyCreateUpdateProfileQuery,
  useLazyFetchAllAdminDetailsQuery,
  useLazyFetchAllProfileDetailsQuery,
  useLazyAddUpdateCustomerProfileQuery,
  useLazyCheckExistingProfileQuery,
  useLazyDeactivateProfileQuery,
  useLazyCreateUpdateAdminDetailsQuery,
  useLazyFetchAllChannelsQuery,
  useLazyCreateUpdateChannelQuery,
  useLazyFetchFeaturesByChannelQuery,
} = UserManagementAPI;
