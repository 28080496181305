import { T_ApiResponse } from "../../globalTypes/GlobalTypes";
import { CSApiService } from "../../redux-store/CSApiService";
import {
  Access_Token,
  Api_AddEditMasterDetailsByUniqueId,
  Api_AddEditMasterModule,
  Api_FetchAllModuleLob,
  Api_FetchMasterDetailsByLob,
  Api_FetchMasterManagementMasterTypeData,
  Api_FetchMasterManagementModule,
  Api_FetchMasterTypeByLob,
  API_TOKEN,
} from "../../utils/ApiEndpoints";
import {
  T_AddUpdateMasterModule_Request,
  T_AddUpdateMasterModule_Response,
  T_AddUpdateMasterModuleByUniqueId_Request,
  T_FetchAllModuleLob_Response,
  T_FetchAllModuleRequest,
  T_FetchMasterDetailsByLobAndMasterTypeCode_Response,
  T_FetchUniqueMasterTypeByLob_Request,
  T_FetchUniqueMasterTypeByLob_Response,
  T_FetchUniqueMasterTypeByLobAndMasterTypeCode_Request,
  T_MasterModuleDetails,
  T_MasterTypeDetails,
} from "./MasterManagement_Type";
export const MasterManagementApi = CSApiService.injectEndpoints({
  endpoints: (builder) => ({
    fetchMasterTypes: builder.query<T_ApiResponse<T_MasterTypeDetails>, any>({
      query: () => ({
        url: Api_FetchMasterManagementMasterTypeData,
        method: "GET",
        headers: {
          Authorization: `Bearer ${API_TOKEN}`,
          authToken: `${Access_Token}`,
        },
      }),
    }),
    fetchMasterModule: builder.query<T_ApiResponse<T_MasterModuleDetails>, any>(
      {
        query: () => ({
          url: Api_FetchMasterManagementModule,
          method: "GET",
          headers: {
            Authorization: `Bearer ${API_TOKEN}`,
            authToken: `${Access_Token}`,
          },
        }),
      }
    ),
    fetchMasterTypeByLob: builder.query<
      T_ApiResponse<T_FetchUniqueMasterTypeByLob_Response>,
      T_FetchUniqueMasterTypeByLob_Request
    >({
      query: ({ lobCode }) => ({
        url: Api_FetchMasterTypeByLob,
        method: "GET",
        params: {
          lobCode,
        },
        headers: {
          Authorization: `Bearer ${API_TOKEN}`,
          authToken: `${Access_Token}`,
        },
      }),
    }),
    fetchAllModuleLob: builder.query<
      T_ApiResponse<T_FetchAllModuleLob_Response>,
      T_FetchAllModuleRequest
    >({
      query: ({ pageNumber, pageSize }) => ({
        url: Api_FetchAllModuleLob,
        method: "GET",
        params: {
          pageNumber,
          pageSize,
        },
        headers: {
          Authorization: `Bearer ${API_TOKEN}`,
          authToken: `${Access_Token}`,
        },
      }),
    }),
    addUpdateMasterModule: builder.query<
      T_ApiResponse<T_AddUpdateMasterModule_Response>,
      T_AddUpdateMasterModule_Request
    >({
      query: (payload) => ({
        url: Api_AddEditMasterModule,
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          Authorization: `Bearer ${API_TOKEN}`,
          authToken: `${Access_Token}`,
        },
      }),
    }),
    fetchMasterDetailsByLobAndMasterTypeCode: builder.query<
      T_ApiResponse<T_FetchMasterDetailsByLobAndMasterTypeCode_Response>,
      T_FetchUniqueMasterTypeByLobAndMasterTypeCode_Request
    >({
      query: ({ lobCode, masterTypeCode, pageNumber, pageSize }) => ({
        url: Api_FetchMasterDetailsByLob,
        method: "GET",
        params: {
          lobCode,
          masterTypeCode,
          pageNumber,
          pageSize,
        },
        headers: {
          Authorization: `Bearer ${API_TOKEN}`,
          authToken: `${Access_Token}`,
        },
      }),
    }),
    addUpdateMasterModuleByUniqueId: builder.query<
      T_ApiResponse<T_AddUpdateMasterModule_Response>,
      T_AddUpdateMasterModuleByUniqueId_Request
    >({
      query: (payload) => ({
        url: Api_AddEditMasterDetailsByUniqueId,
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          Authorization: `Bearer ${API_TOKEN}`,
          authToken: `${Access_Token}`,
        },
      }),
    }),
  }),
});

export const {
  useLazyFetchMasterModuleQuery,
  useLazyFetchMasterTypesQuery,
  useLazyFetchMasterTypeByLobQuery,
  useLazyFetchAllModuleLobQuery,
  useLazyAddUpdateMasterModuleQuery,
  useLazyFetchMasterDetailsByLobAndMasterTypeCodeQuery,
  useLazyAddUpdateMasterModuleByUniqueIdQuery,
} = MasterManagementApi;
