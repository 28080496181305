import { T_ApiResponse } from "../../globalTypes/GlobalTypes";
import { CSApiService } from "../../redux-store/CSApiService";
import {
  Api_allLobsHistory,
  Api_masterHistory,
  API_TOKEN,
} from "../../utils/ApiEndpoints";
import {
  T_FetchMasterHistory_Request,
  T_FetchMasterHistory_Response,
  T_MasterLOBHistoryDetails,
} from "./T_AdminAuditHistoryTypes";

export const auditHistoryApi = CSApiService.injectEndpoints({
  endpoints: (builder) => ({
    masterHistory: builder.query<
      T_ApiResponse<T_FetchMasterHistory_Response>,
      T_FetchMasterHistory_Request
    >({
      query: (payload) => ({
        method: "POST",
        url: Api_masterHistory,
        body: JSON.stringify({
          masterTypeCode: payload.masterTypeCode,
          masterLobCode: payload.masterLobCode,
        }),
        params: {
          pageNumber: payload.pageNumber,
          pageSize: payload.pageSize,
        },
        headers: {
          pageName: "/admin",
        },
      }),
    }),

    allLobsHistory: builder.query<
      T_ApiResponse<T_MasterLOBHistoryDetails>,
      any
    >({
      query: () => ({
        method: "GET",
        url: Api_allLobsHistory,
        headers: {
          Authorization: `Bearer ${API_TOKEN}`,
          traceId: "abc",
          pageName: "abc",
          source: "abc",
        },
      }),
    }),
  }),
});

export const { useLazyMasterHistoryQuery, useLazyAllLobsHistoryQuery } =
  auditHistoryApi;
