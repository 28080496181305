import LobListIcon from "../assets/icons/lob-list-icon.svg";

export enum DetailsPageTabKeys {
  Otp = "OTP Management",
  Features = "Feature Management ",
  FinancialYear = "Financial Year",
  Reports = "Reports Management",
  OnboardingReport = "Onboarding Report",
  SRRaiseReport = "SR Raise Report",
  LoginCountReport = "Login Count Report",
  MpinChangeReport = "MPIN change Report",
  RedirectioncountReport = "Redirection count report",
  ManualAdditionsReport = "Manual Addition report",
  CustomerUserManagement = "Customer User Profile",
  AdminProfileManagement = "Admin User Profile",
  AddNewCustomer = "Customer User Profile",
  UserManagement = "Customer User Profile",
  AddUserMobile = "Customer User Profile",
  AddUserList = "Admin User List",
  AddNewUserProfile = "Admin User profile",
  MasterManagement = "Master Management",
  ChannelManagement = "Channel Management",
  AuditHistory = "Audit History",
}

export const USE_DEV_TEST_DATA: boolean = false;
export enum DEVICE_TYPE {
  MOBILE = "ABC_Mobile_CS",
  WEB = "ABC_Web_CS",
  PWA_WEB = "ABC_Pwa_Web_CS",
  PWA_MOBILE = "ABC_Pwa_Mobile_CS",
}

export const ABCD_SUPERAPP_URL = process.env.REACT_APP_ABCD_SUPERAPP_URL;

export const tabViewPoint = 768;

export const reportCheckboxLabelList = [
  {
    id: 1,
    label: "Number of onboarding done",
    checked: false,
  },
  {
    id: 2,
    label: "Number of drop off on onboarding",
    checked: false,
  },
  {
    id: 3,
    label: "Number of SR raised",
    checked: false,
  },
  {
    id: 4,
    label: "Number of Add manually happened",
    checked: false,
  },
  {
    id: 5,
    label: "Login count",
    checked: false,
  },
  {
    id: 6,
    label: "Change in MPIN",
    checked: false,
  },
  {
    id: 7,
    label: "Number of Redirection happened",
    checked: false,
  },
];

// export const dashboardLobList = [
//   {
//     id: 1,
//     lobID:"01",
//     title: "ABFSSL",
//     icon: LobListIcon,
//     description:
//       "(Aditya Birla Finance Shared Services Limited-General App Elements)",
//   },
//   {
//     id: 2,
//     lobID:"02",
//     title: "ABSLI",
//     icon: LobListIcon,
//     description: "(Aditya Birla Sun Life Insurance)",
//   },
//   {
//     id: 3,
//     lobID:"03",
//     title: "ABHI",
//     icon: LobListIcon,
//     description: "(Aditya Birla Health Insurance)",
//   },
//   {
//     id: 4,
//     lobID:"04",
//     title: "ABSLAMC",
//     icon: LobListIcon,
//     description: "(Aditya Birla Sun Life AMC)",
//   },
//   {
//     id: 5,
//     lobID:"05",
//     title: "ABML",
//     icon: LobListIcon,
//     description: "(Aditya Birla Money Limited)",
//   },
//   {
//     id: 6,
//     lobID:"06",
//     title: "ABFL",
//     icon: LobListIcon,
//     description: "(Aditya Birla Finance Limited)",
//   },
//   {
//     id: 7,
//     lobID:"07",
//     title: "ABHFL",
//     icon: LobListIcon,
//     description: "(Aditya Birla Housing Finance)",
//   },
//   {
//     id: 8,
//     lobID:"08",
//     title: "User",
//     icon: LobListIcon,
//     description: "Management",
//   },
// ];

export const dashboardLobList = [
  {
    id: 1,
    lobID: "00",
    title: "General App Elements",
    icon: LobListIcon,
    description:
      "(Aditya Birla Finance Shared Services Limited-General App Elements)",
    options: [
      // { label: "OTP Management", value: DetailsPageTabKeys.Otp },
      {
        label: "Channel Management",
        value: DetailsPageTabKeys.ChannelManagement,
      },
      {
        label: "Master Management",
        value: DetailsPageTabKeys.MasterManagement,
      },
    ],
  },
  {
    id: 2,
    lobID: "00",
    title: "Reports Management",
    icon: LobListIcon,
    description:
      "(Aditya Birla Finance Shared Services Limited-General App Elements)",
    options: [
      {
        label: "Onboarding Completion Report",
        value: DetailsPageTabKeys.OnboardingReport,
      },
      {
        label: "SR Raised Report",
        value: DetailsPageTabKeys.SRRaiseReport,
      },
      {
        label: "Manual Additions Report",
        value: DetailsPageTabKeys.ManualAdditionsReport,
      },
      {
        label: "Login Count Report",
        value: DetailsPageTabKeys.LoginCountReport,
      },
      {
        label: "MPIN Change Report",
        value: DetailsPageTabKeys.MpinChangeReport,
      },
      {
        label: "Redirection Count Report",
        value: DetailsPageTabKeys.RedirectioncountReport,
      },
    ],
  },
  {
    id: 3,
    lobID: "02",
    title: "ABSLI",
    icon: LobListIcon,
    description: "(Aditya Birla Sun Life Insurance)",
    options: [
      { label: "Features Management", value: DetailsPageTabKeys.Features },
    ],
  },
  {
    id: 4,
    lobID: "06",
    title: "ABHI",
    icon: LobListIcon,
    description: "(Aditya Birla Health Insurance)",
    options: [
      { label: "Features Management", value: DetailsPageTabKeys.Features },
    ],
  },
  {
    id: 5,
    lobID: "01",
    title: "ABSLAMC",
    icon: LobListIcon,
    description: "(Aditya Birla Sun Life AMC)",
    options: [
      { label: "Feature Management", value: DetailsPageTabKeys.Features },
    ],
  },
  {
    id: 6,
    lobID: "24",
    title: "ABML",
    icon: LobListIcon,
    description: "(Aditya Birla Money Limited)",
    options: [
      { label: " Feature Management", value: DetailsPageTabKeys.Features },
    ],
  },
  {
    id: 7,
    lobID: "03",
    title: "ABFL",
    icon: LobListIcon,
    description: "(Aditya Birla Finance Limited)",
    options: [{ label: "Features", value: DetailsPageTabKeys.Features }],
  },
  {
    id: 8,
    lobID: "04",
    title: "ABHFL",
    icon: LobListIcon,
    description: "(Aditya Birla Housing Finance)",
    options: [{ label: "Features", value: DetailsPageTabKeys.Features }],
  },
  {
    id: 9,
    lobID: "08",
    title: "User Management",
    icon: LobListIcon,
    description: "Management",
    options: [
      {
        label: "Admin User Profile",
        value: DetailsPageTabKeys.AddNewUserProfile,
      },
      { label: "Admin User List", value: DetailsPageTabKeys.AddUserList },
      {
        label: "Customer user Profile",
        value: DetailsPageTabKeys.CustomerUserManagement,
      },
    ],
  },
  {
    id: 10,
    lobID: "10",
    title: "Admin Audit History",
    icon: LobListIcon,
    description: "Management",
    options: [],
  },
];

export const AddUserLobList: { [key: string]: any } = [
  {
    id: 1,
    title: "ABFSSL",
    icon: LobListIcon,
    description:
      "(Aditya Birla Finance Shared Services Limited-General App Elements)",
    features: [
      {
        featureName: " Tax Certificate",
        isView: false,
        isModify: false,
      },
      {
        featureName: " Pay premium",
        isView: false,
        isModify: false,
      },
    ],
  },
  {
    id: 2,
    title: "ABSLI",
    icon: LobListIcon,
    description: "(Aditya Birla Sun Life Insurance)",
    features: [
      {
        featureName: " Tax Certificate",
        isView: false,
        isModify: false,
      },
    ],
  },
  // {
  //   id: 3,
  //   title: "ABHI",
  //   icon: LobListIcon,
  //   description: "(Aditya Birla Health Insurance)",
  //   features: [
  //     {
  //       featureName: " Tax Certificate",
  //       isView: false,
  //       isModify: false,
  //     },
  //   ],
  //   isViewAll: false,
  //   isModifyAll: false,
  // },
  // {
  //   id: 4,
  //   title: "ABSLAMC",
  //   icon: LobListIcon,
  //   description: "(Aditya Birla Sun Life AMC)",
  //   features: [
  //     {
  //       featureName: " Tax Certificate",
  //       isView: false,
  //       isModify: false,
  //     },
  //   ],
  //   isViewAll: false,
  //   isModifyAll: false,
  // },
  // {
  //   id: 5,
  //   title: "ABML",
  //   icon: LobListIcon,
  //   description: "(Aditya Birla Money Limited)",
  //   features: [
  //     {
  //       featureName: " Tax Certificate",
  //       isView: false,
  //       isModify: false,
  //     },
  //   ],
  //   isViewAll: false,
  //   isModifyAll: false,
  // },
  // {
  //   id: 6,
  //   title: "ABFL",
  //   icon: LobListIcon,
  //   description: "(Aditya Birla Finance Limited)",
  //   features: [
  //     {
  //       featureName: " Tax Certificate",
  //       isView: false,
  //       isModify: false,
  //     },
  //   ],
  //   isViewAll: false,
  //   isModifyAll: false,
  // },
  // {
  //   id: 7,
  //   title: "ABHFL",
  //   icon: LobListIcon,
  //   description: "(Aditya Birla Housing Finance)",
  //   features: [
  //     {
  //       featureName: " Tax Certificate",
  //       isView: false,
  //       isModify: false,
  //     },
  //   ],
  //   isViewAll: false,
  //   isModifyAll: false,
  // },
  // {
  //   id: 8,
  //   title: "User",
  //   icon: LobListIcon,
  //   description: "Management",
  //   features: [
  //     {
  //       featureName: " Tax Certificate",
  //       isView: false,
  //       isModify: false,
  //     },
  //   ],
  //   isViewAll: false,
  //   isModifyAll: false,
  // },
];

export const reportsListArray = [
  {
    id: 1,
    title: "Report 1",
    description: "Description placeholder",
  },
  {
    id: 2,
    title: "Report 2",
    description: "Description placeholder",
  },
  {
    id: 3,
    title: "Report 3",
    description: "Description placeholder",
  },
  {
    id: 4,
    title: "Report 4",
    description: "Description placeholder",
  },
  {
    id: 5,
    title: "Report 5",
    description: "Description placeholder",
  },
  {
    id: 6,
    title: "Report 6",
    description: "Description placeholder",
  },
  {
    id: 7,
    title: "Report 7",
    description: "Description placeholder",
  },
  {
    id: 8,
    title: "Report 8",
    description: "Description placeholder",
  },
  {
    id: 9,
    title: "Report 9",
    description: "Description placeholder",
  },
  {
    id: 10,
    title: "Report 10",
    description: "Description placeholder",
  },
];

export const masterManagementDropDownOptions = [
  { label: "Table master details", value: "Table master details" },
  { label: "LOB Master", value: "LOB Master" },
];

export const MasterOptions = [
  { label: "Master Table", value: "Master Table" },
  {
    label: "LOB Master",
    value: "LOB Master",
  },
  {
    label: "Feature",
    value: "Feature",
  },
];

export const masterManagementTableHeaderList = [
  "Serial No",
  "Module",
  "Master Type",
  "Downstream Key",
  "Downstream Value",
  "IsActive",
];

export const masterManagementLobMasterTableHeaderList = [
  "Serial No",
  "LOB Code",
  "Description",
  "Downstream Key",
  "LOB Redirectional URL",
  "IsActive",
  "IsLOB",
];

export const masterManagementTableBodyData = [
  {
    serialNo: 1,
    module: "Module A",
    masterType: "Type 1",
    downstreamKey: "Key 1",
    downstreamValue: "Value 1",
    isActive: "Yes",
  },
  {
    serialNo: 2,
    module: "Module B",
    masterType: "Type 2",
    downstreamKey: "Key 2",
    downstreamValue: "Value 2",
    isActive: "Yes",
  },
  {
    serialNo: 3,
    module: "Module C",
    masterType: "Type 3",
    downstreamKey: "Key 3",
    downstreamValue: "Value 3",
    isActive: "Yes",
  },
  // Add more data here as needed
];

export const masterManagementLobMasterTableBodyData = [
  {
    serialNo: 1,
    lobCode: "01",
    description: "Mutual Funds",
    downstreamKey: "Key 1",
    lobRedirectionalUrl: "Value 1",
    isActive: "Yes",
    isLob: "Yes",
  },
  {
    serialNo: 2,
    lobCode: "06",
    description: "Health Insurance",
    downstreamKey: "Key 1",
    lobRedirectionalUrl: "Value 1",
    isActive: "Yes",
    isLob: "Yes",
  },
  {
    serialNo: 3,
    lobCode: "01",
    description: "Life Insurance",
    downstreamKey: "Key 1",
    lobRedirectionalUrl: "Value 1",
    isActive: "Yes",
    isLob: "Yes",
  },
];

export const AuditHistoryFeatureTableHeaderName = [
  "Username",
  "Functionality Code",
  "LOB Redirectional URL",
  "Downtime Enabled",
  "Feature Enabled",
  "Is Live",
  "Financial Year",
  "Date",
  "Time",
];

export const AuditHistoryLOBMasterTableHeaderName = [
  "Username",
  "Description",
  "LOB Code",
  "Downstream Key",
  "LOB Redirectional URL",
  "Active",
  "Date",
  "Time",
];

export const AuditHistoryMasterTableHeaderName = [
  "Username",
  "Module",
  "Master Type",
  "Downstream Key",
  "Downstream Value",
  "Date",
  "Time",
];
