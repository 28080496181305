import { FC, useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Divider,
  Pagination,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { API_STATUS_CODE } from "../../../../utils/ApiEndpoints";
import { setError } from "../../../../redux-store/reducers/CsErrorSlice";
import { T_AnimationData } from "../../../../globalTypes/GlobalTypes";
import AlertGif from "../../../../assets/gif/Exclamation_Mark.json";
import PlusIcon from "../../../../assets/icons/plus.svg";
import DeleteIcon from "../../../../assets/icons/trash-can.svg";
import EditIcon from "../../../../assets/icons/edit.svg";
import "./CustomerProfileList.scss";
import CustomButton from "../../../../components/CustomAdminButton/CustomAdminButton";
import { Colors } from "../../../../styles/constant";
import {
  useLazyFetchAllProfileDetailsQuery,
  useLazyDeactivateProfileQuery,
} from "../../UserManagement_Api";
import CustomPopup from "../../../../components/CustomPopup/CustomPopup";
import CustomLoader from "../../../../components/CustomLoader";
import CustomSuccessPopupBody from "../../../../components/CustomSuccessPopupBody/CustomSuccessPopupBody";
import CustomThreeDotsLoader from "../../../../components/CustomThreeDotsLoader/CustomThreeDotsLoader";
import { CustomFailurePopupBody } from "../../../../components/CustomFailurePopupBody/CustomFailurePopupBody";
import CommonGifAnimation from "../../../../components/CustomGifAnimation/CustomGifAnimation";

type AddCustomerListProps = {
  onAddNewCustomerClick: (customerData: any) => void;
};
interface Profile {
  profileId: number; // Or `profileId: number` depending on your data type
  profileName: string;
  isActive: boolean;
  isAdmin: boolean;
}

const CustomerProfileList: FC<AddCustomerListProps> = ({
  onAddNewCustomerClick,
}) => {
  const dispatch = useDispatch();
  let [customerList, setCustomerList] = useState<Profile[]>([]);
  console.log("customer-list", customerList);
  const [fetchAllProfileDetails] = useLazyFetchAllProfileDetailsQuery();
  const [deactiveProfile] = useLazyDeactivateProfileQuery();
  const [loading, setLoading] = useState<boolean>(false); // State for loading
  const [selectedProfileId, setSelectedProfileId] = useState<number>(0);
  const [deletesuccesspopupOpen, setDeletesuccessPopupOpen] =
    useState<boolean>(false);
  const [deletefailurepopupOpen, setDeleteFailurePopupOpen] =
    useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageSize] = useState<number>(10);
  const [totalElements, setTotalElements]=useState<number>(0);
  const [deleteConfirmationPopup, setDeleteConfirmationPopup] =
    useState<boolean>(false);

  const handleAddNewCustomerClick = (customerData: any) => {
    onAddNewCustomerClick(customerData);
  };


  const fetchData = async () => {
    setLoading(true); // Set loading to true before fetching
    try {
      const res = await fetchAllProfileDetails({
        pageNumber: currentPage,
        pageSize: pageSize,
        isAdmin:false
      }).unwrap(); // Use unwrap to handle errors easily

      // if (res.error && res.isError) {
      //   setLoading(false);
      //   dispatch(
      //     setError({
      //       error: new Error("Error"),
      //       message: "delete api failed",
      //     })
      //   );
      // }

      if (res.payload.data && res.code === API_STATUS_CODE.SUCCESS) {
        setLoading(false);
        console.log("Response Payload:", res.payload.data);
        setCustomerList(res.payload.data);
        setTotalElements(res.payload.totalElements);
      } 
      else {
        console.error("Unexpected response structure:", res);
        setLoading(false);
        dispatch(
          setError({
            error: new Error("Error"),
            message: "fetchAllProfileDetails api failed",
          })
        );
      }
    } 
    catch (err) {
      setLoading(false);
      dispatch(
        setError({
          error: new Error("Error"),
          message: "fetchAllProfileDetails api failed",
        })
      );
      console.error("fetchAllProfileDetails api failed", err);
    } 
    finally {
      setLoading(false); // Set loading to false after fetching
    }
  };

  useEffect(() => {
    fetchData();
    // console.log("custometer 123",customerList)
  }, [pageSize, currentPage]);

  const closeDeletePopup = () => {
    setDeleteConfirmationPopup(false);
    setSelectedProfileId(0); // Reset selected profile when popup closes
  };

  const handleDelete = (deletedid: number) => {
    setSelectedProfileId(deletedid);
    setDeleteConfirmationPopup(true);
  };

  const handledeletefailurepopup = () => {
    setDeleteFailurePopupOpen(false);
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };

  const deleteapicall = async (profileid: number) => {
    setLoading(true); // Set loading to true before fetching
    console.log("deleted profile id ", profileid);
    try {
      const payload = {
        profileId: profileid,
        // profileId:1
      };

      const res = await deactiveProfile(payload); // Call the mutation and unwrap the response
      console.log(res);
      if (res.error && res.isError) {
        setLoading(false);
        dispatch(
          setError({
            error: new Error("Error"),
            message: "deactiveProfile api failed",
          })
        );
      }
      if (res.data?.code === API_STATUS_CODE.SUCCESS) {
        setLoading(false);
        setDeletesuccessPopupOpen(true);
        fetchData();
      }
      if (res.data?.code !== API_STATUS_CODE.SUCCESS) {
        setLoading(false);
        setDeleteFailurePopupOpen(true);
      }
      closeDeletePopup();
      console.log("printing profileid after setting to 0", profileid);
      // Close the popup after deletion is successful
    } 
    catch (err) {
      setLoading(false);
      dispatch(
        setError({
          error: new Error("Error"),
          message: "deactiveProfile api failed",
        })
      );
      console.error("deactiveProfile api failed", err);
    } 
    
    finally {
      setLoading(false); // Set loading to false after fetching
    }
  };

  if (loading) {
    return (
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{ height: "100vh" }}
      >
        <CustomThreeDotsLoader />
      </Box>
    );
  } else {
    return (
      <Box>
        <Container>
          <Box className="customer-profile-header-title">
            <Typography variant="h3">Customer User Profile list</Typography>
            <CustomButton
              btnValue={"Add New Profile"}
              icon={PlusIcon}
              handleClick={() => {
                handleAddNewCustomerClick(null);
              }}
            />
          </Box>
          <Box className="customer-user-list-box">
            <Box className="customer-user-list-box-header">
              <Typography variant="h5">
                Total Profiles ({customerList.length})
              </Typography>
            </Box>
            <Box>
              <Box className="customer-user-list-container">
                {customerList.map((profile, index) => (
                  <Box key={profile.profileId} sx={{ mb: 2 }}>
                    <Box className="customer-user-list-container-1">
                      <Box className="customer-profile">
                        {/* <Avatar className="customer-otp-avatar">OP</Avatar> */}
                        <Box className="customer-profile-name">
                          <Typography variant="h5" className="title-value">
                            {/* <span style={{ width: '30%', margin: 0 }}>Profile Name</span> */}
                            Profile Name
                          </Typography>
                          <Typography variant="subtitle2">
                            {profile.profileName}
                          </Typography>
                        </Box>
                      </Box>

                      <Box className="customer-user-list-box-items-2-buttons">
                        <CustomButton
                          btnValue={"Modify"}
                          icon={EditIcon}
                          handleClick={() => {
                            handleAddNewCustomerClick(profile);
                          }}
                        />
                        <CustomButton
                          btnValue={"Delete"}
                          className="customer-delete-icon"
                          icon={DeleteIcon}
                          handleClick={() => handleDelete(profile.profileId)}
                        />
                      </Box>
                    </Box>
                    {index < customerList.length - 1 && (
      <Divider orientation="horizontal" sx={{ color: "#F1F3F6" }} />
    )}
                    
                    {/* <Divider
                      orientation="horizontal"
                      sx={{ color: "#F1F3F6" }}
                    /> */}
                  </Box>
                ))}
              </Box>
            </Box>
            <Box className="customer-profile-footer">
              <Pagination
                className="custom-pagination"
                count={Math.ceil(totalElements/10)}
                page={currentPage+1}
                onChange={(event, newPage) =>
                  handlePageChange(event, newPage - 1)
                }
                variant="outlined"
                shape="rounded"
              />
            </Box>
          </Box>
          <CustomPopup open={deletesuccesspopupOpen} handleOpen={() => {}}>
            <CustomSuccessPopupBody
              title="Success"
              des="your changes have been saved"
              btnLabel="Continue"
              btnOnClick={() => setDeletesuccessPopupOpen(false)}
            />
          </CustomPopup>
          {deletefailurepopupOpen && (
            <CustomPopup open={deletefailurepopupOpen} handleOpen={() => {}}>
              <CustomFailurePopupBody btnClick={handledeletefailurepopup} />
            </CustomPopup>
          )}
          {/* <CustomAPIFailurePopup
            open={deletefailurepopupOpen}
            handleOpen={handledeletefailurepopup}
          ></CustomAPIFailurePopup> */}

          <CustomPopup open={deleteConfirmationPopup} handleOpen={() => {}}>
            <Box className="customer-list-delete-popup">
              {/* <img src={errorimg} alt="delete alert" /> */}
              <CommonGifAnimation
        width="6.25rem"
        animationData={AlertGif as T_AnimationData}
      />
              <Typography
                variant="h4"
                color={"#252525"}
                marginTop={"1.5rem"}
                marginBottom=".9375rem"
              >
                Delete
              </Typography>
              <Typography
                variant="h5"
                color={"#797979"}
                marginBottom={"1.5rem"}
              >
                Are you sure you want to delete?
              </Typography>
              <Box display="flex" gap={"1rem"}>
                <Button
                  variant="outlined"
                  sx={{
                    border: "1px solid #C7222A",
                    padding: "16px 42px 16px 42px",
                    width: "16rem",
                  }}
                  onClick={() => setDeleteConfirmationPopup(false)}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    padding: "16px 42px 16px 42px",
                    width: "16rem",
                  }}
                  onClick={() => {
                    deleteapicall(selectedProfileId);
                  }}
                >
                  Delete
                </Button>
              </Box>
            </Box>
          </CustomPopup>
        </Container>
      </Box>
    );
  }
};

export default CustomerProfileList;
