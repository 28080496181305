import { Navigate, RouteObject, useRoutes } from "react-router-dom";
// import AdaptiveHome from "../pages/Home/AdaptiveHome";
import ExistingUser from "../features/UserManagement/ExistingUser/ExistingUser";
import AuthGuard from "./Guards/AuthGuard";
import AddUser from "../features/UserManagement/AddUser/AddUser";
import Home from "../pages/Home/Home";
import DetailsPage from "../pages/DetailsPage/DetailsPage";
import ReportsList from "../features/Reports/ReportsList";
import FeatureLogin from "../pages/Login/Feature_Login";
import AddUserMobile from "../features/UserManagement/Customer/AddUserMobile/AddUserMobile";
import CustomerUserManagement from "../features/UserManagement/Customer/CustomerUserManagement/CustomerUserManagement";
import ValidateUser from "./Guards/ValidateUser";

const AppRoutes = () => {
  const arr: RouteObject[] = [
    { path: "/login", element: <FeatureLogin /> },
    { path: "/validate", element: <ValidateUser /> },
    {
      path: "/",
      element: <AuthGuard />,
      children: [
        { path: "home", element: <Home /> },
        { path: "details", element: <DetailsPage /> },
        { path: "reports-list", element: <ReportsList /> },
        { path: "addUser", element: <AddUser /> },
        { path: "existingUser", element: <ExistingUser /> },
        { path: "customerUserManagement", element: <CustomerUserManagement /> },
      ],
    },
    {
      path: "*",
      element: <Navigate to={"/login"} />,
    },
  ];
  const routes = useRoutes(arr);
  // const routes = useRoutes([
  //   {
  //     path: "/login",
  //     element: <FeatureLogin />,
  //   },
  //   { path: "/home", element: <Home /> },
  //   {
  //     path: "*",
  //     element: <Navigate to={"/login"} />,
  //   },
  // ]);

  return routes;
};

export default AppRoutes;
