import React from "react";
import { Box } from "@mui/material";
import Lottie from "lottie-react";
import { T_AnimationData } from "../../globalTypes/GlobalTypes";

interface CommonGifAnimationProps {
  animationData: T_AnimationData;
  width?: string;
  maxWidth?: string;
  heightCustom?: string;
}

const CommonGifAnimation: React.FC<CommonGifAnimationProps> = ({
  animationData,
  width = "50%",
  maxWidth = "31.25rem",
  heightCustom,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: heightCustom || "100%",
      }}
    >
      <Lottie
        className="custom-gif-wrapper"
        animationData={animationData}
        style={{ width: width, maxWidth: maxWidth }}
      />
    </Box>
  );
};

export default CommonGifAnimation;
