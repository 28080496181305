import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { ThemeProvider } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { theme } from "./styles/constant";
import { Provider } from "react-redux";
import { store, persistor } from "./redux-store/store";
import { injectStore } from "./axios/axiosInstance";
import { injectStoreApiService } from "./redux-store/CSApiService";
import { PersistGate } from "redux-persist/integration/react";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
injectStore(store);
injectStoreApiService(store);
root.render(
  // <React.StrictMode>
  //   <Provider store={store}>
  //     <BrowserRouter>
  //       <LocalizationProvider dateAdapter={AdapterDayjs}>
  //         <ThemeProvider theme={theme}>
  //           <App />
  //         </ThemeProvider>
  //       </LocalizationProvider>
  //     </BrowserRouter>
  //   </Provider>
  // </React.StrictMode>
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <BrowserRouter>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </LocalizationProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
