import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Pagination,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import CustomBreadcrumbs from "../../components/CustomBreadcrumbs/CustomBreadcrumbs";
import { useLocation } from "react-router-dom";
import backArrow from "../../assets/icons/back-arrow.svg";
import "./CustomVersionHistory.scss";
import { useAppDispatch } from "../../redux-store/CustomReduxHooks";
import CustomThreeDotsLoader from "../../components/CustomThreeDotsLoader/CustomThreeDotsLoader";
import { dataFetchFeatureHistoryDetails } from "../../features/Features/T_FeaturesDetailsTypes";
import { formatTime } from "../../utils/helper";
type VersionHistoryProps = {
  dataHistory: dataFetchFeatureHistoryDetails[];
  onBackButtonClick: () => void;
  currentPage: number;
  onPageChange: (event: React.ChangeEvent<unknown>, value: number) => void;
};
const CustomVersionHistory: FC<VersionHistoryProps> = ({
  dataHistory = [],
  onBackButtonClick,
  currentPage,
  onPageChange,
}) => {
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
  const [featureHistoryData, setFeatureHistoryData] = useState(dataHistory);
  const handleBackButtonClick = () => {
    onBackButtonClick();
  };
  return (
    <>
      {isBtnLoading ? (
        <Box marginTop={"200px"}>
          <CustomThreeDotsLoader />
        </Box>
      ) : (
        <>
          <Box className="version-history-header">
            <Button
              className="back-button"
              startIcon={<img src={backArrow} alt="Expand Icon" />}
              onClick={handleBackButtonClick}
            ></Button>
            <CustomBreadcrumbs data={["ABSLI", "Version History"]} />
          </Box>
          {dataHistory.length > 0 ? (
            <>
              {" "}
              <Box className="version-history-page">
                <Box className="version-history-page-header">
                  <Typography variant="h3">
                    {featureHistoryData[0]?.featureDisplayName}
                  </Typography>
                </Box>

                <TableContainer component={Paper} className="feature-table">
                  <Table aria-label="version history table">
                    <TableHead className="feature-table-header">
                      <TableRow>
                        <TableCell className="feature-table-cell fixed-column">
                          <Typography variant="h5">Username</Typography>
                        </TableCell>
                        <TableCell className="feature-table-cell">
                          <Typography variant="h5">
                            Functionality Code
                          </Typography>
                        </TableCell>
                        <TableCell className="feature-table-cell">
                          <Typography variant="h5">
                            LOB Redirectional URL
                          </Typography>
                        </TableCell>
                        <TableCell className="feature-table-cell">
                          <Typography variant="h5">Downtime Enabled</Typography>
                        </TableCell>
                        <TableCell className="feature-table-cell">
                          <Typography variant="h5">Feature Enabled</Typography>
                        </TableCell>
                        <TableCell className="feature-table-cell">
                          <Typography variant="h5">Is Live</Typography>
                        </TableCell>
                        <TableCell className="feature-table-cell">
                          <Typography variant="h5">Financial Year</Typography>
                        </TableCell>
                        <TableCell className="feature-table-cell">
                          <Typography variant="h5">Date</Typography>
                        </TableCell>
                        <TableCell className="feature-table-cell">
                          <Typography variant="h5">Time</Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {featureHistoryData.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell className="feature-table-cell">
                            <Typography className="feature-table-cell-text">
                              {row?.modifiedBy?.name}
                            </Typography>
                          </TableCell>
                          <TableCell className="feature-table-cell">
                            <Typography className="feature-table-cell-text">
                              {row?.functionalityCode || "-"}
                            </Typography>
                          </TableCell>
                          <TableCell className="feature-table-cell">
                            <Typography className="feature-table-cell-text">
                              {row?.redirectionUrl || "-"}
                            </Typography>
                          </TableCell>
                          <TableCell className="feature-table-cell">
                            <Typography className="feature-table-cell-text">
                              {row?.isDowntime === true ? "true" : "false"}
                            </Typography>
                          </TableCell>
                          <TableCell className="feature-table-cell">
                            <Typography className="feature-table-cell-text">
                              {row?.isActive === true ? "true" : "false"}
                            </Typography>
                          </TableCell>
                          <TableCell className="feature-table-cell">
                            <Typography className="feature-table-cell-text">
                              {row?.isLive === true ? "true" : "false"}
                            </Typography>
                          </TableCell>
                          <TableCell className="feature-table-cell">
                            <Typography className="feature-table-cell-text">
                              {row?.isLive || "-"}
                            </Typography>
                          </TableCell>
                          <TableCell className="feature-table-cell">
                            <Typography className="feature-table-cell-text">
                              {row?.modifiedDate.split("T")[0] || "-"}
                            </Typography>
                          </TableCell>
                          <TableCell className="feature-table-cell">
                            <Typography className="feature-table-cell-text">
                              {formatTime(row?.modifiedDate) || "-"}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <Box className="version-history-footer">
                <Pagination
                  count={Math.ceil(dataHistory.length / 10)} // Assuming 10 records per page, adjust according to total data
                  page={currentPage}
                  onChange={onPageChange}
                  variant="outlined"
                  shape="rounded"
                />
              </Box>
            </>
          ) : (
            <Typography>Data unavilable</Typography>
          )}
        </>
      )}
    </>
  );
};

export default CustomVersionHistory;
