import {
  UserLoginReport,
  API_TOKEN,
  Access_Token,
  UserLoginReportByEmail,
  UserLoginnReportByDownload,
  ChangeMPINDetails,
  ChangeMPINDetailsReportDownload,
} from "../../../utils/ApiEndpoints";
import { T_ApiResponse } from "../../../globalTypes/GlobalTypes";
import { CSApiService } from "../../../redux-store/CSApiService";
import {
  T_ChangeMPINDetailsReport_Request,
  T_ChangeMPINDetailsReport_Response,
  T_MPINChangeDownload_Request,
  T_MPINChangeDownload_Response,
} from "./T_MPINChnageReport";

export const MPINChangeAPI = CSApiService.injectEndpoints({
  endpoints: (builder) => ({
    changeMPINDetails: builder.query<
      T_ApiResponse<T_ChangeMPINDetailsReport_Response>,
      T_ChangeMPINDetailsReport_Request
    >({
      query: (payload) => ({
        url: ChangeMPINDetails,
        method: "POST",
        body: payload,
        headers: {
          Authorization: `Bearer ${API_TOKEN}`,
          authToken: `${Access_Token}`,
          traceId: "abc", // Add traceId to headers
          csUserId: "abc",
          source: "abc",
        },
      }),
    }),

    changeMPINDownload: builder.query<
      T_ApiResponse<T_MPINChangeDownload_Response>,
      T_MPINChangeDownload_Request
    >({
      query: (payload) => ({
        url: ChangeMPINDetailsReportDownload,
        method: "POST",
        body: payload,
        headers: {
          Authorization: `Bearer ${API_TOKEN}`,
          authToken: `${Access_Token}`,
          traceId: "abc", // Add traceId to headers
          csUserId: "abc",
          source: "abc",
        },
      }),
    }),
  }),
});

export const { useLazyChangeMPINDetailsQuery, useLazyChangeMPINDownloadQuery } =
  MPINChangeAPI;
