import { T_ApiResponse } from "../../globalTypes/GlobalTypes";
import { CSApiService } from "../../redux-store/CSApiService";
import {
  Api_GenerateInternalToken,
  Api_GenerateToken,
} from "../../utils/ApiEndpoints";
import { T_GenerateTokenRes, T_InternalTokenRes } from "./Login_Types";
export const loginRtk = CSApiService.injectEndpoints({
  overrideExisting: "throw",
  endpoints: (builder) => ({
    generateToken: builder.query<T_ApiResponse<T_GenerateTokenRes>, void>({
      query: (code) => ({ url: Api_GenerateToken }),
    }),
    generateInternalToken: builder.query<
      T_ApiResponse<T_InternalTokenRes>,
      string
    >({
      query: (code) => ({
        url: Api_GenerateInternalToken,
        params: {
          email: code,
        },
      }),
    }),
  }),
});

export const { useLazyGenerateInternalTokenQuery, useLazyGenerateTokenQuery } =
  loginRtk;
