import { Box, Button, Typography } from "@mui/material";
import "./CustomFailurePopupBody.scss";
import CommonGifAnimation from "../CustomGifAnimation/CustomGifAnimation";
import { T_AnimationData } from "../../globalTypes/GlobalTypes";
import AlertGif from "../../assets/gif/Exclamation_Mark.json";
import { Colors } from "../../styles/constant";
import { FC } from "react";
import { defaultEmptyFun } from "../../utils/helper";

interface CustomFailurePopupBodyProps {
  btnLabel?: string;
  btnClick?: any;
  message?: string;
}

export const CustomFailurePopupBody: FC<CustomFailurePopupBodyProps> = ({
  btnLabel,
  btnClick = defaultEmptyFun,
  message,
}) => {
  const handleClick = () => {
    btnClick();
  };
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap="1rem"
      padding="1.5rem"
    >
      <CommonGifAnimation
        width="6.25rem"
        animationData={AlertGif as T_AnimationData}
      />
      <Typography variant="h4" color={Colors.textblack}>
        failure
      </Typography>

      <Typography variant="h5" color={Colors.descriptionGray}>
        {message ?? "Your changes could not be saved"}
      </Typography>

      <Button
        fullWidth
        onClick={handleClick}
        sx={{
          backgroundColor: Colors.red,
          "&:hover": {
            backgroundColor: Colors.red, // Set hover color to red
          },
        }}
      >
        <Typography variant="h4" color={Colors.white}>
          {btnLabel?.length ? btnLabel : "Try again"}
        </Typography>
      </Button>
    </Box>
  );
};
