import { FC, useState, useEffect } from "react";
import "./ManualAdditionReport.scss";
import ReportDuration from "../ReportDuration";

import { downloadExcelFromBase64 } from "../../../utils/helper";
import { setError } from "../../../redux-store/reducers/CsErrorSlice";
import { useAppDispatch } from "../../../redux-store/CustomReduxHooks";
import {
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { useLazyFetchUserRegistrationReportByEmailQuery } from "../SRRaiseReport/SRRaiseReports_Api";
import {
  useLazyManualHoldingDownloadQuery,
  useLazyManualHoldingQuery,
} from "./ManualAdditionReportApi";
import {
  T_ManualHolding_Response,
  T_ManualHoldingDownload_Response,
} from "./T_ManualAdditionReport";

interface OnboardReport {
  abcApp: number;
  abcWeb: number;
  abcdApp: number;
}

// interface T_ManualHolding_Response {
//   HealthInsurance: number;
//   HomeLoans: number;
//   LifeInsurance: number;
//   MutualFunds: number;
//   PersonalLoansCreditCard: number;
//   StocksSecurities: number;
// }

const ManualAdditionReport: FC = () => {
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [reportType, setReportType] = useState("");
  const [showReport, setShowReport] = useState(false);
  const [fetchUserRegistrationReportByEmail] =
    useLazyFetchUserRegistrationReportByEmailQuery();
  const [fetchmanualHoldingDownload] = useLazyManualHoldingDownloadQuery();
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [emailToSend, setEmailToSend] = useState("");
  const [manualAdditionData, setManualAdditiondata] = useState<
    Partial<T_ManualHolding_Response>
  >({
    "Health Insurance": 0,
    "Home Loans": 0,
    "Life Insurance": 0,
    "Mutual Funds": 0,
    "Personal Loans/Credit Card": 0,
    "Stocks & Securities": 0,
  });

  const dispatch = useAppDispatch();
  const [reportDumpFlag, setReportDumpFlag] = useState(false);
  const [fetchmanualHoldingReport] = useLazyManualHoldingQuery();

  const fetchReport = async () => {
    try {
      const res = await fetchmanualHoldingReport({
        fromDate: fromDate,
        toDate: toDate,
      });

      if (res && res?.data?.payload) {
        // Log the response structure to debug
        console.log("Response from API:", res?.data);
        console.log(res.data.payload, "manual Addition");
        setManualAdditiondata(res.data.payload);
        // Assuming payload is the expected structure
        // setManualAdditiondata(res?.data?.payload);
      } else {
        console.error("Unexpected response structure:", res);
        // Handle the error if payload is missing or in unexpected format
        dispatch(
          setError({
            error: new Error("Unexpected response structure"),
            message: "Failed to fetch manual addition data.",
          })
        );
      }
    } catch (error) {
      console.error("Error fetching report:", error);
      dispatch(
        setError({
          error: new Error(
            error instanceof Error ? error.message : "Unknown error"
          ),
          message: "Error fetching manual addition report.",
        })
      );
    }
  };

  const fetchReportDownload = async () => {
    try {
      const res = await fetchmanualHoldingDownload({
        fromDate: fromDate,
        toDate: toDate,
      });

      if (res && res?.data) {
        console.log(res, "manual");
        downloadExcelFromBase64(
          res?.data?.payload?.fileDetails?.attachment,
          res?.data?.payload?.fileDetails?.filename
        );
      } else {
        dispatch(
          setError({
            error: new Error("Error"),
            message: "XLS Download failed",
          })
        );
      }
    } catch (error) {
      dispatch(
        setError({
          error: new Error("Error"),
          message: "XLS Download failed",
        })
      );
    }
  };

  useEffect(() => {
    if (showReport) {
      fetchReport();
    }
  }, [showReport, fromDate, toDate]);

  const handleGenerateReport = (
    selectedFromDate: string,
    selectedToDate: string
  ) => {
    setFromDate(selectedFromDate);
    setToDate(selectedToDate);
    setShowReport(true);
  };

  const handleDownload = () => {
    fetchReportDownload();
  };

  return (
    <Box className="report-details-page-manualddition">
      <Typography variant="h3">Manual Additions Report</Typography>
      <ReportDuration onGenerate={handleGenerateReport} />

      {showReport && (
        <Box className="report-table-manualAddition">
          <TableContainer className="table-container" component={Box}>
            <Table>
              <TableHead className="table-header">
                <TableRow>
                  <TableCell className="table-header-cell">LOB</TableCell>
                  <TableCell className="table-header-cell">
                    Manual Additions Count
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* Example to render manual addition data */}
                {manualAdditionData &&
                  Object.entries(manualAdditionData).map(([key, value]) => (
                    <TableRow key={key}>
                      <TableCell className="table-body-cell">{key}</TableCell>
                      <TableCell className="table-body-cell">{value}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Box className="email-download-buttons">
            <Button
              onClick={handleDownload}
              className="button"
              variant="outlined"
              fullWidth
            >
              Download
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ManualAdditionReport;
