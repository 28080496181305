import {
  useAppDispatch,
  useAppSelector,
} from "../../redux-store/CustomReduxHooks";
import APIFailurePopup from "../CustomAPIFailurePopup/CustomAPIFailurePopup";
import {
  clearError,
  ErrorEventHandler,
} from "../../redux-store/reducers/CsErrorSlice";
import { useCallback, useEffect } from "react";
import { T_ApiResponse } from "../../globalTypes/GlobalTypes";
import { useLocation } from "react-router-dom";

const ErrorComp: React.FC = () => {
  const errorState = useAppSelector((state) => state.CsErrorSlice);
  const authDispatch = useAppDispatch();
  const location = useLocation();

  const handleOpen = useCallback(() => {
    if (ErrorEventHandler.eventState.onClick) {
      ErrorEventHandler.eventState.onClick({ ...errorState });
      ErrorEventHandler.resetHandler();
    }
    authDispatch(clearError());
  }, [errorState]);

  const ApiErrorObj = errorState.error as any;
  const title = "System Under Maintenance";
  const message =
    "This service is currently under maintenance. Please try again later.";

  return errorState.error ? (
    <APIFailurePopup
      open={true}
      handleOpen={handleOpen}
      customTitle={title}
      customSubTitle={message}
    />
  ) : null;
};
export default ErrorComp;
