import { Box, Button, Typography } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import dayjs, { Dayjs } from "dayjs";
import { FC, useState } from "react";
import "./ReportDuration.scss";

interface ReportDurationProps {
  onGenerate: (fromDate: string, toDate: string) => void;
}

const ReportDuration: FC<ReportDurationProps> = ({ onGenerate }) => {
  const [fromDate, setFromDate] = useState<Dayjs | null>(null);
  const [toDate, setToDate] = useState<Dayjs | null>(null);

  const today = dayjs(); // Today's date
  const maxSelectableDate = today.endOf("day"); // Disable future dates

  // Max date for "From" date is 6 months ago from today
  const minSelectableDate = today.subtract(6, "month").startOf("day");

  const handleFromDateChange = (newValue: Dayjs | null) => {
    setFromDate(newValue);
    if (toDate && newValue && toDate.diff(newValue, "month") > 6) {
      setToDate(null); // Reset "To" date if it exceeds six months
    }
  };

  const handleToDateChange = (newValue: Dayjs | null) => {
    if (fromDate && newValue && newValue.diff(fromDate, "month") > 6) {
      alert("The duration cannot exceed six months.");
      setToDate(null); // Reset "To" date if it exceeds six months
    } else {
      setToDate(newValue);
    }
  };

  const handleGenerateReport = () => {
    if (fromDate && toDate) {
      onGenerate(
        dayjs(fromDate).format("YYYY-MM-DD"),
        dayjs(toDate).format("YYYY-MM-DD")
      );
    } else {
      alert("Please select both the Dates.");
    }
  };

  // Min date for "To" is one day after "From"
  const minToDate = fromDate ? dayjs(fromDate).add(1, "day") : undefined;

  return (
    <Box className="view-report">
      <Typography>Select the duration</Typography>
      <Box className="date-range">
        <DesktopDatePicker
          label="From"
          format="DD/MM/YYYY"
          value={fromDate}
          onChange={handleFromDateChange}
          className="date-picker"
          minDate={minSelectableDate} // Prevent selecting dates older than 6 months
          maxDate={maxSelectableDate} // Prevent selecting future dates
        />
        <DesktopDatePicker
          label="To"
          format="DD/MM/YYYY"
          value={toDate}
          onChange={handleToDateChange}
          className="date-picker"
          minDate={minToDate} // Min date for "To" is one day after "From"
          maxDate={maxSelectableDate} // Prevent selecting future dates
          disabled={!fromDate} // Disable "To" until "From" is selected
        />
      </Box>
      <Box className="view-report-button">
        <Button
          disabled={!fromDate || !toDate}
          className="popup-btn"
          onClick={handleGenerateReport}
        >
          View Report
        </Button>
      </Box>
    </Box>
  );
};

export default ReportDuration;
