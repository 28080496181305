import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  T_FetchAllModuleLob_ResponseObject,
  T_FetchMasterModule_Response,
  T_MasterManagementSliceState,
} from "./MasterManagement_Type";

const initialState: T_MasterManagementSliceState = {
  allLobDetails: [],
  allLobMasterDetails: [],
};

export const MasterManagementSlice = createSlice({
  name: "MasterManagementSlice",
  initialState,
  reducers: {
    setAllLobDetails: (
      state,
      action: PayloadAction<T_FetchMasterModule_Response[]>
    ) => {
      return {
        ...state,
        allLobDetails: action.payload,
      };
    },
    setAllLobMasterDetails: (
      state,
      action: PayloadAction<T_FetchAllModuleLob_ResponseObject[]>
    ) => {
      return {
        ...state,
        allLobMasterDetails: action.payload,
      };
    },
  },
});

export const MasterManagementReducer = MasterManagementSlice.reducer;
export const MasterManagementSliceKey = MasterManagementSlice.reducerPath;
export const { setAllLobDetails, setAllLobMasterDetails } =
  MasterManagementSlice.actions;
