const REACT_ADMIN_API_BASE_URL = process.env.REACT_APP_ADMIN_BASE_URL;
const REACT_ADMIN_AUTH_API_BASE_URL = process.env.REACT_APP_ADMIN_AUTH_BASE_URL;
const REACT_ADMIN_MODULE_BASE_URL = process.env.REACT_APP_ADMIN_API_BASE_URL;

export enum API_STATUS_CODE {
  SUCCESS = 1,
  ERROR = 0,
  BUSINESS_ERROR = 2,
}
export {};

export const GetFeaturesByLob = REACT_ADMIN_MODULE_BASE_URL + "/fetch-all-features-lob-wise";
export const UpdateFeature = REACT_ADMIN_MODULE_BASE_URL + "/update-feature";
export const FetchFeatureHistoryDetails = REACT_ADMIN_MODULE_BASE_URL
 + "/fetch-feature-history";
export const API_TOKEN =
  "eyJhbGciOiJSUzI1NiJ9.eyJzdWIiOiI4ZGU0N2UwNy1mMDI0LTRlMTUtODMzNC0zOGMwNmFlMzNkNmEiLCJ1bmlxdWVfYXNzaWduZWRfbnVtYmVyIjoiOWY4ZmMxMmItMDhjOC00M2U3LThiYzItMDhkNjJkZTQxOTE3IiwiY3JlYXRlZF90aW1lIjoiMTQgTm92ZW1iZXIsIDIwMjQgfCAxMTozOToyMyBBTSIsImV4cGlyZWRfdGltZSI6IjE0IE5vdmVtYmVyLCAyMDI0IHwgMDE6Mzk6MjMgUE0iLCJpYXQiOjE3MzE1NjQ1NjMsImlzcyI6IjhkZTQ3ZTA3LWYwMjQtNGUxNS04MzM0LTM4YzA2YWUzM2Q2YSIsImF1ZCI6Imh0dHBzOi8vaG9zdC11cmwiLCJleHAiOjE3MzE1NzE3NjN9.PpXSPiyqUqVeXCxi2fQq4Or4E7WFSczwXZhPUp5ofUpPG90UDFpunk-By2JToytXOxluYtKThfbH83qS1qE7Ew-lI6MOtHfdDhTRxU5-70hcI0C5eTVEevTkCVSr-j6Xerr6hmEMa8vwodbgjA2Ey1M627zJYZ9cp4tyeq_6dWeHjkLddL19HeJhU1rxuixPSo8ghTe0F4bcMceaAPU0FqOW2OERmINaDrW3TibglvKt7442ZJ5_5pJotE5TOCh0YD1fe6o2kurBAVjL28NaiFIXBGZ8Iuky5eATI3NRDdo5JUm0Ur7IZltrc5oAOV_SDunxHKQevvXdrrItFfnIVw";
export const Access_Token =
  "eyJraWQiOiJHY3llWkhabnJQNHNuWm1ubHdiSWRQdEh0UzBxSmxneSs4YTdDNG5pTGVJPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiIyYmhiZmZhbmF1N3FwZmwxYjhnZzFiaGYxayIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoiYXV0aFwvc3ZjYXBwIiwiYXV0aF90aW1lIjoxNzMxNTY0NTY0LCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAuYXAtc291dGgtMS5hbWF6b25hd3MuY29tXC9hcC1zb3V0aC0xXzBIOHR4Qm9YVSIsImV4cCI6MTczMTU2ODE2NCwiaWF0IjoxNzMxNTY0NTY0LCJ2ZXJzaW9uIjoyLCJqdGkiOiI4YzkwNGExNi03OGYzLTQ5NWQtODMyMi0zOTQ5ZjhiM2NhOTUiLCJjbGllbnRfaWQiOiIyYmhiZmZhbmF1N3FwZmwxYjhnZzFiaGYxayJ9.KMR_w4EfqlCWpbU_N2JHMl9-L-FPUpeSgpKNPSl1SD2P8ZrdSlPZArbTqH-kXGmzGolrKLRybHPgpTDOXrWTihhMYQG4avA6RyezeE1JQ9MuDP9ePOa7S7jyc8TbtT2V3xqik4Xnfefm2MgIAVbjI-yJ8nAF7XTBnydfIMyTS-s1KNzYZSkbG9FAciNMHCjzyZO73XNjkioW0n1dG7ZTlVBXdxGwcI2YJVirqDjYEkyCm1TNgLVF34QtQaRIOn6BEBzHE-T6VLk-YrYXc7mC96_7Olu900waljpWPfpvuV2nL1JPsWOWc79BmtTwoeH7hQR3HszTe3fDCNpgWEOo8g";
export const UserRegistrationReport = REACT_ADMIN_AUTH_API_BASE_URL+"/report/admin/user-registration-report";

export const UserRegistrationReportByEmail = REACT_ADMIN_AUTH_API_BASE_URL+"/report/admin/user-registration-report/email";
export const UserRegistrationReportByDownload = REACT_ADMIN_AUTH_API_BASE_URL+"/report/admin/user-registration-report/download";

export const UserLoginReport = REACT_ADMIN_AUTH_API_BASE_URL+"/report/admin/user-login-report";
export const UserLoginReportByEmail = REACT_ADMIN_AUTH_API_BASE_URL+"/report/admin/user-login-report/email";
export const UserLoginnReportByDownload = REACT_ADMIN_AUTH_API_BASE_URL+"/report/admin/user-login-report/download";

export const FetchAllAdminDetails = REACT_ADMIN_MODULE_BASE_URL+"/fetch-all-admins";
export const FetchAllProfile = REACT_ADMIN_MODULE_BASE_URL+"/fetch-all-profiles";
export const CreateUpdateProfile = REACT_ADMIN_MODULE_BASE_URL+"/create-update-profile";

export const SubmitAdminProfileMenu = REACT_ADMIN_MODULE_BASE_URL+"/submit-admin-profile-menus";
export const AddUpdateCustomerMobile = REACT_ADMIN_MODULE_BASE_URL+"/add-update-customer";
export const AddUpdateCustomerProfile = REACT_ADMIN_MODULE_BASE_URL+"/add-update-customer-profile";
export const CheckExistingProfile = REACT_ADMIN_MODULE_BASE_URL+"/check-existing-profile";

export const DeactivateProfile = REACT_ADMIN_MODULE_BASE_URL+"/deactivate-profile";
export const FetchCustomerProfileByiD = REACT_ADMIN_MODULE_BASE_URL+"/fetch-customer-profile-by-id";
export const FetchCustomerMobileFromProfileID = REACT_ADMIN_MODULE_BASE_URL+"/fetch-customer-mobile-numbers-by-profile-id";
export const InativeMobileFromProfile = REACT_ADMIN_MODULE_BASE_URL+"/inactive-mobile-from-profile";
// export const FetchCustomerDetailsFromMobileNumber =
//   "https://sg-test.eastus.cloudapp.azure.com/apiadmin/admin/fetch-customer-details-from-mobile-number";
export const FetchCustomerDetailsFromMobileNumber = REACT_ADMIN_MODULE_BASE_URL+"/fetch-customer-details-from-mobile-number";
export const GetMenusModulewiseByAdminProfile = REACT_ADMIN_MODULE_BASE_URL+"/get-all-menu-by-admin-profile";
//  "https://sg-test.eastus.cloudapp.azure.com/apiadmin/admin/get-menu-module-wise-by-admin-profile"
export const GetFeatureLobwiseByAdminProfile = REACT_ADMIN_MODULE_BASE_URL+"/get-features-lob-wise-by-admin-profile";
// export const Api_FetchMasterData =
//   " https://uatoneservice.adityabirlacapital.com/generalapp/abfssl/fetch-master-data";
export const Api_FetchMasterData = REACT_ADMIN_AUTH_API_BASE_URL+"/abfssl/fetch-master-data";
export const Api_UpdateOtp = REACT_ADMIN_MODULE_BASE_URL+"/update-otp-details";
export const Api_VersionHistory = REACT_ADMIN_MODULE_BASE_URL+"/version-history";

export const Api_GenerateInternalToken =
  REACT_ADMIN_API_BASE_URL + "/login/get-token-details-by-email";

export const Api_GenerateToken =
  REACT_ADMIN_AUTH_API_BASE_URL + "/auth/generate-token";

  // export const Api_GenerateToken =
  // REACT_ADMIN_MODULE_BASE_URL + "/auth/generate-token";



//User management
export const Api_CreateUpdateAdminDetails =
  REACT_ADMIN_MODULE_BASE_URL+"/add-update-admin-details";

//Master management
export const Api_FetchMasterManagementModule =
  REACT_ADMIN_MODULE_BASE_URL + "/fetch-all-lobs";
export const Api_FetchMasterManagementMasterTypeData =
  REACT_ADMIN_MODULE_BASE_URL + "/fetch-all-master-types";
export const Api_FetchMasterTypeByLob =
  REACT_ADMIN_MODULE_BASE_URL + "/fetch-unique-master-type-by-lob-id";
export const Api_FetchAllModuleLob =
  REACT_ADMIN_MODULE_BASE_URL + "/fetch-all-module-lob";
export const Api_AddEditMasterModule =
  REACT_ADMIN_MODULE_BASE_URL + "/add-edit-module";
export const Api_FetchMasterDetailsByLob =
  REACT_ADMIN_MODULE_BASE_URL + "/fetch-master-details-by-lob-id";

export const Api_allLobsHistory =
  REACT_ADMIN_MODULE_BASE_URL + "/fetch-all-lobs-history";

export const Api_masterHistory =
  REACT_ADMIN_MODULE_BASE_URL+"/fetch-master-history";
export const Api_AddEditMasterDetailsByUniqueId =
  REACT_ADMIN_MODULE_BASE_URL + "/edit-master-details-by-unique-id";

export const ChangeMPINDetails =
  REACT_ADMIN_AUTH_API_BASE_URL+"/report/admin/change-mpin/details";

export const ChangeMPINDetailsReportDownload =
  REACT_ADMIN_AUTH_API_BASE_URL+"/report/admin/change-mpin/download";

export const ManualHoldingReport =
  REACT_ADMIN_AUTH_API_BASE_URL+"/report/admin/fetch-manual-holding-report";

export const ManualholdingReportDownload =
  REACT_ADMIN_AUTH_API_BASE_URL+"/report/admin/download-manual-holding-report";

export const srDetailsReport =
  REACT_ADMIN_AUTH_API_BASE_URL+"/report/admin/sr-details";

export const srDetailsReportDownload =
  REACT_ADMIN_AUTH_API_BASE_URL+"/report/admin/sr-details/download";

export const redirectionDetails =
  REACT_ADMIN_AUTH_API_BASE_URL+"/report/admin/redirection/details";

export const redirectionDetailsDownload =
  REACT_ADMIN_AUTH_API_BASE_URL+"/report/admin/redirection/details/download";

//Channel Management
export const Api_FetchAllChannels =
  REACT_ADMIN_MODULE_BASE_URL + "/fetch-all-channels";
export const Api_FetchFeaturesByChannel =
  REACT_ADMIN_MODULE_BASE_URL + "/fetch-features-for-channel";
export const Api_CreateUpdateChannel =
  REACT_ADMIN_MODULE_BASE_URL + "/create-update-channel-feature";
