import { FC, useEffect, useState } from "react";
import "./MasterManagement.scss";
import {
  Box,
  Button,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Colors } from "../../styles/constant";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import {
  masterManagementDropDownOptions,
  masterManagementLobMasterTableHeaderList,
  masterManagementTableHeaderList,
} from "../../utils/constants";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  useLazyAddUpdateMasterModuleByUniqueIdQuery,
  useLazyAddUpdateMasterModuleQuery,
  useLazyFetchAllModuleLobQuery,
  useLazyFetchMasterDetailsByLobAndMasterTypeCodeQuery,
  useLazyFetchMasterModuleQuery,
  useLazyFetchMasterTypeByLobQuery,
} from "./MasterManagement_Api";
import { API_STATUS_CODE } from "../../utils/ApiEndpoints";
import { useDispatch } from "react-redux";
import {
  setAllLobDetails,
  setAllLobMasterDetails,
} from "./MasterManagement_Slice";
import { useAppSelector } from "../../redux-store/CustomReduxHooks";
import { setError } from "../../redux-store/reducers/CsErrorSlice";
import {
  T_AddUpdateMasterModule_Request,
  T_AddUpdateMasterModule_RequestObject,
  T_AddUpdateMasterModuleByUniqueId_Request,
  T_FetchAllModuleLob_Response,
  T_FetchAllModuleLob_ResponseObject,
  T_FetchMasterDetailsByLobAndMasterTypeCode_Response,
  T_FetchMasterDetailsByLobCode_ResponseObject,
  T_FetchUniqueMasterTypeByLob_Response,
} from "./MasterManagement_Type";
import CustomThreeDotsLoader from "../../components/CustomThreeDotsLoader/CustomThreeDotsLoader";
import CustomPopup from "../../components/CustomPopup/CustomPopup";
import CommonSuccessPopupBody from "../../components/CustomSuccessPopupBody/CustomSuccessPopupBody";
import { useNavigate } from "react-router-dom";
import { defaultEmptyFun } from "../../utils/helper";
import { CustomFailurePopupBody } from "../../components/CustomFailurePopupBody/CustomFailurePopupBody";
import errorOutlineIcon from "../../assets/icons/error-outline.svg";

interface Option {
  label: string;
  value: string;
}

const MasterManagement: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [masterValue, setMasterValue] = useState<string>("");
  const [moduleValue, setModuleValue] = useState<string>("");
  const [masterTypeValue, setMasterTypeValue] = useState<string>("");
  const [moduleOptions, setModuleOptions] = useState<Option[]>([]);
  const [masterTypeOptions, setMasterTypeOptions] = useState<Option[]>([]);
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageSize] = useState<number>(10);
  const [successPopUpOpen, setSuccessPopUpOpen] = useState<boolean>(false);
  const [errorPopUpOpen, setErrorPopUpOpen] = useState<boolean>(false);
  const [submitPopUpOpen, setSubmitPopUpOpen] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState<number>(9999);
  const [tableMasterTotalElements, setTableMasterTotalElements] =
    useState<number>(9999);
  const [lobMasterTotalElements, setLobMasterTotalElements] =
    useState<number>(9999);
  const [tableMasterTempData, setTableMasterTempData] = useState<
    T_FetchMasterDetailsByLobCode_ResponseObject[]
  >([]);
  const [lobMasterTempData, setLobMasterTempData] = useState<
    T_FetchAllModuleLob_ResponseObject[]
  >([]);
  const [masterTypeDataFromModuleApi, setMasterTypeDataFromModuleApi] =
    useState<T_FetchUniqueMasterTypeByLob_Response>([]);

  const [fetchMasterTypeByLobApi] = useLazyFetchMasterTypeByLobQuery();
  const [fetchAllLobMasterDataApi] = useLazyFetchAllModuleLobQuery();
  const [addUpdateMasterApi] = useLazyAddUpdateMasterModuleQuery();
  const [addUpdateMasterByUniqueIdApi] =
    useLazyAddUpdateMasterModuleByUniqueIdQuery();
  const [fetchMasterDetailsByLob] =
    useLazyFetchMasterDetailsByLobAndMasterTypeCodeQuery();
  const [fetchAllLob] = useLazyFetchMasterModuleQuery();
  const lobModuleDetails = useAppSelector(
    (state) => state.MasterManagementSlice.allLobDetails
  );
  const lobMasterTableData = useAppSelector(
    (state) => state.MasterManagementSlice.allLobMasterDetails
  );

  const handleEditToggle = (index: number, isLobMasterTable?: boolean) => {
    if (!isLobMasterTable) {
      if (editingIndex === index) {
        setEditingIndex(null); // Exit edit mode if clicked again
        setTableMasterTempData(tableMasterTempData); // Reset to original data
      } else {
        setEditingIndex(index); // Set current index as being edited
        setTableMasterTempData([...tableMasterTempData]); // Clone data for editing
      }
    } else {
      if (editingIndex === index) {
        setEditingIndex(null); // Exit edit mode if clicked again
        setLobMasterTempData(lobMasterTempData); // Reset to original data
      } else {
        setEditingIndex(index); // Set current index as being edited
        setLobMasterTempData([...lobMasterTempData]); // Clone data for editing
      }
    }
  };

  const handleTableMasterFormValuesChange = (
    index: number,
    field: string,
    value: any,
    isLobMasterTable?: boolean
  ) => {
    if (!isLobMasterTable) {
      const updatedData = [...tableMasterTempData];
      updatedData[index] = { ...updatedData[index], [field]: value };
      setTableMasterTempData(updatedData);
    } else {
      const updatedData = [...lobMasterTempData];
      updatedData[index] = { ...updatedData[index], [field]: value };
      setLobMasterTempData(updatedData);
    }
  };

  useEffect(() => {
    setIsBtnLoading(true);
    fetchAllLob({})
      .then((res) => {
        if (res.error && res.isError) {
          setIsBtnLoading(false);
          dispatch(
            setError({
              error: new Error("Error"),
              message: "fetchAllLob api failed",
            })
          );
        }
        if (res.data?.code === API_STATUS_CODE.SUCCESS) {
          setIsBtnLoading(false);
          const moduleData = res.data.payload.map((module) => ({
            label: module.description,
            value: module.description,
          }));
          setModuleOptions(moduleData);
          dispatch(setAllLobDetails(res?.data?.payload));
        }
      })
      .catch((err) => {
        setIsBtnLoading(false);
        dispatch(
          setError({
            error: new Error(err),
            message: "fetchAllLob api failed",
          })
        );
      });
  }, []);

  useEffect(() => {
    setIsBtnLoading(true);
    fetchAllLobMasterDataApi({
      pageNumber: currentPage === 0 ? 0 : currentPage,
      pageSize: pageSize,
    }).then((res) => {
      if (res.error && res.isError) {
        setIsBtnLoading(false);
        dispatch(
          setError({
            error: new Error("Error"),
            message: "fetchAllLobMasterDataApi api failed",
          })
        );
      }
      if (res.data?.code === API_STATUS_CODE.SUCCESS && res.data.payload) {
        setIsBtnLoading(false);

        setLobMasterTempData(res?.data?.payload.data);
        setLobMasterTotalElements(res?.data?.payload.totalElements);
        dispatch(setAllLobMasterDetails(res?.data?.payload?.data));
      }
    });
  }, [currentPage]);

  useEffect(() => {
    setIsBtnLoading(true);
    if (moduleValue) {
      const lobCodeFromModuleOptionsSlice =
        lobModuleDetails.find((module) => {
          return module.description === moduleValue;
        })?.lobCode || "";
      fetchMasterTypeByLobApi({ lobCode: lobCodeFromModuleOptionsSlice }).then(
        (res) => {
          setTableMasterTempData([]);
          if (res.isError && res.error) {
            setIsBtnLoading(false);
            dispatch(
              setError({
                error: new Error("Error"),
                message: "fetchMasterTypeByLob API failed",
              })
            );
          }

          if (
            res?.data?.code === API_STATUS_CODE.SUCCESS &&
            res.data?.payload.length
          ) {
            setIsBtnLoading(false);
            const masterTypeByLob = res?.data?.payload;
            const masterTypeData = masterTypeByLob.map((masterType) => ({
              label: masterType.description,
              value: masterType.description,
            }));
            setMasterTypeDataFromModuleApi(masterTypeByLob);
            setMasterTypeOptions(masterTypeData);
          } else {
            setIsBtnLoading(false);
            const masterTypeData = [
              {
                label: "",
                value: "",
              },
            ];
            setMasterTypeOptions(masterTypeData);
          }
        }
      );
    }
  }, [moduleValue]);

  useEffect(() => {
    setIsBtnLoading(true);
    if (moduleValue && masterTypeValue) {
      const lobCodeFromModuleOptionsSlice =
        lobModuleDetails.find((module) => {
          return module.description === moduleValue;
        })?.lobCode || "";
      const masterTypeCode =
        masterTypeDataFromModuleApi.find((masterType) => {
          return masterType.description === masterTypeValue;
        })?.masterTypeCode || "";
      fetchMasterDetailsByLob({
        lobCode: lobCodeFromModuleOptionsSlice,
        masterTypeCode: masterTypeCode,
        pageNumber: currentPage === 0 ? 0 : currentPage,
        pageSize: pageSize,
      })
        .then((res) => {
          if (res.isError && res.error) {
            setIsBtnLoading(false);
            dispatch(
              setError({
                error: new Error("Error"),
                message: "fetchMasterDetailsByLob API failed",
              })
            );
          }
          if (
            res?.data?.code === API_STATUS_CODE.SUCCESS &&
            res.data.payload?.data.length
          ) {
            setIsBtnLoading(false);
            const tableMasterDetailData = res?.data?.payload?.data;
            setTableMasterTotalElements(res?.data?.payload?.totalElements);
            setTableMasterTempData(tableMasterDetailData);
          }
        })
        .catch((err) => {
          setIsBtnLoading(false);
          dispatch(
            setError({
              error: new Error("Error"),
              message: "fetchMasterDetailsByLob API failed",
            })
          );
        });
    }
  }, [moduleValue, masterTypeValue, currentPage]);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };

  const handleTableMasterDetailsSave = (index: number) => {
    setIsBtnLoading(true);
    setSubmitPopUpOpen(false);
    const { masterDetailsUniqueId, downstreamKey, downstreamValue } =
      tableMasterTempData[index];
    const payload: T_AddUpdateMasterModuleByUniqueId_Request = {
      uniqueId: masterDetailsUniqueId,
      downstreamKey: downstreamKey,
      downstreamValue: downstreamValue,
    };
    addUpdateMasterByUniqueIdApi(payload)
      .then((res) => {
        if (res.error && res.isError) {
          setIsBtnLoading(false);
          setSuccessPopUpOpen(false);
          dispatch(
            setError({
              error: new Error("Error"),
              message: "addUpdateMasterByUniqueIdApi API failed",
            })
          );
        }
        if (res.data?.code === API_STATUS_CODE.SUCCESS) {
          setSuccessPopUpOpen(true);
          setIsBtnLoading(false);
          setEditingIndex(null);
        } else {
          setErrorPopUpOpen(true);
          setIsBtnLoading(false);
        }
      })
      .catch((err) => {
        setIsBtnLoading(false);
        setSuccessPopUpOpen(false);
        dispatch(
          setError({
            error: new Error("Error"),
            message: "addUpdateMasterByUniqueIdApi API failed",
          })
        );
      });
  };

  const handleLobMasterTableDetailsSave = (index: number) => {
    setIsBtnLoading(true);
    setSubmitPopUpOpen(false);
    const {
      uniqueId,
      lobCode,
      description,
      downstreamKey,
      lobRedirectionalUrl,
      isActive,
      isLob,
      username,
    } = lobMasterTempData[index];
    const payload: T_AddUpdateMasterModule_RequestObject = {
      uniqueId,
      lob_code: lobCode,
      description: description,
      downstreamKey: downstreamKey,
      lobRedirectionalUrl: lobRedirectionalUrl,
      isActive: isActive,
      isLob: isLob,
      username: username || "",
    };
    addUpdateMasterApi([payload])
      .then((res) => {
        if (res.error && res.isError) {
          setIsBtnLoading(false);
          setSuccessPopUpOpen(false);
          dispatch(
            setError({
              error: new Error("Error"),
              message: "addUpdateMasterApi API failed",
            })
          );
        }
        if (res?.data?.code === API_STATUS_CODE.SUCCESS) {
          setSuccessPopUpOpen(true);
          setIsBtnLoading(false);
          setEditingIndex(null);
        } else {
          setErrorPopUpOpen(true);
          setIsBtnLoading(false);
        }
      })
      .catch((err) => {
        setIsBtnLoading(false);
        setSuccessPopUpOpen(false);
        dispatch(
          setError({
            error: new Error("Error"),
            message: "addUpdateMasterApi API failed",
          })
        );
      });
  };

  let isPaginationVisible: boolean = false;
  if (masterValue === masterManagementDropDownOptions[0].value) {
    if (moduleValue && masterTypeValue && tableMasterTempData.length) {
      isPaginationVisible = true;
    } else {
      isPaginationVisible = false;
    }
  } else if (masterValue === masterManagementDropDownOptions[1].value) {
    if (lobMasterTempData.length) {
      isPaginationVisible = true;
    } else {
      isPaginationVisible = false;
    }
  }

  const handleSave = (index: number) => {
    setSelectedIndex(index);
    setSubmitPopUpOpen(true);
  };

  return (
    <>
      <Box display="flex" flexDirection="column" gap="1.5rem">
        <Typography variant="h3" color={Colors.black}>
          Master Management
        </Typography>
        <Box display="flex" flexDirection="column" gap=".9375rem">
          <Typography variant="subtitle2" color={Colors.descriptionGray}>
            Please select Master
          </Typography>
          <CustomSelect
            value={masterValue}
            onChange={setMasterValue}
            options={masterManagementDropDownOptions}
          />
        </Box>
        {masterValue === masterManagementDropDownOptions[0].value && (
          <>
            <Box display="flex" flexDirection="column" gap=".9375rem">
              <Typography variant="subtitle2" color={Colors.descriptionGray}>
                Please select Module
              </Typography>
              <CustomSelect
                value={moduleValue}
                onChange={setModuleValue}
                options={moduleOptions}
              />
            </Box>
            <Box display="flex" flexDirection="column" gap=".9375rem">
              <Typography variant="subtitle2" color={Colors.descriptionGray}>
                Please select Master Type
              </Typography>
              <CustomSelect
                value={masterTypeValue}
                onChange={setMasterTypeValue}
                options={masterTypeOptions}
              />
            </Box>
          </>
        )}
        {isBtnLoading ? <CustomThreeDotsLoader /> : <></>}
        {masterValue === masterManagementDropDownOptions[0].value &&
          moduleValue &&
          masterTypeValue && (
            <TableContainer
              component={Paper}
              sx={{
                borderTopLeftRadius: "1.125rem",
                borderTopRightRadius: "1.125rem",
              }}
            >
              <Table aria-label="master table">
                <TableHead>
                  <TableRow
                    sx={{
                      backgroundColor: Colors.bgGray,
                    }}
                  >
                    {masterManagementTableHeaderList.map((header, index) => (
                      <TableCell
                        key={index}
                        sx={{
                          borderRight:
                            index !== masterManagementTableHeaderList.length - 1
                              ? ".0625rem solid #E3E4E5"
                              : "none", // Adds border to all but the last cell
                        }}
                      >
                        <Typography
                          variant="h5"
                          lineHeight="1.375rem"
                          color="#212121"
                        >
                          {" "}
                          {header}
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableMasterTempData.map((row, rowIndex) => (
                    <TableRow key={rowIndex}>
                      <TableCell
                        sx={{
                          borderRight: ".0625rem solid  #F1F3F6",
                          borderBottom: "none",
                        }}
                      >
                        <TextField
                          value={rowIndex + 1}
                          sx={{
                            width: "4.6875rem",
                            height: "3rem",
                            borderRadius: "1.25rem",
                            padding: ".75rem 1rem .75rem 1rem",
                            "& .MuiInputBase-root": {
                              backgroundColor: "#E6E6E6",
                            },
                          }}
                          disabled
                        />
                      </TableCell>
                      <TableCell
                        sx={{
                          borderRight: ".0625rem solid  #F1F3F6",
                          borderBottom: "none",
                        }}
                      >
                        <TextField
                          value={row.lobDescription}
                          sx={{
                            width: "7.1875rem",
                            height: "3rem",
                            borderRadius: "1.25rem",
                            padding: ".75rem 1rem .75rem 1rem",
                            "& .MuiInputBase-root": {
                              backgroundColor: "#E6E6E6",
                            },
                          }}
                          disabled
                        />
                      </TableCell>
                      <TableCell
                        sx={{
                          borderRight: ".0625rem solid  #F1F3F6",
                          borderBottom: "none",
                        }}
                      >
                        <TextField
                          value={row.masterTypeDescription}
                          sx={{
                            width: "7.1875rem",
                            height: "3rem",
                            borderRadius: "1.25rem",
                            padding: ".75rem 1rem .75rem 1rem",
                            "& .MuiInputBase-root": {
                              backgroundColor: "#E6E6E6",
                            },
                          }}
                          disabled
                        />
                      </TableCell>
                      <TableCell
                        sx={{
                          borderRight: ".0625rem solid  #F1F3F6",
                          borderBottom: "none",
                        }}
                      >
                        <TextField
                          name="downStreamKey"
                          value={row.downstreamKey}
                          sx={{
                            width: "11.875rem",
                            height: "3rem",
                            borderRadius: "1.25rem",
                            padding: ".75rem 1rem .75rem 1rem",
                            "& .MuiInputBase-root": {
                              backgroundColor: "#F1F3F6",
                            },
                          }}
                          onChange={(e) =>
                            handleTableMasterFormValuesChange(
                              rowIndex,
                              "downstreamKey",
                              e.target.value
                            )
                          }
                          disabled={editingIndex !== rowIndex}
                        />
                      </TableCell>
                      <TableCell
                        sx={{
                          borderRight: ".0625rem solid  #F1F3F6",
                          borderBottom: "none",
                        }}
                      >
                        <TextField
                          name="downStreamValue"
                          value={row.downstreamValue}
                          sx={{
                            width: "7rem",
                            height: "3rem",
                            borderRadius: "1.25rem",
                            padding: ".75rem 1rem .75rem 1rem",
                            "& .MuiInputBase-root": {
                              backgroundColor: "#F1F3F6",
                            },
                          }}
                          onChange={(e) =>
                            handleTableMasterFormValuesChange(
                              rowIndex,
                              "downstreamValue",
                              e.target.value
                            )
                          }
                          disabled={editingIndex !== rowIndex}
                        />
                      </TableCell>
                      <TableCell
                        sx={{
                          borderRight: ".0625rem solid  #F1F3F6",
                          borderBottom: "none",
                        }}
                      >
                        <Box
                          display="flex"
                          flexDirection="row"
                          gap="1.5rem"
                          alignItems="center"
                        >
                          <TextField
                            value={row.isActive === 1 ? "Yes" : "No"}
                            sx={{
                              width: "7rem",
                              height: "3rem",
                              borderRadius: "1.25rem",
                              padding: ".75rem 1rem .75rem 1rem",
                              "& .MuiInputBase-root": {
                                backgroundColor: "#E6E6E6",
                              },
                            }}
                            disabled
                          />
                          <Box
                            display="flex"
                            alignItems="center"
                            gap=".6875rem"
                          >
                            {editingIndex !== rowIndex ? (
                              <>
                                <Button
                                  sx={{
                                    width: "4.75rem",
                                    height: "2.1875rem",
                                    border: ".0625rem solid #C7222A ",
                                    borderRadius: ".5rem",
                                    padding: ".625rem 1rem .625rem 1rem",
                                    gap: ".25rem",
                                  }}
                                  onClick={() => handleEditToggle(rowIndex)}
                                >
                                  <EditOutlinedIcon
                                    sx={{ width: ".75rem", height: ".75rem" }}
                                  />
                                  <Typography variant="h6" color={Colors.red}>
                                    Edit
                                  </Typography>
                                </Button>
                              </>
                            ) : (
                              <>
                                <Button
                                  sx={{
                                    width: "4.75rem",
                                    height: "2.1875rem",
                                    border: ".0625rem solid #C7222A ",
                                    borderRadius: ".5rem",
                                    padding: ".625rem 1rem .625rem 1rem",
                                    gap: ".25rem",
                                  }}
                                  onClick={() => handleEditToggle(rowIndex)}
                                >
                                  <Typography variant="h6" color={Colors.red}>
                                    Cancel
                                  </Typography>
                                </Button>
                              </>
                            )}

                            <Button
                              sx={{
                                backgroundColor: "#FFE7E5",
                                width: "4rem",
                                height: "2.1875rem",
                                border: ".0625rem solid #C7222A ",
                                borderRadius: ".5rem",
                                padding: ".625rem 1rem .625rem 1rem",
                                gap: ".25rem",
                                borderColor:
                                  editingIndex !== rowIndex
                                    ? "#AFB3C0"
                                    : Colors.red,
                              }}
                              onClick={() => handleSave(rowIndex)}
                              disabled={editingIndex !== rowIndex}
                            >
                              <Typography
                                variant="h6"
                                color={
                                  editingIndex !== rowIndex
                                    ? Colors.white
                                    : Colors.red
                                }
                              >
                                Save
                              </Typography>
                            </Button>
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        {masterValue === masterManagementDropDownOptions[1].value && (
          <TableContainer
            component={Paper}
            sx={{
              borderTopLeftRadius: "1.125rem",
              borderTopRightRadius: "1.125rem",
            }}
          >
            <Table aria-label="master table">
              <TableHead>
                <TableRow
                  sx={{
                    backgroundColor: Colors.bgGray,
                  }}
                >
                  {masterManagementLobMasterTableHeaderList.map(
                    (header, index) => (
                      <TableCell
                        key={index}
                        sx={{
                          borderRight:
                            index !==
                            masterManagementLobMasterTableHeaderList.length - 1
                              ? ".0625rem solid #E3E4E5"
                              : "none", // Adds border to all but the last cell
                        }}
                      >
                        <Typography
                          variant="h5"
                          lineHeight="1.375rem"
                          color="#212121"
                        >
                          {" "}
                          {header}
                        </Typography>
                      </TableCell>
                    )
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {lobMasterTempData.map((row, rowIndex) => (
                  <TableRow key={rowIndex}>
                    <TableCell
                      sx={{
                        borderRight: ".0625rem solid  #F1F3F6",
                        borderBottom: "none",
                      }}
                    >
                      <TextField
                        value={rowIndex + 1}
                        sx={{
                          width: "4.6875rem",
                          height: "3rem",
                          borderRadius: "1.25rem",
                          padding: ".75rem 1rem .75rem 1rem",
                          "& .MuiInputBase-root": {
                            backgroundColor: "#E6E6E6",
                          },
                        }}
                        disabled
                      />
                    </TableCell>
                    <TableCell
                      sx={{
                        borderRight: ".0625rem solid  #F1F3F6",
                        borderBottom: "none",
                      }}
                    >
                      <TextField
                        value={row.lobCode}
                        sx={{
                          width: "7rem",
                          height: "3rem",
                          borderRadius: "1.25rem",
                          padding: ".75rem 1rem .75rem 1rem",
                          "& .MuiInputBase-root": {
                            backgroundColor: "#F1F3F6",
                          },
                        }}
                        onChange={(e) =>
                          handleTableMasterFormValuesChange(
                            rowIndex,
                            "lobCode",
                            e.target.value,
                            true
                          )
                        }
                        disabled={editingIndex !== rowIndex}
                      />
                    </TableCell>
                    <TableCell
                      sx={{
                        borderRight: ".0625rem solid  #F1F3F6",
                        borderBottom: "none",
                      }}
                    >
                      <TextField
                        value={row.description}
                        sx={{
                          width: "7.125rem",
                          height: "3rem",
                          borderRadius: "1.25rem",
                          padding: ".75rem 1rem .75rem 1rem",
                          "& .MuiInputBase-root": {
                            backgroundColor: "#E6E6E6",
                          },
                        }}
                        disabled
                      />
                    </TableCell>
                    <TableCell
                      sx={{
                        borderRight: ".0625rem solid  #F1F3F6",
                        borderBottom: "none",
                      }}
                    >
                      <TextField
                        value={row.downstreamKey}
                        sx={{
                          width: "13.0625rem",
                          height: "3rem",
                          borderRadius: "1.25rem",
                          padding: ".75rem 1rem .75rem 1rem",
                          "& .MuiInputBase-root": {
                            backgroundColor: "#F1F3F6",
                          },
                        }}
                        onChange={(e) =>
                          handleTableMasterFormValuesChange(
                            rowIndex,
                            "downstreamKey",
                            e.target.value,
                            true
                          )
                        }
                        disabled={editingIndex !== rowIndex}
                      />
                    </TableCell>
                    <TableCell
                      sx={{
                        borderRight: ".0625rem solid  #F1F3F6",
                        borderBottom: "none",
                      }}
                    >
                      <TextField
                        value={row.lobRedirectionalUrl}
                        sx={{
                          width: "13.0625rem",
                          height: "3rem",
                          borderRadius: "1.25rem",
                          padding: ".75rem 1rem .75rem 1rem",
                          "& .MuiInputBase-root": {
                            backgroundColor: "#F1F3F6",
                          },
                        }}
                        onChange={(e) =>
                          handleTableMasterFormValuesChange(
                            rowIndex,
                            "lobRedirectionalUrl",
                            e.target.value,
                            true
                          )
                        }
                        disabled={editingIndex !== rowIndex}
                      />
                    </TableCell>
                    <TableCell
                      sx={{
                        borderRight: ".0625rem solid  #F1F3F6",
                        borderBottom: "none",
                      }}
                    >
                      <TextField
                        value={row.isActive === true ? "Yes" : "No"}
                        sx={{
                          width: "13.0625rem",
                          height: "3rem",
                          borderRadius: "1.25rem",
                          padding: ".75rem 1rem .75rem 1rem",
                          "& .MuiInputBase-root": {
                            backgroundColor: "#F1F3F6",
                          },
                        }}
                        onChange={(e) =>
                          handleTableMasterFormValuesChange(
                            rowIndex,
                            "isActive",
                            e.target.value,
                            true
                          )
                        }
                        disabled={editingIndex !== rowIndex}
                      />
                    </TableCell>
                    <TableCell
                      sx={{
                        borderRight: ".0625rem solid  #F1F3F6",
                        borderBottom: "none",
                      }}
                    >
                      <Box
                        display="flex"
                        flexDirection="row"
                        gap="1.5rem"
                        alignItems="center"
                      >
                        <TextField
                          value={row.isLob === true ? "Yes" : "No"}
                          sx={{
                            width: "7rem",
                            height: "3rem",
                            borderRadius: "1.25rem",
                            padding: ".75rem 1rem .75rem 1rem",
                            "& .MuiInputBase-root": {
                              backgroundColor: "#F1F3F6",
                            },
                          }}
                          onChange={(e) =>
                            handleTableMasterFormValuesChange(
                              rowIndex,
                              "isLob",
                              e.target.value,
                              true
                            )
                          }
                          disabled={editingIndex !== rowIndex}
                        />
                        <Box display="flex" alignItems="center" gap=".6875rem">
                          {editingIndex !== rowIndex ? (
                            <>
                              <Button
                                sx={{
                                  width: "4.75rem",
                                  height: "2.1875rem",
                                  border: ".0625rem solid #C7222A ",
                                  borderRadius: ".5rem",
                                  padding: ".625rem 1rem .625rem 1rem",
                                  gap: ".25rem",
                                }}
                                onClick={() => handleEditToggle(rowIndex, true)}
                              >
                                <EditOutlinedIcon
                                  sx={{ width: ".75rem", height: ".75rem" }}
                                />
                                <Typography variant="h6" color={Colors.red}>
                                  Edit
                                </Typography>
                              </Button>
                            </>
                          ) : (
                            <>
                              <Button
                                sx={{
                                  width: "4.75rem",
                                  height: "2.1875rem",
                                  border: ".0625rem solid #C7222A ",
                                  borderRadius: ".5rem",
                                  padding: ".625rem 1rem .625rem 1rem",
                                  gap: ".25rem",
                                }}
                                onClick={() => handleEditToggle(rowIndex, true)}
                              >
                                <Typography variant="h6" color={Colors.red}>
                                  Cancel
                                </Typography>
                              </Button>
                            </>
                          )}
                          <Button
                            sx={{
                              backgroundColor: "#FFE7E5",
                              width: "4rem",
                              height: "2.1875rem",
                              border: ".0625rem solid #C7222A ",
                              borderRadius: ".5rem",
                              padding: ".625rem 1rem .625rem 1rem",
                              gap: ".25rem",
                              borderColor:
                                editingIndex !== rowIndex
                                  ? "#AFB3C0"
                                  : Colors.red,
                            }}
                            onClick={() => handleSave(rowIndex)}
                            disabled={editingIndex !== rowIndex}
                          >
                            <Typography
                              variant="h6"
                              color={
                                editingIndex !== rowIndex
                                  ? Colors.white
                                  : Colors.red
                              }
                            >
                              Save
                            </Typography>
                          </Button>
                        </Box>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <Box className="master-management-version-history-footer">
          {isPaginationVisible && (
            <Pagination
              count={
                masterValue === masterManagementDropDownOptions[0].value
                  ? Math.ceil(tableMasterTotalElements / 10)
                  : Math.ceil(lobMasterTotalElements / 10)
              } // Assuming 10 records per page, adjust according to total data
              page={currentPage + 1}
              onChange={(event, newPage) =>
                handlePageChange(event, newPage - 1)
              }
              variant="outlined"
              shape="rounded"
            />
          )}
        </Box>
        {successPopUpOpen && (
          <CustomPopup open={successPopUpOpen} handleOpen={setSuccessPopUpOpen}>
            <CommonSuccessPopupBody
              title="Success!"
              des="Your changes have been saved"
              btnLabel="Continue"
              btnOnClick={() => setSuccessPopUpOpen(false)}
            />
          </CustomPopup>
        )}
        {errorPopUpOpen && (
          <CustomPopup open={errorPopUpOpen} handleOpen={setErrorPopUpOpen}>
            <CustomFailurePopupBody btnClick={() => setErrorPopUpOpen(false)} />
          </CustomPopup>
        )}
        <CustomPopup
          open={submitPopUpOpen}
          handleOpen={setSubmitPopUpOpen}
          className="api-failure-popup"
        >
          <Box padding="1.5rem 1rem">
            <Box display="flex" justifyContent="center">
              <img src={errorOutlineIcon} alt="no-connection" />
            </Box>
            <Box marginTop="1.5rem">
              <Typography textAlign="center" variant="h3">
                Submit
              </Typography>
              <Typography
                marginTop="1rem"
                textAlign="center"
                variant="h5"
                paddingX="1.25rem"
                color={Colors.textGray}
              >
                Are you sure you want to submit
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                gap: "1rem",
              }}
            >
              <Button
                fullWidth
                variant="outlined"
                sx={{ marginTop: "1rem" }}
                onClick={() => {
                  setSubmitPopUpOpen(false);
                }}
              >
                Cancel
              </Button>
              <Button
                fullWidth
                variant="contained"
                sx={{ marginTop: "1rem" }}
                onClick={async () => {
                  masterValue === masterManagementDropDownOptions[0].value
                    ? handleTableMasterDetailsSave(selectedIndex)
                    : handleLobMasterTableDetailsSave(selectedIndex);
                }}
              >
                Submit
              </Button>
            </Box>
          </Box>
        </CustomPopup>
      </Box>
    </>
  );
};

export default MasterManagement;
