import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Pagination,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import CustomBreadcrumbs from "../../components/CustomBreadcrumbs/CustomBreadcrumbs";
import { useLocation } from "react-router-dom";
import { setError } from "../../redux-store/reducers/CsErrorSlice";
import backArrow from "../../assets/icons/back-arrow.svg";
import "./AdminAuditHistory.scss";
import {
  useAppDispatch,
  useAppSelector,
} from "../../redux-store/CustomReduxHooks";
import CustomThreeDotsLoader from "../../components/CustomThreeDotsLoader/CustomThreeDotsLoader";
import { Colors } from "../../styles/constant";

import {
  useLazyFetchAllModuleLobQuery,
  useLazyFetchMasterModuleQuery,
  useLazyFetchMasterTypeByLobQuery,
} from "../../features/MasterManagement/MasterManagement_Api";
import { API_STATUS_CODE } from "../../utils/ApiEndpoints";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import {
  useLazyFetchFeatureHistoryDetailsQuery,
  useLazyFetchFeatureLobDetailsQuery,
} from "../../features/Features/FeaturesDetails_Api";
import {
  AuditHistoryFeatureTableHeaderName,
  AuditHistoryLOBMasterTableHeaderName,
  AuditHistoryMasterTableHeaderName,
  MasterOptions,
} from "../../utils/constants";
import { useDispatch } from "react-redux";
import { setAllLobDetails } from "../../features/MasterManagement/MasterManagement_Slice";
import { T_FetchUniqueMasterTypeByLob_Response } from "../../features/MasterManagement/MasterManagement_Type";
import {
  useLazyAllLobsHistoryQuery,
  useLazyMasterHistoryQuery,
} from "./AdminAuditHistory_Api";
import {
  dataFetchFeatureHistoryDetails,
  Feature,
} from "../../features/Features/T_FeaturesDetailsTypes";
import { Data } from "./T_AdminAuditHistoryTypes";
import { formatDate, formatTime } from "../../utils/helper";

interface Change {
  new: string;
  old: string;
}

interface ChangeData {
  downstream_value?: Change;
  downstream_key?: Change;
}

interface PayloadItem {
  adminName: string;
  modifiedDate: string;
  changes: ChangeData;
}

interface ResponseData {
  payload: PayloadItem[];
}

interface Option {
  label: string;
  value: string;
}

interface T_FetchLOBHistoryDetails_Response {
  lobCode: string;
  description: string;
  downstreamKey: string;
  lobRedirectionalUrl: string;
  username: string;
  isActive: boolean;
  modifiedDate: string;
}

// const MasterOptions: Option[] = [
//   { label: "Master Table", value: "Master Table" },
//   {
//     label: "LOB Master",
//     value: "LOB Master",
//   },
//   {
//     label: "Feature",
//     value: "Feature",
//   },
// ];

const ModuleOptions: Option[] = [
  { label: "Life Insurance", value: "Life Insurance" },
  { label: "Health Insurance", value: "Health Insurance" },
  { label: "Stocks", value: "Stocks" },
  { label: "Mutual Funds", value: "Mutual Funds" },
];

const MasterType: Option[] = [
  { label: "Policy Status", value: "Policy status" },
  { label: "Account Status", value: "Account Status" },
];

const LOBName: Option[] = [
  { label: "ABSLI", value: "ABSLI" },
  { label: "ABHI", value: "ABHI" },
  { label: "ABML", value: "ABFL" },
];

const FeatureName: Option[] = [
  { label: "Pay Premium", value: "Pay Premium" },
  { label: "Tax Certificate", value: "Tax Certificate" },
];
const AdminAuditHistory: FC = () => {
  const dispatch = useDispatch();

  const [fetchMasterHistory, { data, error, isLoading }] =
    useLazyMasterHistoryQuery();
  const appDispatch = useAppDispatch();
  const [auditHistoryData, setAuditHistoryData] = useState<PayloadItem[]>([]);
  const { state } = useLocation();
  const [masterValue, setMasterValue] = useState("");
  const [moduleValue, setModuleValue] = useState("");
  const [lobValue, setLobValue] = useState("");
  const [featureValue, setFeaturevalue] = useState("");
  const [fetchModuleApi] = useLazyFetchMasterModuleQuery();
  const [fetchAllLobMasterDataApi] = useLazyFetchAllModuleLobQuery();
  const [fetchMasterTypeByLobApi] = useLazyFetchMasterTypeByLobQuery();
  const [fetchFeatureHistoryDetails] = useLazyFetchFeatureHistoryDetailsQuery();

  const [moduleOptions, setModuleOptions] = useState<Option[]>([]);
  const [lobOptions, setLobOptions] = useState<Option[]>([]);
  const [fetchFeatureLobDetails] = useLazyFetchFeatureLobDetailsQuery();
  const [fetchAllLobsHistory] = useLazyAllLobsHistoryQuery();
  const [masterTypeValue, setMasterTypeValue] = useState<string>("");
  const [masterTypeOptions, setMasterTypeOptions] = useState<Option[]>([]);
  const [masterTypeDataFromModuleApi, setMasterTypeDataFromModuleApi] =
    useState<T_FetchUniqueMasterTypeByLob_Response>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageSize] = useState<number>(10);
  const [features, setFeatures] = useState<Feature[]>([]);
  const [featureNameOption, setFeaturenameOption] = useState<Option[]>([]);
  const [loading, setLoading] = useState<boolean>(false); // State for loading
  const [lobHistoryData, setLobHistoryData] = useState<
    T_FetchLOBHistoryDetails_Response[]
  >([]);

  const [FeatureHistory, setFeatureHistory] = useState<
    dataFetchFeatureHistoryDetails[]
  >([]);

  const [masterHistorydata, setMasterHistorydata] = useState<Data[]>([]);

  const lobModuleDetails = useAppSelector(
    (state) => state.MasterManagementSlice.allLobDetails
  );

  useEffect(() => {
    fetchModuleApi({}).then((res) => {
      if (res.error && res.isError) {
        dispatch(
          setError({
            error: new Error("Error"),
            message: "fetchAllLob api failed",
          })
        );
      }

      if (res.data?.code === API_STATUS_CODE.SUCCESS && res.data.payload) {
        // setIsBtnLoading("");
        const moduleData = res?.data?.payload.map((module) => ({
          label: module.description,
          value: module.description,
        }));
        setModuleOptions(moduleData);
        dispatch(setAllLobDetails(res?.data?.payload));
      }
    });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetchAllLobsHistory({});

        if (res?.error || res?.isError) {
          dispatch(
            setError({
              error: new Error("Error"),
              message: "fetchAllLob API failed",
            })
          );
        } else if (
          res?.data?.code === API_STATUS_CODE.SUCCESS &&
          res?.data?.payload
        ) {
          setLobHistoryData(res.data.payload);
          dispatch(setAllLobDetails(res.data.payload));
        }
      } catch (error) {
        // Handle any fetch or unexpected errors
        dispatch(
          setError({
            error: new Error("Error"),
            message: "An error occurred while fetching LOB data",
          })
        );
      }
    };

    fetchData();
  }, []); // Make sure `dispatch` is properly memoized to avoid unnecessary re-renders

  useEffect(() => {
    if (masterValue === "Feature") {
      fetchModuleApi({}).then((res) => {
        if (res.error && res.isError) {
          dispatch(
            setError({
              error: new Error("Error"),
              message: "fetchAllLob api failed",
            })
          );
        }

        if (res.data?.code === API_STATUS_CODE.SUCCESS && res.data.payload) {
          // setIsBtnLoading("");
          const moduleData = res?.data?.payload.map((module) => ({
            label: module.downstreamKey,
            value: module.lobCode,
          }));
          setLobOptions(moduleData);
          dispatch(setAllLobDetails(res?.data?.payload));
        }
      });
    }
  }, [masterValue]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await fetchFeatureLobDetails({
          lobMasterCode: lobValue,
          pageNumber: 0,
          pageSize: 1000,
        }).unwrap();

        if (res.payload) {
          setFeatures(res?.payload?.data?.features);
          const moduleData = res?.payload?.data?.features.map((module) => ({
            label: module.featureDisplayName,
            value: module.featureId.toString(),
          }));
          setFeaturenameOption(moduleData);
        } else {
          console.error("Unexpected response structure:", res);
        }
      } catch (error) {
        console.error("Error fetching features:", error);
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    fetchData();
  }, [fetchFeatureLobDetails, lobValue, pageSize, currentPage]);

  useEffect(() => {
    if (moduleValue) {
      const lobCodeFromModuleOptionsSlice =
        lobModuleDetails.find((module) => {
          return module.description === moduleValue;
        })?.lobCode || "";
      fetchMasterTypeByLobApi({ lobCode: lobCodeFromModuleOptionsSlice }).then(
        (res) => {
          if (res.isError && res.error) {
            dispatch(
              setError({
                error: new Error("Error"),
                message: "fetchMasterTypeByLob API failed",
              })
            );
          }

          if (
            res?.data?.code === API_STATUS_CODE.SUCCESS &&
            res.data?.payload.length
          ) {
            const masterTypeByLob = res?.data?.payload;
            const masterTypeData = masterTypeByLob.map((masterType) => ({
              label: masterType.description,
              value: masterType.description,
            }));
            setMasterTypeDataFromModuleApi(masterTypeByLob);
            setMasterTypeOptions(masterTypeData);
          } else {
            const masterTypeData = [
              {
                label: "",
                value: "",
              },
            ];
            setMasterTypeOptions(masterTypeData);
          }
        }
      );
    }
  }, [moduleValue]);

  useEffect(() => {
    if (moduleValue && masterTypeValue) {
      const lobCodeFromModuleOptionsSlice =
        lobModuleDetails.find((module) => {
          return module.description === moduleValue;
        })?.lobCode || "";
      const masterTypeCode =
        masterTypeDataFromModuleApi.find((masterType) => {
          return masterType.description === masterTypeValue;
        })?.masterTypeCode || "";
      const fetchData = async () => {
        try {
          const res = await fetchMasterHistory({
            masterTypeCode: parseInt(masterTypeCode, 10),
            masterLobCode: lobCodeFromModuleOptionsSlice,
            pageNumber: currentPage,
            pageSize: pageSize,
          }).unwrap();
          if (res.payload) {
            setMasterHistorydata(res?.payload?.data);
          } else {
            console.error("Unexpected response structure:", res);
          }
        } catch (error) {
          console.error("Error fetching features:", error);
        } finally {
          // setLoading(false); // Set loading to false after fetching
        }
      };
      fetchData();
    }
  }, [moduleValue, masterTypeValue]);

  useEffect(() => {
    if (lobValue && featureValue) {
      const fetchFeatureHistory = async () => {
        setLoading(true);
        try {
          const res = await fetchFeatureHistoryDetails({
            featureId: parseInt(featureValue),
            masterLobCode: lobValue,
            pageNumber: currentPage,
            pageSize: pageSize,
          }).unwrap();
          if (res.payload) {
            setFeatureHistory(res?.payload?.data);
          } else {
            console.error("Unexpected response structure:", res);
          }
        } catch (error) {
          console.error("Error fetching features:", error);
        } finally {
          setLoading(false); // Set loading to false after fetching
        }
      };
      fetchFeatureHistory();
    }
  }, [lobValue, featureValue]);

  const renderSteps = () => {
    if (masterValue === "LOB Master") {
      return (
        <Box marginTop={"1.5rem"}>
          <TableContainer component={Paper} className="otp-table">
            <Table aria-label="version history table">
              <TableHead className="otp-table-header">
                <TableRow
                  sx={{
                    backgroundColor: Colors.bgGray,
                  }}
                >
                  {AuditHistoryLOBMasterTableHeaderName.map((header, index) => (
                    <TableCell
                      key={index}
                      sx={{
                        borderRight:
                          index !==
                          AuditHistoryLOBMasterTableHeaderName.length - 1
                            ? ".0625rem solid #E3E4E5"
                            : "none", // Adds border to all but the last cell
                      }}
                    >
                      <Typography
                        variant="h5"
                        lineHeight="1.375rem"
                        color="#212121"
                      >
                        {" "}
                        {header}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {lobHistoryData?.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className="otp-table-cell">
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          className="otp-table-cell-text"
                          sx={{ marginLeft: "8px" }}
                          variant="subtitle2"
                        >
                          {row.username}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell className="otp-table-cell">
                      <Typography
                        className="otp-table-cell-text"
                        variant="subtitle2"
                      >
                        {row.description}
                      </Typography>
                    </TableCell>
                    <TableCell className="otp-table-cell">
                      <Typography
                        variant="subtitle2"
                        className="otp-table-cell-text"
                      >
                        {row.lobCode}
                      </Typography>
                    </TableCell>
                    <TableCell className="otp-table-cell">
                      <Typography
                        variant="subtitle2"
                        className="otp-table-cell-text"
                      >
                        {row.downstreamKey}
                      </Typography>
                    </TableCell>
                    <TableCell className="otp-table-cell">
                      <Typography
                        variant="subtitle2"
                        className="otp-table-cell-text"
                      >
                        {row.lobRedirectionalUrl}
                      </Typography>
                    </TableCell>
                    <TableCell className="otp-table-cell">
                      <Typography
                        variant="subtitle2"
                        className="otp-table-cell-text"
                      >
                        {row.isActive === true ? "1" : "0"}
                      </Typography>
                    </TableCell>
                    <TableCell className="otp-table-cell">
                      <Typography
                        variant="subtitle2"
                        className="otp-table-cell-text"
                      >
                        {formatDate(row.modifiedDate)}
                      </Typography>
                    </TableCell>
                    <TableCell className="otp-table-cell">
                      <Typography
                        variant="subtitle2"
                        className="otp-table-cell-text"
                      >
                        {formatTime(row.modifiedDate)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      );
    } else if (masterValue === "Master Table") {
      return (
        <Box>
          <Box>
            <Typography marginTop={"1.5rem"} color={Colors.descriptionGray}>
              Please select Module
            </Typography>
            <Box marginTop={".9375rem"}>
              <CustomSelect
                value={moduleValue}
                onChange={setModuleValue}
                options={moduleOptions}
              />
            </Box>
          </Box>

          <Box>
            <Typography marginTop={"1.5rem"} color={Colors.descriptionGray}>
              Please select Master Type
            </Typography>
            <Box marginTop={".9375rem"}>
              <CustomSelect
                value={masterTypeValue}
                onChange={setMasterTypeValue}
                options={masterTypeOptions}
              />
            </Box>
          </Box>
          {masterValue === "Master Table" && moduleValue && masterTypeValue && (
            <Box marginTop={"1.5rem"}>
              <TableContainer component={Paper} className="otp-table">
                <Table aria-label="version history table">
                  <TableHead className="otp-table-header">
                    <TableRow
                      sx={{
                        backgroundColor: Colors.bgGray,
                      }}
                    >
                      {AuditHistoryMasterTableHeaderName.map(
                        (header, index) => (
                          <TableCell
                            key={index}
                            sx={{
                              borderRight:
                                index !==
                                AuditHistoryMasterTableHeaderName.length - 1
                                  ? ".0625rem solid #E3E4E5"
                                  : "none", // Adds border to all but the last cell
                            }}
                          >
                            <Typography
                              variant="h5"
                              lineHeight="1.375rem"
                              color="#212121"
                            >
                              {" "}
                              {header}
                            </Typography>
                          </TableCell>
                        )
                      )}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {masterHistorydata?.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell className="otp-table-cell">
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Typography
                              className="otp-table-cell-text"
                              sx={{ marginLeft: "8px" }}
                              variant="subtitle2"
                            >
                              {row.createdBy}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell className="otp-table-cell">
                          <Typography
                            variant="subtitle2"
                            className="otp-table-cell-text"
                          >
                            {row.description}
                          </Typography>
                        </TableCell>
                        <TableCell className="otp-table-cell">
                          <Typography
                            variant="subtitle2"
                            className="otp-table-cell-text"
                          >
                            {row.masterTypeCode}
                          </Typography>
                        </TableCell>
                        <TableCell className="otp-table-cell">
                          <Typography
                            variant="subtitle2"
                            className="otp-table-cell-text"
                          >
                            {row.downstreamKey}
                          </Typography>
                        </TableCell>
                        <TableCell className="otp-table-cell">
                          <Typography
                            variant="subtitle2"
                            className="otp-table-cell-text"
                          >
                            {row.downstreamValue}
                          </Typography>
                        </TableCell>
                        <TableCell className="otp-table-cell">
                          <Typography
                            variant="subtitle2"
                            className="otp-table-cell-text"
                          >
                            {formatDate(row.modifiedDate)}
                          </Typography>
                        </TableCell>
                        <TableCell className="otp-table-cell">
                          <Typography
                            variant="subtitle2"
                            className="otp-table-cell-text"
                          >
                            {formatTime(row.modifiedDate)}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}

          <Box className="audit-history-footer">
            {/* <Pagination
              count={Math.ceil(masterHistorydata.length / 10)} // Assuming 10 records per page, adjust according to total data
              page={currentPage}
              // onChange={onPageChange}
              variant="outlined"
              shape="rounded"
            /> */}
          </Box>
        </Box>
      );
    } else if (masterValue === "Feature") {
      return (
        <Box>
          <Box>
            <Typography marginTop={"1.5rem"} color={Colors.descriptionGray}>
              Please select LOB
            </Typography>
            <Box marginTop={".9375rem"}>
              <CustomSelect
                value={lobValue}
                onChange={setLobValue}
                options={lobOptions}
              />
            </Box>
          </Box>

          <Box>
            <Typography marginTop={"1.5rem"} color={Colors.descriptionGray}>
              Please select Feature
            </Typography>
            <Box marginTop={".9375rem"}>
              <CustomSelect
                value={featureValue}
                onChange={setFeaturevalue}
                options={featureNameOption}
              />
            </Box>
          </Box>
          {masterValue === "Feature" && featureValue && lobValue && (
            <Box marginTop={"1.5rem"}>
              <TableContainer component={Paper} className="otp-table">
                <Table aria-label="version history table">
                  <TableHead className="otp-table-header">
                    <TableRow
                      sx={{
                        backgroundColor: Colors.bgGray,
                      }}
                    >
                      {AuditHistoryFeatureTableHeaderName.map(
                        (header, index) => (
                          <TableCell
                            key={index}
                            sx={{
                              borderRight:
                                index !==
                                AuditHistoryFeatureTableHeaderName.length - 1
                                  ? ".0625rem solid #E3E4E5"
                                  : "none", // Adds border to all but the last cell
                            }}
                          >
                            <Typography
                              variant="h5"
                              lineHeight="1.375rem"
                              color="#212121"
                            >
                              {" "}
                              {header}
                            </Typography>
                          </TableCell>
                        )
                      )}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {FeatureHistory?.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell className="otp-table-cell">
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Typography
                              className="otp-table-cell-text"
                              sx={{ marginLeft: "8px" }}
                              variant="subtitle2"
                            >
                              {row.modifiedBy.name}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell className="otp-table-cell">
                          <Typography
                            variant="subtitle2"
                            className="otp-table-cell-text"
                          >
                            {row.functionalityCode}
                          </Typography>
                        </TableCell>
                        <TableCell className="otp-table-cell">
                          <Typography
                            variant="subtitle2"
                            className="otp-table-cell-text"
                          >
                            {row.redirectionUrl ? row.redirectionUrl : null}
                          </Typography>
                        </TableCell>
                        <TableCell className="otp-table-cell">
                          <Typography
                            variant="subtitle2"
                            className="otp-table-cell-text"
                          >
                            {row.isDowntime === true ? "Yes" : "No"}
                          </Typography>
                        </TableCell>
                        <TableCell className="otp-table-cell">
                          <Typography
                            variant="subtitle2"
                            className="otp-table-cell-text"
                          >
                            {"Yes"}
                          </Typography>
                        </TableCell>
                        <TableCell className="otp-table-cell">
                          <Typography
                            variant="subtitle2"
                            className="otp-table-cell-text"
                          >
                            {row.isLive === true ? "Yes" : "No"}
                          </Typography>
                        </TableCell>
                        <TableCell className="otp-table-cell">
                          <Typography
                            variant="subtitle2"
                            className="otp-table-cell-text"
                          >
                            {row.financialYearCount}
                          </Typography>
                        </TableCell>
                        <TableCell className="otp-table-cell">
                          <Typography
                            variant="subtitle2"
                            className="otp-table-cell-text"
                          >
                            {formatDate(row.modifiedDate)}
                          </Typography>
                        </TableCell>
                        <TableCell className="otp-table-cell">
                          <Typography
                            variant="subtitle2"
                            className="otp-table-cell-text"
                          >
                            {formatTime(row.modifiedDate)}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
        </Box>
      );
    }
  };

  return (
    <>
      {isLoading ? (
        <Box marginTop={"200px"}>
          <CustomThreeDotsLoader />
        </Box>
      ) : (
        <>
          <Box className="version-history-header">
            {/* <Button
              className="back-button"
              startIcon={<img src={backArrow} alt="Back" />}
              onClick={() => window.history.back()} // Modify as needed
            />
            <CustomBreadcrumbs data={["ABSLI", "Version History"]} /> */}
          </Box>
          <Box className="version-history-page">
            <Box className="version-history-header">
              <Typography variant="h3">Admin Audit History</Typography>
            </Box>
            <Box>
              <Typography marginTop={"1.5rem"} color={Colors.descriptionGray}>
                Please select master
              </Typography>
            </Box>
            <Box marginTop={".9375rem"}>
              <CustomSelect
                value={masterValue}
                onChange={setMasterValue}
                options={MasterOptions}
              />
            </Box>
          </Box>
          <Box>{renderSteps()}</Box>
        </>
      )}
    </>
  );
};

export default AdminAuditHistory;
