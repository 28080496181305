import { FC, useState, useEffect } from "react";
import "./SRRaiseReportDetailsPage.scss";
import ReportDuration from "../ReportDuration";

import CustomEmail from "../../../components/CustomEmail/CustomEmail";
import { downloadExcelFromBase64 } from "../../../utils/helper";
import { setError } from "../../../redux-store/reducers/CsErrorSlice";
import { useAppDispatch } from "../../../redux-store/CustomReduxHooks";
import {
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  useLazyFetchUserRegistrationReportByEmailQuery,
  useLazySrDetailsDownloadReportApiQuery,
  useLazySrDetailsReportApiQuery,
} from "./SRRaiseReports_Api";

interface OnboardReport {
  abcApp: number;
  abcWeb: number;
  abcdApp: number;
}

interface T_FetchSrDetailsReport_Response {
  lob: string;
  feature: string;
  total: number;
  open: number;
  close: number;
}

const UserLoginReport: FC = () => {
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [reportType, setReportType] = useState("");
  const [showReport, setShowReport] = useState(false);
  const [fetchUserRegistrationReportByEmail] =
    useLazyFetchUserRegistrationReportByEmailQuery();
  const [fetchSrDetailsReportDownload] =
    useLazySrDetailsDownloadReportApiQuery();
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [emailToSend, setEmailToSend] = useState("");
  const [srDetailsReport, setSrDetailsReport] = useState<
    T_FetchSrDetailsReport_Response[]
  >([]);
  const dispatch = useAppDispatch();
  const [reportDumpFlag, setReportDumpFlag] = useState(false);
  const [fetchSrDetailsReport] = useLazySrDetailsReportApiQuery();
  const fetchReport = async () => {
    try {
      console.log(
        "first column",
        fromDate,
        toDate,
        typeof fromDate,
        typeof toDate
      );
      const res = await fetchSrDetailsReport({
        hiLobFlag: true,
        hlLobFlag: true,
        liLobFlag: true,
        fromDate: fromDate,
        toDate: toDate,
      });
      if (res && res?.data?.payload) {
        setSrDetailsReport(res && res?.data?.payload);
        // setOnboardReport(res.data.payload);
        console.log("Response Payload:", res.data.payload);
      } else {
        console.error("Unexpected response structure:", res);
      }
    } catch (error) {
      console.error("Error fetching report:", error);
    }
  };

  const fetchReportEmail = async (email: string) => {
    try {
      const res = await fetchUserRegistrationReportByEmail({
        emailId: email,
        fromDate: fromDate,
        toDate: toDate,
        reportDumpFlag: reportDumpFlag,
      });

      if (res && res?.data?.payload) {
        console.log("Email sent to:", email);
        console.log("Response Payload:", res.data.payload);
      } else {
        console.error("Unexpected response structure:", res);
      }
    } catch (error) {
      console.error("Error sending report via email:", error);
    }
  };

  const fetchReportDownload = async () => {
    try {
      const res = await fetchSrDetailsReportDownload({
        hiLobFlag: true,
        hlLobFlag: true,
        liLobFlag: true,
        fromDate: fromDate,
        toDate: toDate,
        reportDumpFlag: reportDumpFlag,
      });

      if (res && res?.data) {
        console.log("res?.data", res?.data?.payload?.fileDetails);
        downloadExcelFromBase64(
          res?.data?.payload?.fileDetails?.attachment,
          res?.data?.payload?.fileDetails?.filename
        );
      } else {
        return dispatch(
          setError({
            error: new Error("Error"),
            message: "XLS Download failed",
          })
        );
      }
    } catch (error) {
      return dispatch(
        setError({
          error: new Error("Error"),
          message: "XLS Download failed",
        })
      );
    }
  };

  useEffect(() => {
    if (showReport) {
      fetchReport();
    }
  }, [showReport, fromDate, toDate]); // Fetch report when showReport, fromDate, or toDate changes

  const handleGenerateReport = (
    selectedFromDate: string,
    selectedToDate: string
  ) => {
    setFromDate(selectedFromDate);
    setToDate(selectedToDate);
    setShowReport(true);
    console.log("From Date:", selectedFromDate, "To Date:", selectedToDate);
  };

  const handleDownload = () => {
    fetchReportDownload();
  };
  return (
    <Box className="report-details-page">
      <Typography variant="h3">SR Raise Report</Typography>
      <ReportDuration onGenerate={handleGenerateReport} />

      {showReport && (
        <Box className="report-table">
          <TableContainer className="table-container" component={Box}>
            <Table>
              <TableHead className="table-header">
                <TableRow>
                  <TableCell className="table-header-cell">LOB </TableCell>
                  <TableCell className="table-header-cell">Feature</TableCell>
                  <TableCell colSpan={3} className="table-header-cell">
                    SR Count
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell className="otp-table-cell">Total</TableCell>
                  <TableCell className="otp-table-cell">Open</TableCell>
                  <TableCell className="otp-table-cell">Closed</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {srDetailsReport?.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className="otp-table-cell">
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          className="otp-table-cell-text"
                          sx={{ marginLeft: "8px" }}
                          variant="subtitle2"
                        >
                          {row.lob}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell className="otp-table-cell">
                      <Typography
                        className="otp-table-cell-text"
                        variant="subtitle2"
                      >
                        {row.feature}
                      </Typography>
                    </TableCell>
                    <TableCell className="otp-table-cell">
                      <Typography
                        variant="subtitle2"
                        className="otp-table-cell-text"
                      >
                        {row.total}
                      </Typography>
                    </TableCell>
                    <TableCell className="otp-table-cell">
                      <Typography
                        variant="subtitle2"
                        className="otp-table-cell-text"
                      >
                        {row.open}
                      </Typography>
                    </TableCell>
                    <TableCell className="otp-table-cell">
                      <Typography
                        variant="subtitle2"
                        className="otp-table-cell-text"
                      >
                        {row.close}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Box className="email-download-buttons">
            <Button
              onClick={handleDownload}
              className="button"
              variant="outlined"
              fullWidth
            >
              Download
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default UserLoginReport;
