import { FC, ReactElement, useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  makeStyles,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import CustomBreadcrumbs from "../../../components/CustomBreadcrumbs/CustomBreadcrumbs";
import CustomHelpComponent from "../../../components/CustomHelpComponent/CustomHelpComponent";
import "./AddUser.scss";
import { CustomRadioOption } from "../ExistingUser/ExistingUser";
import CommonSuccessPopupBody from "../../../components/CustomSuccessPopupBody/CustomSuccessPopupBody";
import CustomPopup from "../../../components/CustomPopup/CustomPopup";
import useWindowSize from "../../../hooks/useWindowSize";
import { AddUserLobList, tabViewPoint } from "../../../utils/constants";
import { Colors } from "../../../styles/constant";
import CloseIcon from "@mui/icons-material/Close";
import CustomSwitch from "../../../components/CustomSwitch/CustomSwitch";
import { useNavigate } from "react-router-dom";
import CustomAccordion from "../../../components/CustomAccordion/CustomAccordion";
import CustomCheckbox from "../../../components/CustomCheckbox/CustomCheckbox";
import UserAccordion from "../../../components/UserAccordion/UserAccordion";

const AddUser: FC = () => {
  const { width } = useWindowSize();
  const [lobList, setLobList] = useState<any>([]);
  const navigate = useNavigate();
  const [enable, setEnable] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [showAccess, setShowAccess] = useState<boolean>(false);

  useEffect(() => {
    if (AddUserLobList.length > 0) {
      setLobList(AddUserLobList);
      // setLobList(() =>
      // AddUserLobList.map((data: any) => {
      //   data = { ...data };
      //   return data;
      // })

      // );
    }
  }, [AddUserLobList]);

  useEffect(() => {
    let enableNext = [...lobList].filter((item) => item.isChecked);
    if (enableNext.length > 0 && name !== "" && email !== "") {
      setEnable(true);
    } else setEnable(false);
  }, [lobList, name, email]);

  const handleToggleButtonClick = useCallback(
    (value: boolean, index: number) => {
      setLobList((prevState: any) => {
        let copyArr = [...prevState];
        let copyObj = { ...copyArr[index] };
        let updateObj = { ...copyObj, isChecked: value };
        copyObj = updateObj;
        copyArr[index] = copyObj;
        return copyArr;
      });
    },
    []
  );

  const handleNext = useCallback(() => {
    setShowAccess((prevState) => !prevState);
  }, []);
  const StyledTableContainer = styled(TableContainer)(({ theme: any }) => ({
    borderRadius: "8px",
    overflow: "hidden",
  }));

  const handleSelect = useCallback(
    (val: string, type: string) => {
      console.log(val, type, "val");
      switch (type) {
        case "View":
          let index = val.split(",");
          let cloneArr = [...lobList];
          let cloneObj = { ...cloneArr[parseInt(index[0])] };
          let cloneFea = { ...cloneObj };
          let cloneFeature = [...cloneFea.features];
          let cloneFeatureObj = { ...cloneFeature[parseInt(index[1])] };
          let updateObj;
          updateObj = {
            ...cloneFeatureObj,
            isView: !cloneFeatureObj.isModify
              ? !cloneFeatureObj.isView
              : cloneFeatureObj.isView,
          };
          cloneFeatureObj = updateObj;
          cloneFeature[parseInt(index[1])] = cloneFeatureObj;
          cloneObj.features = cloneFeature;
          cloneArr[parseInt(index[0])] = cloneObj;
          console.log(cloneArr, "clonearr");
          setLobList(cloneArr);
          break;
        case "Modify":
          console.log(type);
          let index1 = val.split(",");
          let cloneArr1 = [...lobList];
          let cloneObj1 = { ...cloneArr1[parseInt(index1[0])] };
          let cloneFea1 = { ...cloneObj1 };
          let cloneFeature1 = [...cloneFea1.features];
          let cloneFeatureObj1 = { ...cloneFeature1[parseInt(index1[1])] };
          let updateObj1;
          updateObj1 = {
            ...cloneFeatureObj1,
            isModify: !cloneFeatureObj1.isModify,
            isView: !cloneFeatureObj1.isModify ? true : false,
          };
          cloneFeatureObj1 = updateObj1;
          cloneFeature1[parseInt(index1[1])] = cloneFeatureObj1;
          cloneObj1.features = cloneFeature1;
          cloneArr1[parseInt(index1[0])] = cloneObj1;
          console.log(cloneArr1, "clonearr");
          setLobList(cloneArr1);
          break;
        case "View All" || "Modify All":
          let cloneArrAll = JSON.parse(JSON.stringify(lobList));
          let updatedVal: any = [];
          let cloneFeatureAll = [...cloneArrAll[parseInt(val)]];
          [...cloneFeatureAll].map((data: any) => {
            let clonedVal = [...data.features].map((feature: any) => {
              if (type === "View All") {
                feature.isView = !feature.isView;
                return feature;
              } else if (type === "Modify All") {
                feature.isView = !feature.isModify ? true : false;
                feature.isModify = !feature.isModify;
                return feature;
              }
            });
            data.features = clonedVal;
            updatedVal.push(data);
            return data;
          });
          setLobList(updatedVal);
          break;
      }
    },
    [lobList]
  );

  const St = styled(TableContainer)({
    borderRadius: "8px",
    overflow: "hidden",
  });
  const Stc = styled(TableCell)({
    border: "1px solid red",
  });

  return (
    <Box>
      <Container>
        <Box
          display="flex"
          alignItems="center"
          gap={{
            sm: 10,
          }}
          sx={{
            justifyContent: {
              xs: "space-between",
              sm: "center",
            },
          }}
        >
          <CustomBreadcrumbs data={["User Management", "Add New User"]} />
          <CustomHelpComponent />
        </Box>
        <Box className="um-au-header-title">
          <Typography variant="h3">
            General App Elements- LOB Visibility
          </Typography>
        </Box>
        <Box className="um-au-add-new">
          <Box className="um-au-add-new-header">
            <Typography variant="subtitle2">Please enter name</Typography>
            <TextField
              label="Name"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              fullWidth
            />
          </Box>
          <Box className="um-au-add-new-header">
            <Typography variant="subtitle2">
              Please enter email address
            </Typography>
            <TextField
              label="Email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              fullWidth
            />
          </Box>
          <Box>
            <Typography variant="subtitle2">
              Please select LOB to give access
            </Typography>
            {showAccess ? (
              <Box>
                <Box width={"100%"} className="lob-items">
                  {lobList.map((item: any, index: number) => {
                    return (
                      <Box width={"50%"} className="lob-item">
                        <Box className="select-toggle">
                          <CustomSwitch
                            checked={item.isChecked}
                            onChange={() =>
                              handleToggleButtonClick(!item.isChecked, index)
                            }
                          />
                          <Box sx={{ width: "100%" }}>
                            <Typography variant="h6" className="lob-item-title">
                              {item.title} {item.description}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
                <Button
                  className={`${enable ? "enable-button" : ""}`}
                  disabled={!enable}
                  onClick={handleNext}
                  sx={{ width: "100%" }}
                >
                  Next
                </Button>
              </Box>
            ) : (
              <Box className="lob-items-view">
                {lobList.map((data: any, index: number) => {
                  if (!data.isChecked) {
                    return (
                      <UserAccordion
                        title={`${data.title} ${data.description}`}
                        subTitle={
                          <Box
                            sx={{
                              marginTop: "8px",
                              display: "flex",
                              alignItems: "center",
                              gap: "40px",
                            }}
                          >
                            <Typography variant="subtitle2">
                              Please choose to select all option
                            </Typography>
                            <CustomCheckbox
                              checked={true}
                              onChange={handleSelect}
                              name="View Only"
                              label="View Only"
                              value={index.toString()}
                              type="View All"
                            />
                            <CustomCheckbox
                              checked={true}
                              onChange={handleSelect}
                              name="Modify Only"
                              label="Modify Only"
                              value={index.toString()}
                              type="Modify All"
                            />
                          </Box>
                        }
                      >
                        <TableContainer className="lob-table" component={Paper}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>
                                  <Typography variant="h5">Features</Typography>
                                </TableCell>
                                <TableCell align="center">
                                  <Typography variant="h5">
                                    View Only
                                  </Typography>
                                </TableCell>
                                <TableCell align="center">
                                  <Typography variant="h5">
                                    Modify Only
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {data.features.map(
                                (data: any, dIndex: number) => {
                                  return (
                                    <TableRow key={dIndex}>
                                      <TableCell>
                                        <Typography
                                          variant="h6"
                                          sx={{ fontWeight: "500 !important" }}
                                        >
                                          {data.featureName}
                                        </Typography>
                                      </TableCell>
                                      <TableCell align="center">
                                        <CustomCheckbox
                                          checked={data.isView}
                                          onChange={handleSelect}
                                          name=""
                                          label=""
                                          value={index + "," + dIndex}
                                          type="View"
                                          isModifyChecked={data.isModify}
                                        />
                                      </TableCell>
                                      <TableCell align="center">
                                        <CustomCheckbox
                                          checked={data.isModify}
                                          onChange={handleSelect}
                                          name=""
                                          label=""
                                          value={index + "," + dIndex}
                                          type="Modify"
                                        />
                                      </TableCell>
                                    </TableRow>
                                  );
                                }
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </UserAccordion>
                    );
                  }
                })}
              </Box>
            )}
          </Box>
          {/* <CustomRadioOption /> */}
        </Box>
        <CustomPopup
          className={`admin-common-popup ${
            width > tabViewPoint ? "centered" : ""
          }`}
          open={false}
          handleOpen={() => {}}
          btnLabel="Close"
          btnStartIcon={<CloseIcon sx={{ color: Colors.red }} />}
        >
          <CommonSuccessPopupBody
            title={"Successful"}
            des={"Lorem ipsum"}
            btnLabel={"Close"}
          />
        </CustomPopup>
      </Container>
    </Box>
  );
};

export default AddUser;
