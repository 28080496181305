import { Box, Button, Dialog, Typography } from "@mui/material";
import { FC, useCallback } from "react";
import "./CustomPopup.scss";
import { Colors } from "../../styles/constant";

interface Props {
  open: boolean;
  handleOpen: Function;
  children?: React.ReactNode | null;
  btnStartIcon?: any;
  btnEndIcon?: any;
  btnLabel?: string;
  className?: string;
  anchor?: string;
  isDisabled?: boolean;
  flowType?: string;
}

const CustomPopup: FC<Props> = ({
  open,
  handleOpen,
  children,
  btnLabel = "",
  btnStartIcon = null,
  btnEndIcon = null,
  className = "",
  anchor = "",
  isDisabled = false,
  flowType = "LI",
}) => {
  const handleOpenWrapper = useCallback(() => {
    handleOpen();
  }, [handleOpen]);

  const renderButton = (type: string) => {
    switch (type) {
      case "LI":
        return (
          <Button className="custom-drawer-btn" onClick={handleOpenWrapper}>
            <Box display="flex" alignItems="center">
              {btnStartIcon ? btnStartIcon : null}
              <Typography
                marginLeft=".3125rem"
                marginRight=".3125rem"
                marginBottom={flowType === "MF" ? ".2rem" : ""}
                variant="h5"
                color={Colors.red}
              >
                {btnLabel}
              </Typography>
              {btnEndIcon ? btnEndIcon : null}
            </Box>
          </Button>
        );
      case "HI":
        return (
          <Button
            className="custom-drawer-btn custom-drawer-btn-red"
            onClick={handleOpenWrapper}
          >
            <Box display="flex" alignItems="center">
              {btnStartIcon ? btnStartIcon : null}
              <Typography
                marginLeft=".3125rem"
                marginRight=".3125rem"
                variant="h5"
                color={Colors.white}
              >
                {btnLabel}
              </Typography>
              {btnEndIcon ? btnEndIcon : null}
            </Box>
          </Button>
        );
    }
  };
  const buttonComponent = (
    <Box
      display="flex"
      justifyContent="center"
      marginTop={anchor === "bottom" ? "1rem" : ""}
    >
      <Button
        className={`custom-drawer-btn ${
          flowType === "HI" ? "custom-drawer-btn-red" : ""
        }`}
        onClick={handleOpenWrapper}
        disabled={isDisabled}
      >
        <Box display="flex" alignItems="center">
          {btnStartIcon ? btnStartIcon : null}
          <Typography
            marginLeft=".3125rem"
            marginRight=".3125rem"
            variant="h5"
            color={flowType === "HI" ? Colors.white : Colors.red}
          >
            {btnLabel}
          </Typography>
          {btnEndIcon ? btnEndIcon : null}
        </Box>
      </Button>
    </Box>
  );

  return (
    <Dialog fullWidth className={`custom-popup  ${className}`} open={open}>
      {!anchor && btnLabel && buttonComponent}
      <Box className="popup-content-wrapper">{children}</Box>
      {anchor === "bottom" && buttonComponent}
    </Dialog>
  );
};

export default CustomPopup;
