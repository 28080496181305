import { FC } from "react";
import { defaultEmptyFun } from "../../utils/helper";
import { Box, Button, Typography } from "@mui/material";
import CommonGifAnimation from "../CustomGifAnimation/CustomGifAnimation";
import HealthSuccessGif from "../../assets/gif/Success.json";
import { T_AnimationData } from "../../globalTypes/GlobalTypes";

interface Props {
  title: string;
  des: string;
  btnLabel: string;
  btnOnClick?: any;
  stepValue?: number;
  btnDisabled?: any;
}

const CommonSuccessPopupBody: FC<Props> = ({
  title,
  des,
  btnLabel,
  btnOnClick = defaultEmptyFun,
  stepValue = null,
  btnDisabled = false,
}) => {
  const handleClick = () => {
    if (stepValue === null) btnOnClick();
    else btnOnClick(stepValue);
  };
  return (
    <Box
      className="common-success-popup-body"
      sx={{
        marginBottom: "1.5rem",
        marginLeft: "1.3438rem",
        marginRight: "1.3438rem",
      }}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height={"13.5625rem"}
      >
        <CommonGifAnimation
          width="16.875rem"
          animationData={HealthSuccessGif as T_AnimationData}
        />
      </Box>
      <Typography marginTop="2rem" textAlign="center" variant="h3">
        {title}
      </Typography>
      <Typography
        marginTop=".625rem"
        textAlign="center"
        variant="subtitle2"
        marginBottom={"1rem"}
      >
        {des}
      </Typography>
      <Button
        fullWidth
        variant="contained"
        className="popup-btn"
        onClick={handleClick}
        disabled={btnDisabled}
      >
        {btnLabel}
      </Button>
    </Box>
  );
};

export default CommonSuccessPopupBody;
