import { useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useLazyGenerateInternalTokenQuery } from "../../pages/Login/Login_Api";
import { useAppDispatch } from "../../redux-store/CustomReduxHooks";
import { nanoid } from "@reduxjs/toolkit";
import {
  setDeviceType,
  setLongSessionAuthToken,
  updateTraceId,
} from "../../redux-store/reducers/CsAppSlice";
import useDeviceDetection from "../../hooks/useDeviceDetection";
import { AdminProfileDetails } from "../../globalTypes/GlobalTypes";
import LoadingInstant from "../../pages/Utilities/LoadingInstant";

const ValidateUser = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const appDispatch = useAppDispatch();
  const [genrateInternalToken_Api] = useLazyGenerateInternalTokenQuery();

  const isPwaMobileOrWeb = useDeviceDetection();
  useEffect(() => {
    if (isPwaMobileOrWeb && isPwaMobileOrWeb.length > 0) {
      appDispatch(setDeviceType(isPwaMobileOrWeb));
    }
  }, [isPwaMobileOrWeb]);

  useEffect(() => {
    let randomTraceId = "CS" + nanoid(14);
    appDispatch(updateTraceId(randomTraceId));
    const params = new URLSearchParams(location.search);
    getToken(params.get("code"));
  }, [location]);

  const getToken = useCallback((codeVal: any) => {
    if (codeVal && codeVal !== "") {
      genrateInternalToken_Api(codeVal).then((res) => {
        if (res.data?.code === 1) {
          const data: AdminProfileDetails & { authToken: string } = {
            ...res.data.payload.adminDetailsResponse,
            authToken: res.data.payload.authToken,
          };
          appDispatch(setLongSessionAuthToken(data));
          navigate("/home", { replace: true });
          return;
        }
      });
    } else {
      navigate("/login", { replace: true });
    }
  }, []);

  return <LoadingInstant />;
};
export default ValidateUser;
