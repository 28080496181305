import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import ABCDLogo from "../../assets/images/abcd-insurance-logo.svg";
import BusinessProfitImg from "../../assets/images/business-growth 1.svg";
import { useState } from "react";

import CustomThreeDotsLoader from "../../components/CustomThreeDotsLoader/CustomThreeDotsLoader";
import "./Feature_Login.scss";

const FeatureLogin: React.FC = () => {
  const [isCyberArkRunning, setisCyberArkRunning] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const validateEmail = (): boolean => {
    // Basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError("Please enter a valid email address.");
      return false;
    }
    setEmailError("");
    return true;
  };
  const hitApiAndHandleResponse = async () => {
    if (!validateEmail()) return;
    setisCyberArkRunning(true);
    let url = `${process.env.REACT_APP_METADATA_URL}/${process.env.REACT_APP_APPLICATION_ID}?scope=OpenID&response_type=code&redirect_uri=${process.env.REACT_APP_ADMIN_URL}&client_id=${process.env.REACT_APP_CLIENT_ID}&client_secret=${process.env.REACT_APP_CLIENT_SECRET}&state=abc&nonce=`;
    window.location.href = url;
  };

  return (
    <Grid
      container
      sx={{ justifyContent: "center" }}
      className="container-login"
    >
      <Grid item md={8} sm={12} xs={12}>
        <Box className="login-page">
          <Box className="header-section">
            <img src={ABCDLogo} alt="" />
          </Box>
          <Box>
            <Box className="cardlayout">
              <Box className="cardlayout-image">
                <img src={BusinessProfitImg} alt="" />
              </Box>
              <Typography className="cardlayout-text">
                Welcome to Admin Portal
              </Typography>
              <TextField
                label="Email"
                type="email"
                fullWidth
                value={email}
                onChange={handleEmailChange}
                error={!!emailError}
                helperText={emailError}
              />
              <Box display="flex" justifyContent="center">
                <Box
                  minWidth={"12.5rem"}
                  width={"100%"}
                  maxWidth={"23.9375rem"}
                >
                  <Box
                    display="flex"
                    justifyContent="center"
                    marginTop="1.5rem"
                    marginBottom="1.5rem"
                  >
                    {isCyberArkRunning ? (
                      <CustomThreeDotsLoader />
                    ) : (
                      <Button
                        fullWidth
                        variant="contained"
                        sx={{
                          borderRadius: "1.25rem",
                          padding: ".875rem 0",
                        }}
                        onClick={hitApiAndHandleResponse}
                      >
                        Login
                      </Button>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
export default FeatureLogin;
enum LoginScreenValues {
  enterMobileScreen = "enterMobileScreen",
  LoginWithMPIN = "LoginWithMPIN",
  enterOtpScreen = "enterOtpScreen",
  enterMobileScreenForgotPin = "enterMobileScreenForgotPin",
  enterOtpScreenForgotmPIN = "enterOtpScreenForgotmPIN",
  enterUserDetailScreen = "enterUserDetailScreen",
  setupMPN = "setupMPN",
  fetchCustomerLobAndDo2FAAuth = "fetchCustomerLobAndDo2FAAuth",
  loading = "loading",
}
export { LoginScreenValues };
