import { FC, useState } from "react";
import AddNewCustomer from "../AddNewCustomer/AddNewCustomer";
import AddUserMobile from "../AddUserMobile/AddUserMobile";
import CustomerProfileList from "../CustomerProfileList/CustomerProfileList";
 
const CustomerUserManagement: FC = () => {
  const [screen, setScreen] = useState<string>("customerList");
  const [customerData, setCustomerData] = useState(null);
  const [customerNewData, setCustomerNewData] = useState(null);

 
  const handleAddMobileUser = () => {
    setScreen("AddMobileUser");
  };
 
  const handleAddNewCustomer = () => {
    setScreen("AddNewCustomer");
  };
 
  const handleBackButtonClick = () => {
    setScreen("AddNewCustomer");
  };
 
  const handleAddNewCustomerBackButton = () => {
    setScreen("customerList");
  };
 
  return (
    <div>
      {screen === "customerList" ? (
        <CustomerProfileList
          onAddNewCustomerClick={(list) => {
            console.log(list);
            setCustomerData(list);
            handleAddNewCustomer();
          }}
        />
      ) : screen === "AddNewCustomer" ? (
        <AddNewCustomer
          onAddMobileUserClick={(list)=>{setCustomerNewData(list);handleAddMobileUser()}}
          customerData={customerData}
          handleAddNewCustomerBackBtn={handleAddNewCustomerBackButton}
        />
      ) : (
        <AddUserMobile
          onBackButtonClick={handleBackButtonClick}
          customerData={customerNewData?customerNewData:customerData}
        />
      )}
    </div>
  );
};
export default CustomerUserManagement;
 
 