import { FC, ReactElement, useCallback, useState } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import CustomBreadcrumbs from "../../../components/CustomBreadcrumbs/CustomBreadcrumbs";
import CustomHelpComponent from "../../../components/CustomHelpComponent/CustomHelpComponent";
import PlusIcon from "../../../assets/icons/plus.svg";
import PlanDetailsIcon from "../../../assets/icons/Plandetails.svg";
import DeleteIcon from "../../../assets/icons/trash-can.svg";
import EditIcon from "../../../assets/icons/edit.svg";
import UserIcon from "../../../assets/icons/user.svg";
import "./ExistingUser.scss";
import CustomButton from "../../../components/CustomAdminButton/CustomAdminButton";
import { Colors } from "../../../styles/constant";
import { useNavigate } from "react-router-dom";

const ExistingUser: FC = () => {
  const navigate = useNavigate();
  const addUser = useCallback(() => {
    navigate("/addUser");
  }, []);

  return (
    <Box>
      <Container>
        <Box
          display="flex"
          alignItems="center"
          gap={{
            sm: 10,
          }}
          sx={{
            justifyContent: {
              xs: "space-between",
              sm: "center",
            },
          }}
        >
          <CustomBreadcrumbs data={["User Management", "LAN"]} />
          <CustomHelpComponent />
        </Box>
        <Box className="um-eu-header-title">
          <Typography variant="h3">List of Existing Users</Typography>
          <CustomButton
            btnValue={"Add New User"}
            icon={PlusIcon}
            handleClick={addUser}
          />
        </Box>
        <Box className="um-eu-list-box">
          <Box className="um-eu-list-box-header">
            <img src={PlanDetailsIcon} alt="icon" />
            <Typography variant="h5">Total users 30</Typography>
          </Box>
          <Grid container columns={{ md: 12, sm: 12, xs: 12 }} spacing={"1rem"}>
            {[1, 2, 3, 4].map((data) => {
              return (
                <Grid item md={6} sm={6} xs={12}>
                  <Box key={data} className="um-eu-list-box-items">
                    <Box className="um-eu-list-box-items-1">
                      <img
                        src={PlanDetailsIcon}
                        alt="user"
                        className="user-icon"
                      />
                    </Box>
                    <Box className="um-eu-list-box-items-2">
                      <Box className="um-eu-list-box-items-2-name">
                        <Typography variant="h5">Name</Typography>
                        <Typography variant="subtitle2">Karthik</Typography>
                      </Box>
                      <Box className="um-eu-list-box-items-2-email">
                        <Typography variant="h5">Email</Typography>
                        <Typography variant="subtitle2">
                          Karthik@pwc.com
                        </Typography>
                      </Box>
                      <Box className="um-eu-list-box-items-2-buttons">
                        <CustomButton
                          btnValue={"Modify"}
                          icon={EditIcon}
                          handleClick={() => {}}
                        />
                        <CustomButton
                          btnValue={"Delete"}
                          className="um-delete-icon"
                          icon={DeleteIcon}
                          handleClick={() => {}}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Box>
        {/* <CustomRadioOption /> */}
      </Container>
    </Box>
  );
};

export default ExistingUser;

export const CustomRadioOption = () => {
  const [selectedRadioValue, setSelectedRadioValue] = useState<string>("View");
  const handleRadioBtnClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedRadioValue(e.target.value);
  };

  return (
    <Box>
      <Typography variant="subtitle2" sx={{ color: Colors.descriptionGray }}>
        Please give edit/ view access
      </Typography>
      <RadioGroup
        className="radio-group"
        onChange={handleRadioBtnClick}
        value={selectedRadioValue}
      >
        <Box className="radio-group-items">
          <Radio
            sx={{
              padding: 0,
              "&.Mui-checked": {},
              "& .MuiSvgIcon-root": {
                fontSize: 20,
              },
            }}
            value={"Edit"}
          />
          <Typography variant="h6">Edit</Typography>
        </Box>
        <Box className="radio-group-items">
          <Radio
            sx={{
              padding: 0,
              "&.Mui-checked": {},
              "& .MuiSvgIcon-root": {
                fontSize: 20,
              },
            }}
            value={"View"}
          />
          <Typography variant="h6">View</Typography>
        </Box>
      </RadioGroup>
    </Box>
  );
};
