import {
  combineReducers,
  configureStore,
  PayloadAction,
} from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import {
  CSApiService,
  CSApiServiceReducer,
  CSApiServiceStoreKey,
} from "./CSApiService";
import { CsAppSliceKey, CsAppSliceReducer } from "./reducers/CsAppSlice";
import { persistReducer } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";
import persistStore from "redux-persist/es/persistStore";
import { T_LogoutInfoToBePassed } from "../globalTypes/GlobalTypes";
import {
  MasterManagementReducer,
  MasterManagementSliceKey,
} from "../features/MasterManagement/MasterManagement_Slice";
import { CsErrorSliceKey, CsErrorSliceReducer } from "./reducers/CsErrorSlice";
import {
  ChannelManagementReducer,
  ChannelManagementSliceKey,
} from "../features/UserManagement/ChannelManagement/ChannelManagement_Slice";

// Create the root reducer separately so we can extract the RootState type
const rootReducer = combineReducers({
  [CsAppSliceKey]: CsAppSliceReducer,
  [CsErrorSliceKey]: CsErrorSliceReducer,
  [CSApiServiceStoreKey]: CSApiServiceReducer,
  [MasterManagementSliceKey]: MasterManagementReducer,
  [ChannelManagementSliceKey]: ChannelManagementReducer,
});

const appReducer = (
  state: any,
  action: PayloadAction<T_LogoutInfoToBePassed>
) => {
  return rootReducer(state, action);
};

const rootPersistConfig = {
  key: "root",
  storage: storageSession,
};
const persistedReducer = persistReducer(rootPersistConfig, appReducer);

export const setupStore = (preloadedState?: RootState) => {
  const storeValue = configureStore({
    reducer: persistedReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat(CSApiService.middleware),
  });
  return storeValue;
};

export const store = setupStore();

export const persistor = persistStore(store);

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof persistedReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];
