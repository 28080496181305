import { UpdateFeature, GetFeaturesByLob, FetchFeatureHistoryDetails } from "../../utils/ApiEndpoints";
import { T_ApiResponse } from "../../globalTypes/GlobalTypes";
import { CSApiService } from "../../redux-store/CSApiService";
import { T_FetchFeatureLobDetails_Request, T_FetchFeatureLobDetails_Response, T_UpdateFeatureLobDetails_Request, T_UpdateFeatureLobDetails_Response, Feature, T_FetchFeatureHistoryDetails_Response, T_FetchFeatureHistoryDetails_Request } from "../Features/T_FeaturesDetailsTypes";

export const FeatureManagementPageAPI = CSApiService.injectEndpoints({
  endpoints: (builder) => ({
    fetchFeatureLobDetails: builder.query<
      T_ApiResponse<T_FetchFeatureLobDetails_Response>,
      T_FetchFeatureLobDetails_Request
    >({
      query: ({ lobMasterCode, pageNumber, pageSize }) => ({
        url: GetFeaturesByLob,
        method: "GET",
        params: {
          lobMasterCode,  
          pageNumber,      
          pageSize,        
        },
        headers: {  
          pageName:"/admin"
        },
      }),
    }),
    updateFeatureLobDetails: builder.query<
      T_ApiResponse<T_UpdateFeatureLobDetails_Response>,
      T_UpdateFeatureLobDetails_Request
    >({
      query: (payload) => ({
        url: UpdateFeature,
        method: "POST",
        body: JSON.stringify(payload),
        headers: {  
          pageName:"/admin"
        },
      }),
    }),
    fetchFeatureHistoryDetails: builder.query<
    T_ApiResponse<T_FetchFeatureHistoryDetails_Response>,
    T_FetchFeatureHistoryDetails_Request
  >({
    query: (payload) => ({
      url: FetchFeatureHistoryDetails,
      method: "POST",
      body: JSON.stringify({featureId:payload.featureId,masterLobCode:payload.masterLobCode}),
      params: { 
        pageNumber:payload.pageNumber,      
        pageSize:payload.pageSize,        
      },
      headers: {  
        pageName:"/admin"
      },
    }),
  })
  })
})

export const {
  useLazyFetchFeatureLobDetailsQuery,
  useLazyUpdateFeatureLobDetailsQuery,
  useLazyFetchFeatureHistoryDetailsQuery
} = FeatureManagementPageAPI;