import "./App.scss";
import { FC, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "./components/CustomNavbar/CustomNavbar";
import AppRoutes from "./routes/AppRoutes";
import { Grid } from "@mui/material";
import ErrorBoundary from "./ErrorBoundary";
import NavbarHome from "./components/CustomNavbar/CustomNavbarHome";
import { nanoid } from "@reduxjs/toolkit";
import {
  setDeviceType,
  updateTraceId,
} from "./redux-store/reducers/CsAppSlice";
import { useAppDispatch } from "./redux-store/CustomReduxHooks";
import useDeviceDetection from "./hooks/useDeviceDetection";
import ErrorComp from "./components/CustomError/ErrorComp";

const CsApp: FC = () => {
  const { pathname } = useLocation();
  const appdispatch = useAppDispatch();
  const isPwaMobileOrWeb = useDeviceDetection();
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [pathname]);

  useEffect(() => {
    let randomTraceId = "CS" + nanoid(14);
    appdispatch(updateTraceId(randomTraceId));
  }, []);
  useEffect(() => {
    if (isPwaMobileOrWeb && isPwaMobileOrWeb.length > 0) {
      appdispatch(setDeviceType(isPwaMobileOrWeb));
    }
  }, [isPwaMobileOrWeb]);

  let isHomeRoute = ["/admin/home"].includes(pathname);
  let isLoginRoute = ["/admin/login", "/admin"].includes(pathname);

  const getNavbar = () => {
    if (pathname) {
      if (isLoginRoute) {
        return null;
      } else {
        return <NavbarHome />;
      }
    } else {
      return null;
    }
  };
  return (
    <>
      <ErrorBoundary>
        {/* {getNavbar()} */}
        <AppRoutes />
        <ErrorComp />
      </ErrorBoundary>
    </>
  );
};

export default CsApp;
