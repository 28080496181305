import {
  UserLoginReport,
  API_TOKEN,
  Access_Token,
  UserLoginReportByEmail,
  UserLoginnReportByDownload,
  redirectionDetails,
  redirectionDetailsDownload,
} from "../../../utils/ApiEndpoints";
import { T_ApiResponse } from "../../../globalTypes/GlobalTypes";
import { CSApiService } from "../../../redux-store/CSApiService";
import {
  T_RedirectionCountReport_Request,
  T_RedirectionCountReport_Response,
  T_RedirectionDetailsDownload_Request,
  T_RedirectionDetailsDownload_Response,
} from "./T_RedirectionCountReportTypes";

export const RedirectionReportApi = CSApiService.injectEndpoints({
  endpoints: (builder) => ({
    redirectionDetails: builder.query<
      T_ApiResponse<T_RedirectionCountReport_Response>,
      T_RedirectionCountReport_Request
    >({
      query: (payload) => ({
        url: redirectionDetails,
        method: "POST",
        body: payload,
        headers: {
          Authorization: `Bearer ${API_TOKEN}`,
          authToken: `${Access_Token}`,
          traceId: "abc", // Add traceId to headers
          csUserId: "abc",
          source: "abc",
        },
      }),
    }),

    redirectionDetailsDownload: builder.query<
      T_ApiResponse<T_RedirectionDetailsDownload_Response>,
      T_RedirectionDetailsDownload_Request
    >({
      query: (payload) => ({
        url: redirectionDetailsDownload,
        method: "POST",
        body: payload,
        headers: {
          Authorization: `Bearer ${API_TOKEN}`,
          authToken: `${Access_Token}`,
          traceId: "abc", // Add traceId to headers
          csUserId: "abc",
          source: "abc",
        },
      }),
    }),
  }),
});

export const {
  useLazyRedirectionDetailsQuery,
  useLazyRedirectionDetailsDownloadQuery,
} = RedirectionReportApi;
