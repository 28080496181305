import { FC, useState } from "react";
import AdminUserDetailList from "./AdminUserDetailList/AdminUserDetailList";
import AdminAddNewUser from "./AdminAddNewUser/AdminAddNewUser";

const AdminUserList: FC = () => {
  const [screen, setScreen] = useState<string>("AdminUserDetailList");
  const [userData, setUserData] = useState(null);

  const handleAddNewUser = () => {
    setScreen("AddNewUser");
  };

  const handleBackButton = () => {
    setScreen("AdminUserDetailList");
  };

  return (
    <div>
      {screen === "AdminUserDetailList" ? (
        <AdminUserDetailList
          onAddNewUserClick={(list) => {
            setUserData(list);
            handleAddNewUser();
          }}
        />
      ) : (
        <AdminAddNewUser userData={userData} handleBackBtn={handleBackButton} />
      )}
    </div>
  );
};

export default AdminUserList;
