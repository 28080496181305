import { Box, Divider, Typography } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./CustomSelect.scss";
import CustomPopup from "../CustomPopup/CustomPopup";
import CloseIcon from "@mui/icons-material/Close";
import { Colors } from "../../styles/constant";
import { defaultEmptyFun } from "../../utils/helper";
import LockIcon from "@mui/icons-material/Lock";

interface Props {
  label?: string;
  value: string;
  options?: option[];
  placeholder?: string;
  onChange?: any;
  customPopupClass?: string;
  isDisabled?: boolean;
  isDisableIconVisible?: boolean;
  onScroll?: (event: React.UIEvent<HTMLDivElement>) => void; // Add onScroll prop
}

type option = { label: string; value: string };

const CustomSelect: FC<Props> = ({
  label,
  value,
  options = [],
  placeholder = "",
  onChange = defaultEmptyFun,
  customPopupClass,
  isDisabled = false,
  isDisableIconVisible = false,
  onScroll,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const handleOptionClick = (data: option) => {
    onChange(data.value);
    setOpen(false);
  };
  let displayValue = options.find(
    (item) => item.value?.toLowerCase() === value?.toLowerCase()
  )?.label;

  return (
    <>
      <Box className={`custom-select ${isDisabled ? "disabled" : ""}`}>
        {!!value && <Typography className="shrink-label">{label}</Typography>}
        <Box
          className="select-content-wrapper"
          onClick={() => !isDisabled && setOpen(true)}
          sx={{ color: isDisabled ? "#b0b0b0" : "inherit" }}
        >
          <Typography
            variant="h5"
            color={isDisabled ? "#b0b0b0" : value ? "black" : "#979CAE"}
          >
            {value ? displayValue : placeholder}
          </Typography>
          {isDisabled && isDisableIconVisible ? (
            <LockIcon
              sx={{
                color: "#2E2E2E",
                fontSize: "1.125rem",
              }}
            />
          ) : (
            <ExpandMoreIcon />
          )}
        </Box>
      </Box>
      <CustomPopup
        open={open}
        btnStartIcon={
          <CloseIcon sx={{ color: Colors.red, fontSize: "1.0625rem" }} />
        }
        btnLabel="Close"
        handleOpen={setOpen}
        className={`custom-select-popup ${customPopupClass}`}
      >
        <Typography textAlign="center" textTransform="uppercase" variant="h5">
          {placeholder}
        </Typography>
        <Box
          onScroll={onScroll} // Attach the onScroll event to the Box containing options
          sx={{ maxHeight: "300px", overflowY: "auto" }} // Optional styling for scroll
        >
          {options.map((item: option, index: number) => {
            return (
              <React.Fragment key={item.label}>
                <Typography
                  onClick={() => handleOptionClick(item)}
                  marginY={"1.25rem"}
                  variant="body1"
                  sx={{ cursor: "pointer" }}
                >
                  {item.label}
                </Typography>
                {index < options.length - 1 && <Divider />}
              </React.Fragment>
            );
          })}
        </Box>
      </CustomPopup>
    </>
  );
};

export default CustomSelect;
