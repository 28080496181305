import { FC, useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import CustomBreadcrumbs from "../../../../components/CustomBreadcrumbs/CustomBreadcrumbs";
import "./AddNewCustomer.scss";
import CustomPopup from "../../../../components/CustomPopup/CustomPopup";
import useWindowSize from "../../../../hooks/useWindowSize";
import { Colors } from "../../../../styles/constant";
import { useNavigate } from "react-router-dom";
import UserAccordion from "../../../../components/UserAccordion/UserAccordion";
import CustomCheckbox from "../../../../components/CustomCheckbox/CustomCheckbox";
import {
  useLazyCreateUpdateProfileQuery,
  useLazyFetchAllProfileDetailsQuery,
  useLazyFetchCustomerProfileByIdQuery,
  useLazyCheckExistingProfileQuery
} from "../../UserManagement_Api";
import { API_STATUS_CODE } from "../../../../utils/ApiEndpoints";
import { setError } from "../../../../redux-store/reducers/CsErrorSlice";
import { useDispatch } from "react-redux";
import backArrow from "../../../../assets/icons/back-arrow.svg";
import CustomSuccessPopupBody from "../../../../components/CustomSuccessPopupBody/CustomSuccessPopupBody";
import CustomThreeDotsLoader from "../../../../components/CustomThreeDotsLoader/CustomThreeDotsLoader";
import AlertRhombus from "../../../../assets/icons/alert-rhombus.svg";
import { CustomFailurePopupBody } from "../../../../components/CustomFailurePopupBody/CustomFailurePopupBody";
import plusicon from "../../../../assets/icons/plus.svg";
import { debounce } from 'lodash';
type AddNewCustomerProps = {
  onAddMobileUserClick: (customerNewData:any) => void;
  customerData: any;
  handleAddNewCustomerBackBtn: () => void;
};
interface Feature {
  featureDisplayName: string;
  access: boolean;
}
interface Profile {
  profileName: string;
}
const AddNewCustomer: FC<AddNewCustomerProps> = ({
  onAddMobileUserClick,
  customerData,
  handleAddNewCustomerBackBtn,
}) => {
  const dispatch = useDispatch();
  const [lobList, setLobList] = useState<any>([]);
  const navigate = useNavigate();
  const [enable, setEnable] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [fetchCustomerProfileById] = useLazyFetchCustomerProfileByIdQuery();
  const [loading, setLoading] = useState<boolean>(false); // State for loading
  const [createUpdateProfile] = useLazyCreateUpdateProfileQuery();
  const [successpopupOpen, setsuccessPopupOpen] = useState<boolean>(false); // State to track the popup
  const [failurepopupOpen, setFailurepopupOpen] = useState<boolean>(false);
  console.log("Props passed to AddNewCustomer:", customerData);
  const [nameErrorMessage, setNameErrorMessage] = useState<string>("");
  const [nameAlreadyExistsError, setNameAlreadyExistsError] =
    useState<string>("");
  const [customerList, setCustomerList] = useState<Profile[]>([]);
  const [fetchAllProfileDetails] = useLazyFetchAllProfileDetailsQuery();
  const [checkExistingProfile]= useLazyCheckExistingProfileQuery();
  const [customerNewData, setCustomerNewData] = useState<any>();


 
  const backBtnFunc = () => {
    handleAddNewCustomerBackBtn();
  };



  const debouncedCheckName = debounce(
    async () => {
    // setLoading(true);
      setNameAlreadyExistsError("");

      try {
        const res = await checkExistingProfile({
          profileId: null,
          profileName: name,
        }).unwrap();

        if (res.code === 2) {
          setNameAlreadyExistsError("The name already exists. Please enter a different name.");
        } else {
          setNameAlreadyExistsError(""); // Clear the error if the name doesn't exist
        }
      } catch (error) {
      dispatch(
        setError({
          error: new Error("Error"),
          message: "checkExistingProfile api failed",
        })
      );
        console.error("Error checking profile name:", error);
        setNameAlreadyExistsError("Error checking profile name.");
      } 
      // finally {
      //   setLoading(false);
      // }
  }, 300);

  useEffect(() => {
    if (name) {
      debouncedCheckName();
    } else{
      setNameAlreadyExistsError("");
    }
    return () => debouncedCheckName.cancel(); // Clean up debounce on component unmount
  }, [name]);
 
  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const nameInput = event.target.value;
 
    const regex = /^[A-Za-z0-9\s]*$/; // Only allows letters, no special characters
    if (nameInput.trim() === "" || !regex.test(nameInput)) {
      setNameErrorMessage("Enter a valid name");
    } else {
      // checkNameDuplicate(nameInput);
      setNameErrorMessage("");
    }
 
    setName(nameInput);
  };
 
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Set loading to true before fetching
      try {
          let tempProfileId = customerData === null ? "" : customerData.profileId
        const res = await fetchCustomerProfileById({
          profileId: tempProfileId
          // profileId:12
        }).unwrap(); // Use unwrap to handle errors easily
        if (res.payload) {
          setLoading(false);
          console.log("Response Payload for customer:", res.payload);
 
          setName(res.payload.profileName);
          setLobList(res.payload.details);
        } else {
          setLoading(false);
        dispatch(
          setError({
            error: new Error("Error"),
            message: "fetchCustomerProfileById api failed",
          })
        );
          console.error("Unexpected response structure:", res);
        }
      } catch (error) {
        setLoading(false);
      dispatch(
        setError({
          error: new Error("Error"),
          message: "fetchCustomerProfileById api failed",
        })
      );
        console.error("Error fetching features:", error);
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };
 
    fetchData();
  }, [fetchCustomerProfileById]); // Add lobID to the dependency array



    // const checkNameDuplicate = async () => {
    //   setLoading(true);
    //   setNameAlreadyExistsError("");

    //   try {
    //     const res = await checkExistingProfile({
    //       profileId: null,
    //       profileName: name,
    //     }).unwrap();

    //     if (res.code === 2) {
    //       setNameAlreadyExistsError("The name already exists. Please enter a different name.");
    //     } else {
    //       setNameAlreadyExistsError(""); // Clear the error if the name doesn't exist
    //     }
    //   } catch (error) {
    //     console.error("Error checking profile name:", error);
    //     setNameAlreadyExistsError("Error checking profile name.");
    //   } finally {
    //     setLoading(false);
    //   }
    // };

 
  useEffect(() => {
    if (lobList.length > 0) {
      setLobList(lobList);
    }
  }, [setLobList]);
 
  console.log("customerFeatureList", lobList);
  const isSubmitButtonEnabled = () => {
    if(customerData===null)
    return !name || !!nameErrorMessage || !!nameAlreadyExistsError;
  };
 
  const handleSubmit = async () => {
    setLoading(true);
    try {
      const featureIdsWithAccess = lobList.flatMap((item: any) => {
        // Make sure item.features is an array and not undefined or null
        if (Array.isArray(item.features)) {
          console.log("features:", item.features);
 
          let temp = item.features
          .filter((feature: any) => feature.access === true) // Replace with actual condition
          .map((feature: any) => feature.featureId); // Map to feature ids (adjust as necessary)

      return temp;// Return the mapped array
        } else {
          console.log("No features array found in item.");
          return []; // If no features, return an empty array
        }
      });
 
      console.log("featureIdsWithAccess:", featureIdsWithAccess);
 
      const payload = {
        adminId: "1",
        isAdmin: "false",
        profileId: customerData === null ? null : customerData.profileId,
        profileName: name,
 
        featureId: featureIdsWithAccess,
        menuId: [],
        isActive: true,
      };
 
      const res = await createUpdateProfile(payload); // Call the mutation and unwrap the response
      console.log("response data", res);
      console.log("response Data pre:", res?.data?.payload);
            
      // Update the state with the response data
      const tempResponse = res?.data?.payload;
      setCustomerNewData(tempResponse); // This will trigger a re-render with updated state

      // Optionally log the updated state for debugging (but it won't reflect immediately after this line)
      console.log("Response Data set in state:", tempResponse);

      // if (res.isSuccess === true && res.isError === false) {
      //   setsuccessPopupOpen(true); 
      // }
      if (res.error && res.isError) {
        setLoading(false);
        dispatch(
          setError({
            error: new Error("Error"),
            message: "createUpdateProfile api failed",
          })
        );
      }
      if (res.data?.code===API_STATUS_CODE.SUCCESS) {
        setLoading(false);
        setsuccessPopupOpen(true); 
      }
      if (res.data?.code!==API_STATUS_CODE.SUCCESS){
        setLoading(false);
        setFailurepopupOpen(true);
      }
    } catch (error) {
      setLoading(false);
      dispatch(
        setError({
          error: new Error("Error"),
          message: "createUpdateProfile api failed",
        })
      );
      console.error("Error updating Profile:", error);
    } finally {
      setLoading(false);
      // setsuccessPopupOpen(false);
    }
 
    console.log(name, lobList);
  };
 
  const handleSelect = (value: boolean, identifier: string, type: string) => {
    const [lobIndex, featureIndex] = identifier.split(",").map(Number);
 
    setLobList((prevLobList: any) => {
      // Create a copy of the lobList to ensure we don't mutate the original state
      let updatedLobList = [...prevLobList];
      let updatedLob = { ...updatedLobList[lobIndex] };
      updatedLob.features = [...updatedLobList[lobIndex].features]; // Create a copy of features
 
      if (type === "Access All") {
        const isModifyAllChecked = !updatedLob.features.every(
          (f: Feature) => f.access
        );
        updatedLob.features = updatedLob.features.map((f: Feature) => ({
          ...f, // Create a copy of each feature
          access: isModifyAllChecked, // Modify the access property
        }));
      } else if (type === "Access") {
        updatedLob.features[featureIndex] = {
          ...updatedLob.features[featureIndex], 
          access: !value, 
        };
      } else if (type === "lobcheckbox") {
        const anyChecked = updatedLob.features.some((f: Feature) => f.access);
        updatedLob.isChecked = anyChecked;
      }
 
      // Replace the original lobList entry with the updated one
      updatedLobList[lobIndex] = updatedLob;
 
      return updatedLobList; // Return the updated lobList with new references
    });
  };
      useEffect(() => {
        console.log("Updated customerNewData:", customerNewData);
    }, [customerNewData]); 
    

console.log("customerNewData out side",customerNewData)

 
  const handleUserMobileClick = () => {
    console.log(customerNewData)
    onAddMobileUserClick(customerNewData);
  };
 
  useEffect(() => {
    let enableNext = [...lobList].filter((item) => item.isChecked);
    if (enableNext.length > 0 && name !== "") {
      setEnable(true);
    } else setEnable(false);
  }, [lobList, name]);
 
  return (
    <Box>
      <Container>
        <Box
          display="flex"
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"flex-start"}
          gap={"1.5rem"}
        >
          <Button
            className="back-button-add-customer"
            startIcon={<img src={backArrow} alt="Expand Icon" />}
            onClick={backBtnFunc}
            sx={{height:"24px",width:"24px"}}
          ></Button>
 
          {/* <img src={backArrow} alt="Expand Icon" onClick={backBtnFunc}/> */}
          <CustomBreadcrumbs data={["User Management", "Add New User"]} />
        </Box>
        <Box className="customer-um-au-header-title">
          <Typography variant="h3" color={Colors.darkCharcoal}>
            {customerData === null
              ? "Create new profile"
              : "Modify Existing Profile"}
          </Typography>
           <Button className={"add-new-customer-button"} onClick={handleUserMobileClick}>
            <img src={plusicon} className="admin-custom-button-icon" alt="icon" />
            <Typography variant="h6">Add user (Mobile Nos)</Typography>
           </Button>
             
        </Box>
 
        <Box className="customer-um-au-add-new">
          <Box className="customer-um-au-add-new-header">
            <Typography variant="subtitle2" color={Colors.descriptionGray}>
              Please enter profile name
            </Typography>
            <TextField
              label="Name"
              value={customerData === null ? name : customerData.profileName}
              disabled={customerData === null ? false : true}           
              onChange={handleChangeName}
              // onBlur={checkNameDuplicate}
              fullWidth
            />
            {nameErrorMessage && (
              <InputAdornment sx={{ marginTop: "1.5rem" }} position="start">
                {<img src={AlertRhombus} />}
                <Typography
                  sx={{ marginLeft: ".3125rem" }}
                  color={Colors.red}
                  variant="h6"
                >
                  {" "}
                  {nameErrorMessage}
                </Typography>
              </InputAdornment>
            )}
            {customerData === null&&nameAlreadyExistsError && (
              <InputAdornment sx={{ marginTop: "1.5rem" }} position="start">
                {<img src={AlertRhombus} />}
                <Typography
                  sx={{ marginLeft: ".3125rem" }}
                  color={Colors.red}
                  variant="h6"
                >
                  {" "}
                  {nameAlreadyExistsError}
                </Typography>
              </InputAdornment>
            )}
          </Box>
          <Box>
            <Typography variant="subtitle2" color={Colors.descriptionGray}>
              Please select LOB to give access
            </Typography>
            <Box className="customer-profile-lob-items-view">
              {lobList.map((data: any, index: number) => {
                if (!data.isChecked) {
                  const isAnyFeatureChecked = data.features.some(
                    (f: Feature) => f.access
                  );
                  return (
                    <UserAccordion
                      key={index}
                      title={`${data.downstreamKey} ${data.description}`}
                      preIcon={
                        <CustomCheckbox
                          checked={isAnyFeatureChecked}
                          onChange={() => {}}
                          name=""
                          label=""
                          type="lobcheckbox"
                          value={index.toString()}
                        />
                      }
                    >
                      <Box
                        sx={{
                          marginTop: "-8px",
                          marginBottom: "16px",
                          display: "flex",
                          alignItems: "center",
                          gap: "40px",
                        }}
                      >
                        <Typography variant="subtitle2" color={"#797979"}>
                          Please choose to select all option
                        </Typography>
 
                        <CustomCheckbox
                          checked={data.features.every(
                            (f: Feature) => f.access
                          )} // always true since this checkbox is only for convenience
                          onChange={() =>
                            handleSelect(true, index.toString(), "Access All")
                          }
                          label="Access All"
                          name="Access All"
                          value={index.toString()}
                          type="Access All"
                        />
                      </Box>
                      <TableContainer
                        className="customer-lob-table"
                        component={Paper}
                      >
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                <Typography variant="h5">Features</Typography>
                              </TableCell>
                              <TableCell align="center">
                                <Typography variant="h5">Access</Typography>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {data.features.map((data: any, dIndex: number) => {
                              return (
                                <TableRow key={dIndex}>
                                  <TableCell>
                                    <Typography
                                      fontSize={"12px"}
                                      fontWeight={"500"}
                                    >
                                      {data.featureDisplayName}
                                    </Typography>
                                  </TableCell>
 
                                  <TableCell align="center">
                                    <CustomCheckbox
                                      checked={data.access}
                                      onChange={() =>
                                        handleSelect(
                                          data.access,
                                          `${index},${dIndex}`,
                                          "Access"
                                        )
                                      }
                                      label=""
                                      name=""
                                      value={`${index},${dIndex}`}
                                      type="Access"
                                    />
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </UserAccordion>
                  );
                }
              })}
 
              {loading ? (
                <CustomThreeDotsLoader />
              ) : (
                <Button
                  variant="contained"
                  onClick={handleSubmit}
                  fullWidth
                  disabled={isSubmitButtonEnabled()}
                >
                  Submit
                </Button>
              )}
            </Box>
          </Box>
        </Box>
        {/* <CustomPopup
                    // className={`admin-common-popup ${width > tabViewPoint ? "centered" : ""
                    //     }`}
                    open={successpopupOpen}
                    handleOpen={()=>{}}
                    // btnLabel="Close"
                    btnStartIcon={<CloseIcon sx={{ color: Colors.red }} />}
                >
                    <CommonSuccessPopupBody
                        title={"Successful"}
                        des={"Lorem ipsum"}
                        btnLabel={"Continue"}
                        btnOnClick={handleClosePopup}
                    />
                </CustomPopup> */}
        <CustomPopup open={successpopupOpen} handleOpen={() => {}}>
          <CustomSuccessPopupBody
            title="Success"
            des="your changes have been saved"
            btnLabel="Continue"
            // btnOnClick={handleClosePopup}
            btnOnClick={handleAddNewCustomerBackBtn}
          />
        </CustomPopup>

        {failurepopupOpen && (
          <CustomPopup open={failurepopupOpen} handleOpen={()=>{}}>
            <CustomFailurePopupBody btnClick={() => setFailurepopupOpen(false)} />
          </CustomPopup>
        )}
 
        {/* <CustomAPIFailurePopup
          open={failurepopupOpen}
          handleOpen={() => {
            setFailurepopupOpen(false);
          }}
        ></CustomAPIFailurePopup> */}
      </Container>
    </Box>
  );
};
 
export default AddNewCustomer;
 
 