import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  T_ChannelManagementSliceState,
  T_FetchAllChannels_Response,
  T_FetchAllChannels_ResponseObject,
} from "../T_UserManagementTypes";

const initialState: T_ChannelManagementSliceState = {
  allChannelDetails: [],
};

export const ChannelManagementSlice = createSlice({
  name: "ChannelManagementSlice",
  initialState,
  reducers: {
    setAllChannelDetails: (
      state,
      action: PayloadAction<T_FetchAllChannels_ResponseObject[]>
    ) => {
      return {
        ...state,
        allChannelDetails: action.payload,
      };
    },
  },
});

export const ChannelManagementReducer = ChannelManagementSlice.reducer;
export const ChannelManagementSliceKey = ChannelManagementSlice.reducerPath;
export const { setAllChannelDetails } = ChannelManagementSlice.actions;
