import { FC, ReactElement, useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Container,
  InputAdornment,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import CustomBreadcrumbs from "../../../../components/CustomBreadcrumbs/CustomBreadcrumbs";
import CustomHelpComponent from "../../../../components/CustomHelpComponent/CustomHelpComponent";
import "./AddNewProfile.scss";
import { CustomRadioOption } from "../../ExistingUser/ExistingUser";
import CommonSuccessPopupBody from "../../../../components/CustomSuccessPopupBody/CustomSuccessPopupBody";
import CustomPopup from "../../../../components/CustomPopup/CustomPopup";
import useWindowSize from "../../../../hooks/useWindowSize";
import { AddUserLobList, tabViewPoint } from "../../../../utils/constants";
import { Colors } from "../../../../styles/constant";
import CloseIcon from "@mui/icons-material/Close";
import CustomSwitch from "../../../../components/CustomSwitch/CustomSwitch";
import backArrow from "../../../../assets/icons/back-arrow.svg";
import CustomCheckbox from "../../../../components/CustomCheckbox/CustomCheckbox";
import UserAccordion from "../../../../components/UserAccordion/UserAccordion";
import { useLazyCheckExistingProfileQuery, useLazyGetMenusModulewiseByAdminProfileQuery, useLazySubmitAdminProfileMenuQuery } from "../../UserManagement_Api";
import { API_STATUS_CODE } from "../../../../utils/ApiEndpoints";
import { setError } from "../../../../redux-store/reducers/CsErrorSlice";
import { useDispatch } from "react-redux";
import CustomThreeDotsLoader from "../../../../components/CustomThreeDotsLoader/CustomThreeDotsLoader";
import CustomAPIFailurePopup from "../../../../components/CustomAPIFailurePopup/CustomAPIFailurePopup";
import CustomSuccessPopupBody from "../../../../components/CustomSuccessPopupBody/CustomSuccessPopupBody";
import AlertRhombus from "../../../../assets/icons/alert-rhombus.svg";
import { debounce } from 'lodash';
interface Feature {
  featureName: string;
  isView: boolean;
  isModify: boolean;
}
interface Menu {
  menuName: string;
  access: boolean
}

interface Lob {
  title: string;
  description: string;
  isChecked: boolean;
  features: Feature[];
}
type AppNewProfileProps = {
  onBackButtonClick: () => void;
  profileData: any
};


const AddNewProfile: FC<AppNewProfileProps> = ({ onBackButtonClick, profileData }) => {
  const dispatch = useDispatch();
  const { width } = useWindowSize();
  const [lobList, setLobList] = useState<any>([]);
  const [menuList, setMenuList] = useState<any>([]);
  const [name, setName] = useState<string>("");
  const [submitAdminProfileMenu] = useLazySubmitAdminProfileMenuQuery();
  const [getMenusModulewiseByAdminProfile] = useLazyGetMenusModulewiseByAdminProfileQuery();
  // const [getFeatureLobwiseByAdminProfile] =useLazyGetFeatureLobwiseByAdminProfileQuery()
  const [successpopupOpen, setsuccessPopupOpen] = useState<boolean>(false); // State to track the popup
  const [failurepopupOpen, setFailurepopupOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false); // State for loading
  const [enable, setEnable] = useState<boolean>(false);
  
  const [checkExistingProfile]= useLazyCheckExistingProfileQuery();
  const [nameErrorMessage, setNameErrorMessage] = useState<string>("");
  const [nameAlreadyExistsError, setNameAlreadyExistsError] =
  useState<string>("");

  console.log("profileData comming from list", profileData)


  
  const debouncedCheckName = debounce(
    async () => {
    // setLoading(true);
      setNameAlreadyExistsError("");

      try {
        const res = await checkExistingProfile({
          profileId: null,
          profileName: name,
        }).unwrap();

        if (res.code === 2) {
          setNameAlreadyExistsError("The name already exists. Please enter a different name.");
        } else {
          setNameAlreadyExistsError(""); // Clear the error if the name doesn't exist
        }
      } catch (error) {
        setLoading(false);
      dispatch(
        setError({
          error: new Error("Error"),
          message: "checkExistingProfile api failed",
        })
      );
        console.error("Error checking profile name:", error);
        setNameAlreadyExistsError("Error checking profile name.");
      } 
      // finally {
      //   setLoading(false);
      // }
  }, 300);

  useEffect(() => {
    if (name) {
      debouncedCheckName();
    } else{
      setNameAlreadyExistsError("");
    }
    return () => debouncedCheckName.cancel(); // Clean up debounce on component unmount
  }, [name]);
  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const nameInput = event.target.value;
 
    const regex = /^[A-Za-z0-9\s]*$/; // Only allows letters, no special characters
    if (nameInput.trim() === "" || !regex.test(nameInput)) {
      setNameErrorMessage("Enter a valid name");
    } else {
      // checkNameDuplicate(nameInput);
      setNameErrorMessage("");
    }
 
    setName(nameInput);
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     setLoading(true); // Set loading to true before fetching
  //     try {
  //       let tempProfileId=profileData===null?null:profileData.profileId
  //       const res = await getFeatureLobwiseByAdminProfile({
  //           profileId:tempProfileId
  //           // profileId:profileData===null?profileData
  //       }).unwrap(); // Use unwrap to handle errors easily

  //       if (res.payload) {
  //         console.log("Response Payload for ProfileData:", res.payload);



  //         setName(res.payload.profileName)
  //         setLobList(res.payload.lob);


  //       } else {
  //         console.error("Unexpected response structure:", res);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching features:", error);
  //     } finally {
  //       setLoading(false); // Set loading to false after fetching
  //     }
  //   };

  //   fetchData();

  // }, [getFeatureLobwiseByAdminProfile]); // Add lobID to the dependency array


  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Set loading to true before fetching
      try {
        let tempProfileId = profileData === null ? "" : profileData.profileId
        const res = await getMenusModulewiseByAdminProfile({
          profileId: tempProfileId
        }).unwrap(); // Use unwrap to handle errors easily

        if (res.payload) {
          console.log("Response Payload for ProfileData:", res.payload);



          setName(res.payload.profileName)
          setMenuList(res.payload.menu);
          console.log("menuList", menuList)


        } else {
          setLoading(false);
        dispatch(
          setError({
            error: new Error("Error"),
            message: "getMenusModulewiseByAdminProfile api failed",
          })
        );
          console.error("Unexpected response structure:", res);
        }
      } catch (error) {
        setLoading(false);
      dispatch(
        setError({
          error: new Error("Error"),
          message: "getMenusModulewiseByAdminProfile api failed",
        })
      );
        console.error("Error fetching features:", error);
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    fetchData();

  }, [getMenusModulewiseByAdminProfile]); // Add lobID to the dependency array

  console.log("menuList234", menuList)

  // useEffect(() => {
  //   if (AddUserLobList.length > 0) {
  //     setLobList(AddUserLobList);
  //   }
  // }, [AddUserLobList]);
  // useEffect(() => {
  //   if (lobList.length > 0) {
  //     setLobList(lobList);
  //   }
  // }, [setLobList]);

  useEffect(() => {
    if (menuList.length > 0) {
      setMenuList(menuList);
    }
  }, [setMenuList]);


  // const handleSelect = (value: boolean, identifier: string, type: string) => {
  //   const [lobIndex, featureIndex] = identifier.split(",").map(Number);

  //   setLobList((prevLobList: any) => {
  //     // Create a shallow copy of lobList to prevent mutation
  //     const updatedLobList = [...prevLobList];

  //     // Make a copy of the LOB to avoid mutating the original one
  //     const updatedLob = { ...updatedLobList[lobIndex] };

  //     // Make a copy of the features array to avoid mutating the original one
  //     const updatedFeatures = updatedLob.feature.map((feature: Feature) => ({ ...feature }));

  //     if (type === "View All") {
  //       // Toggle "View All" for all features in this LOB
  //       const isViewAllChecked = !updatedFeatures.every(
  //         (f: Feature) => f.isView
  //       );
  //       updatedFeatures.forEach((f: Feature) => {
  //         f.isView = isViewAllChecked;
  //       });
  //     } else if (type === "View") {
  //       // Toggle a specific feature's "View" setting
  //       updatedFeatures[featureIndex].isView = !value;
  //     } else if (type === "Modify All") {
  //       // Toggle "Modify All" for all features in this LOB
  //       const isModifyAllChecked = !updatedFeatures.every(
  //         (f: Feature) => f.isModify
  //       );
  //       updatedFeatures.forEach((f: Feature) => {
  //         f.isModify = isModifyAllChecked;
  //       });
  //     } else if (type === "Modify") {
  //       // Toggle a specific feature's "Modify" setting
  //       updatedFeatures[featureIndex].isModify = !value;
  //     } else if (type === "lobcheckbox") {
  //       // Handle the LOB checkbox, check if any feature is either View or Modify
  //       const anyChecked = updatedFeatures.some(
  //         (f: Feature) => f.isView || f.isModify
  //       );
  //       updatedLob.isChecked = anyChecked;
  //     }

  //     // Set the updated features back to the LOB object
  //     updatedLob.feature = updatedFeatures;

  //     // Replace the old LOB with the updated one
  //     updatedLobList[lobIndex] = updatedLob;

  //     // Return the updated list
  //     return updatedLobList;
  //   });
  // };


  // const handleSubmit = async () => {
  //   try {
  //     const payload = {
  //       adminId: "1",

  //       profileId: "1",
  //       isAdmin:"true",
  //       profileName: "new profile test",

  //       featureId: [93],
  //       menuId: [1],

  //       isActive: true,
  //     };

  //     const res = await createUpdateProfile(payload); // Call the mutation and unwrap the response
  //     console.log(res);
  //     // Update your state or UI based on the response as necessary
  //   } catch (error) {
  //     console.error("Error updating Profile:", error);
  //   }

  //   console.log(name, lobList);
  // };
  const handleSubmitMenu = async () => {
    try {
      setLoading(true);
      // Extract menuId where access is true
      const menuIdsWithAccessTrue = menuList.filter((menu: any) => menu.access === true)  // Filter menus with access true
        .map((menu: any) => menu.menuId);  // Map to only extract the menuId

      // If you also want to get nested menuIds with access true
      menuList.forEach((menu: any) => {
        if (menu.menu && menu.menu.length > 0) {
          menu.menu
            .filter((subMenu: any) => subMenu.access === true)
            .forEach((subMenu: any) => menuIdsWithAccessTrue.push(subMenu.menuId));
        }
      });
    

      const payload = {

        profileId: profileData === null ? null : profileData.profileId,
        profileName: profileData === null ? name : profileData.profileName,
        menus: menuIdsWithAccessTrue,
        isActive: true

      };

      const res = await submitAdminProfileMenu(payload); // Call the mutation and unwrap the response
      console.log(res);
      if (res.error && res.isError) {
        setLoading(false);
        dispatch(
          setError({
            error: new Error("Error"),
            message: "submitAdminProfileMenu api failed",
          })
        );
      }
      if (res.isSuccess === true && res.isError === false) {
    
        setsuccessPopupOpen(true); // Show success popup only when API is successful
      
      }
      else {
       
        setFailurepopupOpen(true);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      dispatch(
        setError({
          error: new Error("Error"),
          message: "submitAdminProfileMenu api failed",
        })
      );
      console.error("Error updating Profile:", error);
    }finally{
      setLoading(false);
    }

    console.log(name, menuList);
  };

  const handleBackButtonClick = () => {
    onBackButtonClick();
  };



  // const handleSelectMenu = (value: boolean, identifier: string, type: string) => {
  //   const [lobIndex, menuIndex] = identifier.split(",").map(Number);
  //   console.log(lobIndex, menuIndex);

  //   setMenuList((prevLobList: any) => {
  //     // Create a copy of the lobList to ensure we don't mutate the original state
  //     let updatedLobList = [...prevLobList];

  //     // Deep copy of the lob object and its features to avoid mutating directly
  //     let updatedLob = { ...updatedLobList[lobIndex] };
  //     updatedLob.menu = [...updatedLobList[lobIndex]?.menu]; // Create a copy of menu items

  //     if (type === "Access All") {
  //       // If toggling 'Access All', update all child menus
  //       const isModifyAllChecked = !updatedLob?.menu.every((f: Menu) => f.access);
  //       updatedLob.menu = updatedLob.menu.map((f: Menu) => ({
  //         ...f, 
  //         access: isModifyAllChecked,
  //       }));
  //     } else if (type === "Access") {
  //       // Toggling individual menu access
  //       updatedLob.menu[menuIndex] = {
  //         ...updatedLob.menu[menuIndex], 
  //         access: !value, // Toggle the access value of the individual menu
  //       };

  //       // Update the parent access (LOB level)
  //       // const anyChecked = updatedLob.menu.some("(f: Menu) => f.access);
  //       // updatedLob.isChecked = anyChecked;
  //     } else if (type === "lobcheckbox") {

  //         console.log("value",value,updatedLob?.menu.access)
  //         // const anyChecked = updatedLob?.menu.some((f: Menu) => f.access);
  //         // updatedLob.isChecked = anyChecked;

  //       // If toggling the LOB checkbox, adjust all child menu access accordingly
  //       updatedLob.menu = updatedLob.menu.map((f: Menu) => ({
  //         ...f,
  //         access: value, // Set all child menus' access to match the LOB checkbox
  //       }));

  //       // Update the 'isChecked' state of the LOB based on the child menus' access states
  //       updatedLob.isChecked = updatedLob.menu.some((f: Menu) => f.access);


  //     }

  //     // Replace the original lobList entry with the updated one
  //     updatedLobList[lobIndex] = updatedLob;

  //     return updatedLobList; // Return the updated lobList with new references
  //   });
  // };

  const handleSelectMenu = (value: boolean, identifier: string, type: string) => {
    const [lobIndex, menuIndex] = identifier.split(",").map(Number);
    console.log(lobIndex, menuIndex);

    setMenuList((prevLobList: any) => {
      // Create a copy of the lobList to avoid mutating original state
      let updatedLobList = [...prevLobList];

      // Deep copy of the lob object to ensure we don't mutate directly
      let updatedLob = { ...updatedLobList[lobIndex] };
      updatedLob.menu = [...updatedLobList[lobIndex]?.menu]; // Copy menu items

      if (type === "Access All") {
        // If toggling 'Access All', update all child menus
        const isModifyAllChecked = !updatedLob?.menu.every((f: Menu) => f.access);
        updatedLob.menu = updatedLob.menu.map((f: Menu) => ({
          ...f,
          access: isModifyAllChecked,
        }));
      } else if (type === "Access") {
        // Toggling individual menu access
        updatedLob.menu[menuIndex] = {
          ...updatedLob.menu[menuIndex],
          access: !value, // Toggle the access value of the individual menu
        };
      } else if (type === "lobcheckbox") {
        if (updatedLob.menu.length > 0) {
          updatedLob.menu = updatedLob.menu.map((f: Menu) => ({
            ...f,
            access: value, // Set all child menus' access to match the LOB checkbox
          }));
        } else {
          // debugger
          updatedLob.access = !updatedLob.access;
          // alert(updatedLob.access)
        }
        // If toggling the LOB checkbox, update all child menus

      }
      if (updatedLob.menu.length > 0) {
        // After modifying the child menus, we need to update the parent's 'access' state.
        updatedLob.access = updatedLob.menu.some((f: Menu) => f.access);

        // Update the original lobList entry with the updated object
        updatedLobList[lobIndex] = updatedLob;
      } else {
        updatedLobList[lobIndex] = updatedLob
      }

      return updatedLobList; // Return the updated lobList with new references
    });
  };



  console.log("updatedLobList", menuList)
  // console.log("updatedLobList",lobList)

  //   useEffect(() => {
  //     let enableNext = [...lobList].filter((item) => item.isChecked);
  //     if (enableNext.length > 0 && name !== "") {
  //         setEnable(true);
  //     } else setEnable(false);
  // }, [lobList, name]);

  useEffect(() => {
    let enableNext = [...menuList].filter((item) => item.isChecked);
    if (enableNext.length > 0 && name !== "") {
      setEnable(true);
    } else setEnable(false);
  }, [menuList, name]);
  if (loading) {
    return (
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{ height: "100vh" }}
      >
        <CustomThreeDotsLoader />
      </Box>
    );
  }else{
  return (
    <Box>
      <Container>
        <Box
           display="flex"
           flexDirection={"row"}
           alignItems={"center"}
           justifyContent={"flex-start"}
           gap={"1.5rem"}
        >
          <Button
            className="back-button"
            startIcon={<img src={backArrow} alt="Expand Icon" />}
            onClick={handleBackButtonClick}
          ></Button>
          <CustomBreadcrumbs data={["User Management", "Add New User"]} />
          {/* <CustomHelpComponent /> */}
        </Box>
        <Box className="um-au-header-title">
          <Typography variant="h3">{profileData === null ? 'Add New Profile' : 'Edit Existing Profile'}</Typography>
        </Box>
        <Box className="um-au-add-new">
          <Box className="um-au-add-new-header">
            <Typography variant="subtitle2">
              Please enter profile name
            </Typography>
            <TextField
              label="Name"
              value={profileData === null ? name : profileData.profileName}
              disabled={profileData === null ? false : true}
              // onChange={(e) => {
              //   setName(e.target.value);
              // }}
              onChange={handleChangeName}
              fullWidth
            />
                {nameErrorMessage && (
              <InputAdornment sx={{ marginTop: "1.5rem" }} position="start">
                {<img src={AlertRhombus} />}
                <Typography
                  sx={{ marginLeft: ".3125rem" }}
                  color={Colors.red}
                  variant="h6"
                >
                  {" "}
                  {nameErrorMessage}
                </Typography>
              </InputAdornment>
            )}
            {profileData === null&&nameAlreadyExistsError && (
              <InputAdornment sx={{ marginTop: "1.5rem" }} position="start">
                {<img src={AlertRhombus} />}
                <Typography
                  sx={{ marginLeft: ".3125rem" }}
                  color={Colors.red}
                  variant="h6"
                >
                  {" "}
                  {nameAlreadyExistsError}
                </Typography>
              </InputAdornment>
            )}
          </Box>
          {/* Feature Code */}
          {/* <Box>
            <Typography variant="subtitle2">
              Please select LOB/Features to give access
            </Typography>
            <Box className="profile-lob-items-view">
              {lobList.map((data: any, index: number) => {
                if (!data.isChecked) {
                  const isAnyFeatureChecked =data.feature!==null?  data.feature.some(
                    (f: Feature) => f.isView || f.isModify
                  ):null;
                  return (
                    <UserAccordion
                      key={index}
                      title={`${data.downstreamKey} ${data.description}`}
                      preIcon={
                        <CustomCheckbox
                          checked={isAnyFeatureChecked}
                          onChange={() => {}}
                          name=""
                          label=""
                          type="lobcheckbox"
                          value={index.toString()}
                        />
                      }
                    >
                      <Box
                        sx={{
                          marginTop: "-8px",
                          marginBottom: "16px",
                          display: "flex",
                          alignItems: "center",
                          gap: "40px",
                        }}
                      >
                        <Typography variant="subtitle2" color={"#797979"}>
                          Please choose to select all option
                        </Typography>
                        <CustomCheckbox
                          checked={data.feature!==null? data.feature.every(
                            (f: Feature) => f.isView
                          ):null} // always true since this checkbox is only for convenience
                          onChange={() =>
                            handleSelect(true,index.toString(), "View All")
                          }
                          label="View All"
                          name="View All"
                          value={index.toString()}
                          type="View All"
                        />
                        <CustomCheckbox
                          checked={data.feature!==null? data.feature.every(
                            (f: Feature) => f.isModify
                          ):null} // always true since this checkbox is only for convenience
                          onChange={() =>
                            handleSelect(true,index.toString(), "Modify All")
                          }
                          label="Modify All"
                          name="View All"
                          value={index.toString()}
                          type="Modify All"
                        />
                      </Box>
                      <TableContainer
                        className="profile-lob-table"
                        component={Paper}
                      >
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                <Typography variant="h5">Features</Typography>
                              </TableCell>
                              <TableCell align="center">
                                <Typography variant="h5">View Only</Typography>
                              </TableCell>
                              <TableCell align="center">
                                <Typography variant="h5">
                                  Modify Only
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {data.feature!==null? data.feature.map((data: any, dIndex: number) => {
                              return (
                                <TableRow key={dIndex}>
                                  <TableCell>
                                    <Typography
                                      variant="h6"
                                      sx={{ fontWeight: "500 !important" }}
                                    >
                                      {data.featureName}
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="center">
                                    <CustomCheckbox
                                      checked={data.isView}
                                      onChange={() =>
                                        handleSelect(
                                          data.isView,
                                          `${index},${dIndex}`,
                                          "View"
                                        )
                                      }
                                      label=""
                                      name=""
                                      value={`${index},${dIndex}`}
                                      type="View"
                                      // isModifyChecked={data.isModify}
                                    />
                                  </TableCell>
                                  <TableCell align="center">
                                    <CustomCheckbox
                                      checked={data.isModify}
                                      onChange={() =>
                                        handleSelect(
                                          data.isModify,
                                          `${index},${dIndex}`,
                                          "Modify"
                                        )
                                      }
                                      label=""
                                      name=""
                                      value={`${index},${dIndex}`}
                                      type="Modify"
                                    />
                                  </TableCell>
                                </TableRow>
                              );
                            }):null}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </UserAccordion>
                  );
                }
              })}
              <Button variant="contained" onClick={handleSubmit} fullWidth>
                Submit
              </Button>
            </Box>
          </Box> */}

          {/* Menu Code */}

          <Box>
            <Typography variant="subtitle2" color={Colors.descriptionGray}>
              Please select Menu to give access
            </Typography>
            <Box className="customer-profile-lob-items-view">
              {menuList.map((data: any, index: number) => {
                if (!data.isChecked) {
                  const isAnyFeatureChecked = data?.menu ? data.menu.some(
                    (f: Menu) => f.access
                  ) : data?.access;
                  return (
             

                
                      <UserAccordion
                      isVisible={data?.menu && data.menu.length > 0 ?true:false}
                      key={index}
                      title={`${data?.menuName} ${""}`}
                      preIcon={



                        <CustomCheckbox
                          checked={data?.menu?.length > 0 ? data.menu.some((f: Menu) => f.access) : data?.access}
                          onChange={(value: any) =>
                            handleSelectMenu(
                              // value.target.checked,
                              data?.menu?.length > 0 ? !data?.menu.some((f: Menu) => f.access) : value == "0" ? value = false : value = true, // Toggle based on current state
                              index.toString(),
                              "lobcheckbox"
                            )
                          }
                          name=""
                          label=""
                          type="lobcheckbox"
                          value={index.toString()}
                        />
                      }
                    >
                      {data?.menu && data.menu.length > 0 && (
                        <>
                          <Box
                            sx={{
                              marginTop: "-8px",
                              marginBottom: "16px",
                              display: "flex",
                              alignItems: "center",
                              gap: "40px",
                            }}
                          >
                            <Typography variant="subtitle2" color={"#797979"}>
                              Please choose to select all option
                            </Typography>

                            <CustomCheckbox
                              checked={data?.menu ? data.menu.every(
                                (f: Menu) => f.access
                              ) : null} // always true since this checkbox is only for convenience
                              onChange={() =>
                                handleSelectMenu(true, index.toString(), "Access All")
                              }
                              label="Access All"
                              name="Access All"
                              value={index.toString()}
                              type="Access All"
                            />
                          </Box>
                          <TableContainer
                            className="customer-lob-table"
                            component={Paper}
                          >
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell>
                                    <Typography variant="h5">Features</Typography>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Typography variant="h5">
                                      Access
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {data?.menu ? data.menu.map((data: any, dIndex: number) => {
                                  return (
                                    <TableRow key={dIndex}>
                                      <TableCell>
                                        <Typography fontSize={"12px"} fontWeight={"500"}>
                                          {data?.menuName}
                                        </Typography>
                                      </TableCell>

                                      <TableCell align="center">
                                        <CustomCheckbox
                                          checked={data?.access}
                                          onChange={() =>
                                            handleSelectMenu(
                                              data?.access,
                                              `${index},${dIndex}`,
                                              "Access"
                                            )
                                          }
                                          label=""
                                          name=""
                                          value={`${index},${dIndex}`}
                                          type="Access"
                                        />
                                      </TableCell>
                                    </TableRow>
                                  );
                                }) : null}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </>)}
                    </UserAccordion>
                  
             
             );
                }
              })}
              <Button variant="contained" onClick={handleSubmitMenu} fullWidth>
                Submit
              </Button>
            </Box>
          </Box>
        </Box>
        <CustomPopup
          className={`admin-common-popup ${width > tabViewPoint ? "centered" : ""
            }`}
          open={false}
          handleOpen={() => { }}
          btnLabel="Close"
          btnStartIcon={<CloseIcon sx={{ color: Colors.red }} />}
        >
          <CommonSuccessPopupBody
            title={"Successful"}
            des={"Lorem ipsum"}
            btnLabel={"Close"}
          />
        </CustomPopup>
        <CustomPopup open={successpopupOpen} handleOpen={() => {}}>
          <CustomSuccessPopupBody
            title="Success"
            des="your changes have been saved"
            btnLabel="Continue"
            // btnOnClick={handleClosePopup}
            btnOnClick={onBackButtonClick}
          />
        </CustomPopup>
 
        <CustomAPIFailurePopup
          open={failurepopupOpen}
          handleOpen={() => {
            setFailurepopupOpen(false);
          }}
        ></CustomAPIFailurePopup>
      </Container>
    </Box>
  );}
};

export default AddNewProfile;
