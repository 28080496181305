import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AdminProfileDetails,
  T_CsAppSliceState,
} from "../../globalTypes/GlobalTypes";

const initialState: T_CsAppSliceState = {
  traceId: undefined,
  deviceType: undefined,
  tokenDetails: undefined,
};

export const CsAppSlice = createSlice({
  name: "CsAppSlice",
  initialState: initialState,
  reducers: {
    updateTraceId: (state, action) => {
      return {
        ...state,
        traceId: action.payload,
      };
    },
    setDeviceType: (state, action) => {
      return {
        ...state,
        deviceType: action.payload,
      };
    },
    setTokenDetails: (state, action) => {
      return {
        ...state,
        tokenDetails: action.payload,
      };
    },
    setLongSessionAuthToken: (
      state: any,
      action: PayloadAction<AdminProfileDetails & { authToken: string }>
    ) => {
      return {
        ...state,
        tokenDetails: {
          ...state.tokenDetails,
          internal: {
            ...state.tokenDetails?.internal,
            authToken: action.payload.authToken,
            longSessionAuthToken: action.payload.authToken,
            adminDetails: action.payload,
          },
        },
      };
    },
    resetCsAppSliceToInitialState: () => {
      return initialState;
    },
  },
  extraReducers() {},
});

export const CsAppSliceReducer = CsAppSlice.reducer;
export const CsAppSliceKey = CsAppSlice.reducerPath;

export const {
  updateTraceId,
  setDeviceType,
  setTokenDetails,
  setLongSessionAuthToken,
  resetCsAppSliceToInitialState,
} = CsAppSlice.actions;
