import { Outlet, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../redux-store/CustomReduxHooks";
import { isEmpty } from "lodash";
import { useEffect } from "react";

const AuthGuard = () => {
  const csAppSlice = useAppSelector(
    (state) => state.CsAppSlice.tokenDetails?.internal
  );
  const navigate = useNavigate();
  useEffect(() => {
    if (isEmpty(csAppSlice?.longSessionAuthToken)) {
      navigate("/login", { replace: true });
    }
  });
  return isEmpty(csAppSlice?.longSessionAuthToken) ? <></> : <Outlet />;
};
export default AuthGuard;
