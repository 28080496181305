import {
  UserLoginReport,
  API_TOKEN,
  Access_Token,
  ManualHoldingReport,
  ManualholdingReportDownload,
} from "../../../utils/ApiEndpoints";
import { T_ApiResponse } from "../../../globalTypes/GlobalTypes";
import { CSApiService } from "../../../redux-store/CSApiService";
import {
  T_ManualHolding_Request,
  T_ManualHolding_Response,
  T_ManualHoldingDownlaod_Request,
  T_ManualHoldingDownload_Response,
} from "./T_ManualAdditionReport";

export const ManualAdditionAPI = CSApiService.injectEndpoints({
  endpoints: (builder) => ({
    ManualHolding: builder.query<
      T_ApiResponse<T_ManualHolding_Response>,
      T_ManualHolding_Request
    >({
      query: (payload) => ({
        url: ManualHoldingReport,
        method: "POST",
        body: payload,
        headers: {
          Authorization: `Bearer ${API_TOKEN}`,
          authToken: `${Access_Token}`,
          traceId: "abc", // Add traceId to headers
          csUserId: "abc",
          source: "abc",
          pageName: "/admin",
        },
      }),
    }),

    manualHoldingDownload: builder.query<
      T_ApiResponse<T_ManualHoldingDownload_Response>,
      T_ManualHoldingDownlaod_Request
    >({
      query: (payload) => ({
        url: ManualholdingReportDownload,
        method: "POST",
        body: payload,
        headers: {
          Authorization: `Bearer ${API_TOKEN}`,
          authToken: `${Access_Token}`,
          traceId: "abc", // Add traceId to headers
          csUserId: "abc",
          source: "abc",
          pageName: "/admin",
        },
      }),
    }),
  }),
});

export const { useLazyManualHoldingQuery, useLazyManualHoldingDownloadQuery } =
  ManualAdditionAPI;
