import { FC, useCallback } from "react";
import { Button, Typography } from "@mui/material";
import "./CustomAdminButton.scss";

interface Props {
  icon?: any;
  className?: string;
  btnValue: string;
  handleClick: Function;
}

const CustomButton: FC<Props> = ({
  icon,
  className = "",
  btnValue,
  handleClick,
}) => {
  const handleBtnClick = useCallback(() => {
    handleClick();
  }, []);
  return (
    <Button
      className={`admin-custom-button ${className !== "" ? className : ""}`}
      onClick={handleBtnClick}
    >
      {icon && (
        <img src={icon} className="admin-custom-button-icon" alt="icon" />
      )}
      <Typography variant="h6">{btnValue}</Typography>
    </Button>
  );
};

export default CustomButton;
