import { FC, ReactElement, useCallback, useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  Pagination,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import PlusIcon from "../../../../assets/icons/plus.svg";
import DeleteIcon from "../../../../assets/icons/trash-can.svg";
import EditIcon from "../../../../assets/icons/edit.svg";
import PlanDetails from "../../../../assets/icons/Plan-details.svg";
import "./AdminUserDetailList.scss";
import CustomButton from "../../../../components/CustomAdminButton/CustomAdminButton";
import { Colors } from "../../../../styles/constant";
import { useNavigate } from "react-router-dom";
import {
  useLazyCreateUpdateAdminDetailsQuery,
  useLazyFetchAllAdminDetailsQuery,
} from "../../UserManagement_Api";
import { setError } from "../../../../redux-store/reducers/CsErrorSlice";
import { useDispatch } from "react-redux";
import CustomLoader from "../../../../components/CustomLoader";
import CustomPopup from "../../../../components/CustomPopup/CustomPopup";
import ErrorImg from "../../../../assets/images/Error.png";
import CustomThreeDotsLoader from "../../../../components/CustomThreeDotsLoader/CustomThreeDotsLoader";
import CustomSuccessPopupBody from "../../../../components/CustomSuccessPopupBody/CustomSuccessPopupBody";
import { CustomFailurePopupBody } from "../../../../components/CustomFailurePopupBody/CustomFailurePopupBody";

interface Profile {
  // id: string;
  name: string;
  email: string;
  profileName: string;
  profileId: number;
  adminId: number;
}

type AdminUserDetailListProps = {
  onAddNewUserClick: (userData: any) => void;
};

const AdminUserDetailList: FC<AdminUserDetailListProps> = ({
  onAddNewUserClick,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [profiles, setProfiles] = useState<Profile[]>([]);
  const [fetchAllAdminDetails] = useLazyFetchAllAdminDetailsQuery();
  const [loading, setLoading] = useState<boolean>(false); // State for loading
  const [deleteConfirmationPopup, setDeleteConfirmationPopup] =
    useState<boolean>(false);
  const [deletionSuccess, setDeletionSuccess] = useState<boolean>(false);
  const [deletionFailure, setDeletionFailure] = useState<boolean>(false);
  const [selectedProfile, setSelectedProfile] = useState(null); // Track the selected profile for deletion

  const [createUpdateAdminDetails] = useLazyCreateUpdateAdminDetailsQuery();

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageSize] = useState<number>(10);
  const [totalElements, setTotalElements]=useState<number>(0);

  const [selectedUserName, setSelectedUserName] = useState<string>("");
  const [selectedUserEmail, setSelectedUserEmail] = useState<string>("");
  const [selectedUserProfileId, setSelectedUserProfileId] = useState<number>(0);
  const [selectedUserAdminId, setSelectedUserAdminId] = useState<number>(0);

  const fetchData = async () => {
    setLoading(true); // Set loading to true before fetching
    try {
      const res = await fetchAllAdminDetails({
        isActive: true,
        pageNumber: currentPage,
        pageSize: pageSize,
      }).unwrap(); // Use unwrap to handle errors easily

      if (res.payload.data) {
        setLoading(false);
        console.log("Response Payload:", res.payload);
        // setFeatures(res.payload);
        setProfiles(res.payload.data);
        setTotalElements(res.payload.totalElements);
      } else {
        setLoading(false);
        dispatch(
          setError({
            error: new Error("Error"),
            message: "fetchAllAdminDetails api failed",
          })
        );
        console.error("Unexpected response structure:", res);
      }
    } catch (error) {
      setLoading(false);
      dispatch(
        setError({
          error: new Error("Error"),
          message: "fetchAllAdminDetails api failed",
        })
      );
      console.error("Error fetching features:", error);
    } finally {
      setLoading(false); // Set loading to false after fetching
    }
  };

  useEffect(() => {
    fetchData();
  }, [fetchAllAdminDetails, pageSize, currentPage]); // Add lobID to the dependency array

  const handleAddNewUserClick = (userData: any) => {
    onAddNewUserClick(userData);
  };

  const closeDeletePopup = () => {
    setDeleteConfirmationPopup(false);
    setSelectedProfile(null); // Reset selected profile when popup closes
  };

  const handleDelete = (
    name: string,
    email: string,
    profileIdVal: number,
    adminId: number
  ) => {
    setSelectedUserName(name);
    setSelectedUserEmail(email);
    setSelectedUserProfileId(profileIdVal);

    setSelectedUserAdminId(adminId);
    setDeleteConfirmationPopup(true);
  };

  const onDeleteClick = async (
    name: string,
    email: string,
    profileId: number,
    adminId: number
  ) => {
    try {
      const payload = {
        profileId,
        email,
        phoneNumber: "",
        name,
        isUpdate: true,
        isDeleted: true,
        createOrUpdatedBy: 1,
        adminId: adminId,
      };
      const res = await createUpdateAdminDetails(payload); // Call the mutation and unwrap the response
      if (res.error && res.isError) {
        setLoading(false);
        dispatch(
          setError({
            error: new Error("Error"),
            message: "createupdateadmindetails api failed",
          })
        );
      }
      if (res.data?.code === 2) {
        setDeletionSuccess(true);

        fetchData();
      } else {
        setDeletionFailure(true);
      }
      // if (res.data?.code !== 1) {
      //   setDeleteFailurePopupOpen(true);
      // }
      console.log(res);
      closeDeletePopup(); // Close the popup after deletion is successful

      // Update your state or UI based on the response as necessary
    } catch (error) {
      setLoading(false);
      dispatch(
        setError({
          error: new Error("Error"),
          message: "createupdateadmindetails api failed",
        })
      );
      console.error("Error creating user:", error);
    }
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };

  if (loading) {
    // return <CustomLoader />;
    return <CustomThreeDotsLoader />;
  } else {
    return (
      <Box className="admin-user-details-list">
        <Container>
          <Box className="admin-user-title">
            <Typography variant="h3">Admin User List</Typography>
            <CustomButton
              btnValue={"Add New User"}
              icon={PlusIcon}
              handleClick={() => {
                handleAddNewUserClick(null);
              }}
            />
          </Box>
          <Box className="add-user-list-box">
            <Box className="admin-user-list-box">
              <Box className="admin-user-list-box-header">
                <img src={PlanDetails} />
                <Typography variant="h5">
                  Total users ({profiles.length})
                </Typography>
              </Box>
              <Box>
                <Box className="admin-user-list-container">
                  {profiles.map((profile, index) => (
                    <Box key={profile.adminId} sx={{ mb: 2 }}>
                      <Box className="admin-user-list-container-1">
                        {/* <Box className="admin-user-profile"> */}
                        {/* <Avatar className="admin-user-otp-avatar">OP</Avatar> */}
                        <Box className="admin-user-profile-details">
                          <Box className="admin-user-name">
                            <Typography variant="h5">Name</Typography>
                            <Typography
                              variant="subtitle2"
                              sx={{
                                wordWrap: "break-word",
                                whiteSpace: "normal",
                              }}
                            >
                              {profile.name}
                            </Typography>
                          </Box>
                          <Box className="admin-user-email">
                            <Typography variant="h5">Email</Typography>
                            <Typography
                              variant="subtitle2"
                              sx={{
                                wordWrap: "break-word",
                                whiteSpace: "normal",
                              }}
                            >
                              {profile.email}
                            </Typography>
                          </Box>
                          <Box className="admin-user-profile-name">
                            <Typography variant="h5">Profile Name</Typography>
                            <Typography
                              variant="subtitle2"
                              sx={{
                                wordWrap: "break-word",
                                whiteSpace: "normal",
                              }}
                            >
                              {profile.profileName}
                            </Typography>
                          </Box>
                        </Box>

                        <Box className="admin-user-list-box-items-2-buttons">
                          <CustomButton
                            btnValue={"Modify"}
                            icon={EditIcon}
                            handleClick={() => {
                              handleAddNewUserClick(profile);
                            }}
                          />
                          <CustomButton
                            btnValue={"Delete"}
                            className="admin-user-delete-icon"
                            icon={DeleteIcon}
                            // handleClick={() =>
                            //   onDeleteClick(profile.name, profile.email)
                            // }
                            handleClick={() => {
                              handleDelete(
                                profile.name,
                                profile.email,
                                profile.profileId,
                                profile.adminId
                              );
                            }}
                          />
                        </Box>
                      </Box>
                      {/* {profile.profileId % 2 === 0 && <Divider sx={{ width: "100%", color:"blueviolet" }} />} */}
                      <Divider
                        orientation="horizontal"
                        sx={{ color: "#F1F3F6" }}
                      />
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="admin-footer">
            <Pagination
              className="custom-pagination"
              // count={Math.ceil(profiles.length / 10)} 
              count={Math.ceil(totalElements/10)}
              page={currentPage+1}
              onChange={(event, newPage) =>
                handlePageChange(event, newPage - 1)
              }
              variant="outlined"
              shape="rounded"
            />
          </Box>
          <CustomPopup open={deleteConfirmationPopup} handleOpen={() => {}}>
            <Box className="custom-delete-popup">
              <img src={ErrorImg} alt="delete alert" />
              <Typography
                variant="h4"
                color={"#252525"}
                marginTop={"1.5rem"}
                marginBottom=".9375rem"
              >
                Delete
              </Typography>
              <Typography
                variant="h5"
                color={"#797979"}
                marginBottom={"1.5rem"}
              >
                Are you sure you want to delete?
              </Typography>
              <Box display="flex" gap={"1rem"}>
                <Button
                  variant="outlined"
                  sx={{
                    border: "1px solid #C7222A",
                    padding: "16px 42px 16px 42px",
                    width: "16rem",
                  }}
                  onClick={() => {
                    closeDeletePopup();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    padding: "16px 42px 16px 42px",
                    width: "16rem",
                  }}
                  onClick={() => {
                    onDeleteClick(
                      selectedUserName,
                      selectedUserEmail,
                      selectedUserProfileId,
                      selectedUserAdminId
                    );
                  }}
                >
                  Delete
                </Button>
              </Box>
            </Box>
          </CustomPopup>
          <CustomPopup open={deletionSuccess} handleOpen={() => {}}>
            <CustomSuccessPopupBody
              title="Success"
              des="your changes have been saved"
              btnLabel="Continue"
              btnOnClick={() => {
                setDeletionSuccess(false);
              }}
            />
          </CustomPopup>

          <CustomPopup open={deletionFailure} handleOpen={() => {}}>
            <CustomFailurePopupBody
              // open={showFailure}
              // handleOpen={() => {
              //   setShowFailure(false);
              // }}
              btnLabel="Try again"
              btnClick={() => {
                setDeletionFailure(false);
              }}
            />
          </CustomPopup>
        </Container>
      </Box>
    );
  }
};

export default AdminUserDetailList;
