import React, { useState } from "react";
import "./CustomFeature.scss";
import { Box, Typography, Button, TextField, Grid } from "@mui/material";
import { Feature } from "../../features/Features/T_FeaturesDetailsTypes";
import { Colors } from "../../styles/constant";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import EditButtonIcon from "../../assets/icons/edit-button-icon.svg";
import { useLazyUpdateFeatureLobDetailsQuery } from "../../features/Features/FeaturesDetails_Api";
import CustomPopup from "../../components/CustomPopup/CustomPopup";
import submitPopupIcon from "../../assets/icons/error-outline.svg";
import APIFailurePopup from "../CustomAPIFailurePopup/CustomAPIFailurePopup";
import CommonSuccessPopupBody from "../CustomSuccessPopupBody/CustomSuccessPopupBody";
import CustomThreeDotsLoader from "../CustomThreeDotsLoader/CustomThreeDotsLoader";

interface CustomFeatureProps {
  data: Feature[];
  onVersionHistoryClick: (featureId: number, lobId: string) => void;
}

const CustomFeature: React.FC<CustomFeatureProps> = ({
  data = [],
  onVersionHistoryClick,
}) => {
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [open, setOpen] = useState(false);
  const [tempData, setTempData] = useState(data);
  const [selectedIndex, setSelectedIndex] = useState<number>(9191);
  const [apiFailure, setApiFailure] = useState<boolean>(false);
  const [apiSuccess, setApiSuccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [dateErrors, setDateErrors] = useState({
    featureStartDate: false,
    featureEndDate: false,
  });

  const handleVersionHistoryClick = (featureId: number, lobId: string) => {
    onVersionHistoryClick(featureId, lobId);
  };
  const handleEditToggle = (index: number) => {
    if (editingIndex === index) {
      setEditingIndex(null);
      setTempData(data);
    } else {
      setEditingIndex(index);
      setTempData([...data]);
    }
  };

  const handleChange = (index: number, field: string, value: any) => {
    const updatedData = [...tempData];
    updatedData[index] = { ...updatedData[index], [field]: value };
    setTempData(updatedData);
  };
  const [updateFeatureLobDetails] = useLazyUpdateFeatureLobDetailsQuery();
  const handleSubmitClick = async (index: number) => {
    const {
      // isFinancialYearUpdate,
      featureId,
      functionalityCode,
      redirectionUrl,
      isActive,
      isLive,
      isDowntime,
      financialYearCount,
      featureEndDate,
    } = tempData[index];

    try {
      const payload = {
        isFinancialYerUpdate: false,
        featureId: Number(featureId),
        redirectionUrl: redirectionUrl,
        functionalityCode,
        adminId: 4,
        isActive: isActive,
        isLive: isLive,
        isDowntime: isDowntime,
        financialYearCount: financialYearCount,
        featureEndDate: featureEndDate,
      };
      setIsLoading(true);
      const res = await updateFeatureLobDetails(payload);
      setIsLoading(false);
      if (res?.data?.code === 1) {
        setApiSuccess(true);
      } else if (res?.data?.code === 2) {
        setApiFailure(true);
      } else {
        setApiFailure(true);
      }
    } catch (error) {
      console.error("Error updating feature:", error);
    } finally {
      setEditingIndex(null);
      setOpen(false);
    }
  };

  const validateDateFormat = (date: string): boolean => {
    const regex =
      /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;
    return regex.test(date);
  };

  const handleDateBlur = (field: string, value: string) => {
    if (value && !validateDateFormat(value)) {
      setDateErrors((prevErrors) => ({
        ...prevErrors,
        [field]: true,
      }));
    } else {
      setDateErrors((prevErrors) => ({
        ...prevErrors,
        [field]: false,
      }));
    }
  };
  // Use the mutation hook
  const handleSubmit = (index: number) => {
    setSelectedIndex(index);
    setOpen(true);
  };

  const handleOpen = () => {
    setOpen(false);
  };
  return (
    <>
      <Box>
        <Typography variant="h3">Features Management</Typography>
        {tempData.map((value, index) => (
          <Box className="Feature-Management-wrapper">
            <Box
              sx={{
                justifyContent: "space-between",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h3">{value.featureDisplayName}</Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="h6"
                  marginX={"4px"}
                  color={Colors.red}
                  onClick={() =>
                    handleVersionHistoryClick(value.featureId, value.lobId)
                  }
                >
                  View version history
                </Typography>
              </Box>
            </Box>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={value.isActive}
                    onChange={(event) => {
                      if (editingIndex !== index) {
                        event.preventDefault();
                      } else {
                        handleChange(index, "isActive", event.target.checked);
                      }
                    }}
                  />
                }
                label="Feature Enable/ Disable"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={value.isLive}
                    onChange={(event) => {
                      if (editingIndex !== index) {
                        event.preventDefault();
                      } else {
                        handleChange(index, "isLive", event.target.checked);
                      }
                    }}
                  />
                }
                label="Display maintenance screen"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={value.isDowntime}
                    onChange={(event) => {
                      if (editingIndex !== index) {
                        event.preventDefault();
                      } else {
                        handleChange(index, "isDowntime", event.target.checked);
                      }
                    }}
                  />
                }
                label="Display maintenance screen"
              />
            </FormGroup>
            <Box className="Feature-Management-form">
              <Grid container spacing={2}>
                {value.redirectionUrl && (
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Redirection URL"
                      name="Redirection URL"
                      value={tempData[index].redirectionUrl}
                      onChange={(e) =>
                        handleChange(index, "redirectionUrl", e.target.value)
                      }
                      // disabled={!editMode}

                      disabled={editingIndex !== index}
                    />
                  </Grid>
                )}
                {value.functionalityCode && (
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Functionality Code"
                      name="Functionality Code"
                      value={tempData[index].functionalityCode}
                      onChange={(e) =>
                        handleChange(index, "functionalityCode", e.target.value)
                      }
                      // disabled={!editMode}
                      disabled={editingIndex !== index}
                    />
                  </Grid>
                )}
                {value.financialYearCount && (
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Financial Years"
                      name="Financial Years"
                      value={tempData[index].financialYearCount}
                      onChange={(e) =>
                        handleChange(
                          index,
                          "financialYearCount",
                          e.target.value
                        )
                      }
                      // disabled={!editMode}
                      disabled={editingIndex !== index}
                    />
                  </Grid>
                )}
                {value.featureStartDate && (
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="From"
                      name="From"
                      value={tempData[index].featureStartDate}
                      onChange={(e) =>
                        handleChange(index, "featureStartDate", e.target.value)
                      }
                      onBlur={() =>
                        handleDateBlur(
                          "featureStartDate",
                          tempData[index].featureStartDate
                        )
                      }
                      error={dateErrors.featureStartDate}
                      helperText={
                        dateErrors.featureStartDate
                          ? "Invalid date format. Use DD/MM/YYYY"
                          : ""
                      }
                      disabled={editingIndex !== index}
                    />
                  </Grid>
                )}
                {value.featureEndDate && (
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="To Present"
                      name="To Present"
                      // value={getYearRange(tempData[index].featureEndDate, "to")}
                      value={tempData[index].featureEndDate}
                      onChange={(e) =>
                        handleChange(index, "featureEndDate", e.target.value)
                      }
                      onBlur={() =>
                        handleDateBlur(
                          "featureEndDate",
                          tempData[index].featureEndDate
                        )
                      }
                      error={dateErrors.featureEndDate}
                      helperText={
                        dateErrors.featureEndDate
                          ? "Invalid date format. Use DD/MM/YYYY"
                          : ""
                      }
                      // disabled={!editMode}
                      disabled={editingIndex !== index}
                    />
                  </Grid>
                )}
              </Grid>

              <Box className="Feature-Management-button">
                {
                  // editMode
                  editingIndex === index ? (
                    <>
                      <Button
                        className="button"
                        //  onClick={handleEditToggle}
                        // onClick={handleEditToggle}
                        onClick={() => handleEditToggle(index)}
                      >
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        className="button"
                        // onClick={handleEditToggle}
                        // onClick={handleEditToggle}
                        onClick={() => handleEditToggle(index)}
                      >
                        <img
                          src={EditButtonIcon}
                          alt="Edit Icon"
                          style={{ marginRight: "8px" }}
                        />
                        Edit
                      </Button>
                    </>
                  )
                }
                <Button
                  className="button"
                  //  onClick={() => handleSubmit(index)}
                  onClick={() => handleSubmit(index)}
                  disabled={
                    dateErrors.featureStartDate || dateErrors.featureEndDate
                  }
                >
                  Submit
                </Button>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
      <CustomPopup
        open={open}
        handleOpen={handleOpen}
        className="api-failure-popup"
      >
        <Box padding="1.5rem 1rem">
          <Box display="flex" justifyContent="center">
            <img src={submitPopupIcon} alt="no-connection" />
          </Box>
          <Box marginTop="1.5rem">
            <Typography textAlign="center" variant="h3">
              {/* {customTitle} */}
              Submit
            </Typography>
            <Typography
              marginTop="1rem"
              textAlign="center"
              variant="h5"
              paddingX="1.25rem"
              color={Colors.textGray}
            >
              {/* {customSubTitle} */}
              Are you sure you want to submit
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              gap: "1rem",
            }}
          >
            <Button
              fullWidth
              variant="outlined"
              sx={{ marginTop: "1rem" }}
              onClick={() => {
                setOpen(false);
              }}
            >
              Cancel
            </Button>
            {isLoading ? (
              <CustomThreeDotsLoader />
            ) : (
              <Button
                fullWidth
                variant="contained"
                sx={{ marginTop: "1rem" }}
                onClick={async () => {
                  await handleSubmitClick(selectedIndex);
                }}
              >
                Submit
              </Button>
            )}
          </Box>
        </Box>
      </CustomPopup>
      <APIFailurePopup
        open={apiFailure}
        handleOpen={() => setApiFailure(false)}
      ></APIFailurePopup>
      <CustomPopup
        open={apiSuccess}
        handleOpen={() => {
          setApiSuccess(false);
        }}
      >
        <CommonSuccessPopupBody
          title="Success!"
          des="Your changes have been saved"
          btnLabel="Continue"
          btnOnClick={() => setApiSuccess(false)}
        />
      </CustomPopup>
    </>
  );
};

export default CustomFeature;
