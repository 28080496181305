export const defaultEmptyFun = () => {
  //This is intentional
};

export const getMobileOperatingSystem = () => {
  var userAgent = navigator.userAgent || (window as any)?.opera;

  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }
  if (/android/i.test(userAgent)) {
    return "Android";
  }
  if (/iPad|iPhone|iPod/.test(userAgent) || iOS()) {
    return "iOS";
  }
  return "unknown";
};

const iOS = () => {
  return [
    "iPad Simulator",
    "iPhone Simulator",
    "iPod Simulator",
    "iPad",
    "iPhone",
    "iPod",
  ].includes(navigator.platform);
};

export const downloadPdfFromBase64 = async (
  pdfData: string = "",
  fileName: string = "file.pdf"
) => {
  const byteCharacters = atob(pdfData);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: "application/pdf" });
  const blobUrl = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = blobUrl;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  // Clean up the object URL after the download
  URL.revokeObjectURL(blobUrl);
};

export const downloadExcelFromBase64 = async (
  excelData: string = "",
  fileName: string = "file.xlsx"
) => {
  // Decode base64 data to byte array
  const byteCharacters = atob(excelData);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);

  // Create a Blob from the byte array with MIME type for Excel (.xlsx)
  const blob = new Blob([byteArray], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  // Create object URL for the Blob
  const blobUrl = URL.createObjectURL(blob);

  // Create a link element to trigger the download
  const link = document.createElement("a");
  link.href = blobUrl;
  link.download = fileName;

  // Append link to the body, click it, and then remove it
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  // Clean up the object URL after the download
  URL.revokeObjectURL(blobUrl);
};
export const getInitials = (name: string = "") => {
  if (name) {
    const parts = name.split(" ");
    let initials = [""];
    if (parts?.length > 1) {
      initials = [parts[0]?.charAt(0), parts[parts.length - 1]?.charAt(0)];
    } else {
      initials = [parts[0]?.charAt(0)];
    }
    return initials.join("").toUpperCase(); // Convert to uppercase if needed
  }
};

export const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isValidateEmail = (email: string): boolean => {
  const isValidEmailFormat = emailRegex.test(email);
  const isCorrectDomain = email.endsWith("@adityabirlacapital.com");

  return isValidEmailFormat && isCorrectDomain;
  // return emailRegex.test(email);
};

export const formatTime = (value: string): string => {
  // Convert ISO string to Date object
  const date = new Date(value);

  // Get hours and minutes
  let hours = date.getHours();
  let minutes = date.getMinutes();

  // Determine AM or PM
  const period = hours >= 12 ? "PM" : "AM";

  // Convert hours to 12-hour format
  hours = hours % 12;
  hours = hours ? hours : 12; // Convert 0 to 12 for midnight

  // Format minutes to always show two digits
  const minutesString = minutes < 10 ? "0" + minutes : minutes.toString();

  // Return formatted time string
  return `${hours}:${minutesString} ${period}`;
};

export const formatDate = (dateStr: string): string => {
  let dateObj = new Date(dateStr);

  const monthNames: string[] = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  let day: number = dateObj.getDate();
  let month: string = monthNames[dateObj.getMonth()];
  let year: string = dateObj.getFullYear().toString().slice(-2);

  let daySuffix: string;
  if (day > 3 && day < 21) {
    daySuffix = "th";
  } else {
    switch (day % 10) {
      case 1:
        daySuffix = "st";
        break;
      case 2:
        daySuffix = "nd";
        break;
      case 3:
        daySuffix = "rd";
        break;
      default:
        daySuffix = "th";
    }
  }
  // Extract and format time components
  let hours: number = dateObj.getHours();
  let minutes: number = dateObj.getMinutes();
  let ampm: string = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours || 12; // The hour '0' should be '12'
  let formattedMinutes: string =
    minutes < 10 ? "0" + minutes : minutes.toString();

  let time: string = `${hours}:${formattedMinutes} ${ampm}`;

  return `${day}${daySuffix} ${month}, ${year} | ${time}`;
};


export const getYearRange = (value: string, type: string): string => {
  const date = new Date(value);
  const year = date.getFullYear();

  // Use a ternary operator to format the year range as a string
  const yearRange = type === "from" ? `${year}-${(year + 1).toString().slice(-2)}` : `${year - 1}-${(year).toString().slice(-2)}`;

  // Return the year range as a string
  return yearRange;
};