import React, { FC, useCallback, useState } from "react";
import "./UserAccordion.scss";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import { Colors } from "../../styles/constant";
import { defaultEmptyFun } from "../../utils/helper";

interface Props {
  title: string;
  note?: string;
  preIcon?: React.ReactNode | null;
  children?: React.ReactNode | null;
  subTitle?: any;
  handleAccordionClick?: Function;
  customClass?: string;
  expandedByDefault?: boolean;
  isVisible?:boolean
}

const UserAccordion: FC<Props> = ({
  title = "",
  note = "",
  preIcon,
  children,
  subTitle = "",
  handleAccordionClick = defaultEmptyFun,
  customClass = "",
  expandedByDefault = false,
  isVisible=true
}: Props) => {
  const [expanded, setExpanded] = useState<boolean>(expandedByDefault);

  const handleChange = useCallback(() => {
    setExpanded((prevExpanded) => !prevExpanded);
    if (!expanded) {
      handleAccordionClick();
    }
  }, [handleAccordionClick]);

  return (
    <Box className={`custom-user-accordion-wrapper ${customClass}`}>
      {note && (
        <Box className="accordion-note">
          <Typography color={Colors.darkYellow} variant="h6">
            {note}
          </Typography>
        </Box>
      )}
      <Accordion
        className="custom-accordion"
        disableGutters={true}
        expanded={expanded}
      >
        <AccordionSummary
          expandIcon={
            isVisible ? (
            <KeyboardArrowDownIcon
              onClick={handleChange}
              sx={{ color: Colors.red }}
            /> ) : null
          }
          aria-controls="panel1-content"
          id="panel1-header"
          className="accordion-summary"
        >
          <Box display="flex" alignItems="center">
            {preIcon}
            <Box marginLeft={preIcon ? "0.5rem" : 0}>
              <Typography variant="h3" onClick={handleChange}>
                {title}
              </Typography>
              {subTitle && <Box>{subTitle}</Box>}
            </Box>
          </Box>
        </AccordionSummary>
        {children && (
          <AccordionDetails className="accordion-details" key={title}>
            {children}
          </AccordionDetails>
        )}
      </Accordion>
    </Box>
  );
};

export default UserAccordion;
