import {
  UserRegistrationReport,
  API_TOKEN,
  Access_Token,
  UserRegistrationReportByEmail,
  UserRegistrationReportByDownload,
} from "../../../utils/ApiEndpoints";
import { T_ApiResponse } from "../../../globalTypes/GlobalTypes";
import { CSApiService } from "../../../redux-store/CSApiService";
import {
  T_UserRegistrationReport_Request,
  T_UserRegistrationReport_Response,
  T_UserRegistrationReportByDownload_Request,
  T_UserRegistrationReportByDownload_Response,
  T_UserRegistrationReportByEmail_Request,
  T_UserRegistrationReportByEmail_Response,
} from "./T_OnboardingCompletionReportTypes";

export const OnboardingCompletionReportAPI = CSApiService.injectEndpoints({
  endpoints: (builder) => ({
    fetchUserRegistrationReport: builder.query<
      T_ApiResponse<T_UserRegistrationReport_Response>,
      T_UserRegistrationReport_Request
    >({
      query: (payload) => ({
        url: UserRegistrationReport,
        method: "POST",
        body: payload,
        headers: {
          Authorization: `Bearer ${API_TOKEN}`,
          authToken: `${Access_Token}`,
          traceId: "abc", // Add traceId to headers
          csUserId: "abc",
          source: "abc",
        },
      }),
    }),
    fetchUserRegistrationReportByEmail: builder.query<
      T_ApiResponse<T_UserRegistrationReportByEmail_Request>,
      T_UserRegistrationReportByEmail_Request
    >({
      query: (payload) => ({
        url: UserRegistrationReportByEmail,
        method: "POST",
        body: payload,
        headers: {
          Authorization: `Bearer ${API_TOKEN}`,
          authToken: `${Access_Token}`,
          traceId: "abc", // Add traceId to headers
          csUserId: "abc",
          source: "abc",
        },
      }),
    }),
    fetchUserRegistrationReportByDownload: builder.query<
      T_ApiResponse<T_UserRegistrationReportByDownload_Response>,
      T_UserRegistrationReportByDownload_Request
    >({
      query: (payload) => ({
        url: UserRegistrationReportByDownload,
        method: "POST",
        body: payload,
        headers: {
          Authorization: `Bearer ${API_TOKEN}`,
          authToken: `${Access_Token}`,
          traceId: "abc", // Add traceId to headers
          csUserId: "abc",
          source: "abc",
        },
      }),
    }),
  }),
});

export const {
  useLazyFetchUserRegistrationReportQuery,
  useLazyFetchUserRegistrationReportByEmailQuery,
  useLazyFetchUserRegistrationReportByDownloadQuery,
} = OnboardingCompletionReportAPI;
