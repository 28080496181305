import {
  UserRegistrationReport,
  API_TOKEN,
  Access_Token,
  UserRegistrationReportByEmail,
  UserRegistrationReportByDownload,
  srDetailsReport,
  srDetailsReportDownload,
} from "../../../utils/ApiEndpoints";
import { T_ApiResponse } from "../../../globalTypes/GlobalTypes";
import { CSApiService } from "../../../redux-store/CSApiService";
import {
  T_SRDetailsReport_Request,
  T_SRDetailsReport_response,
  T_SrDetailsReportByDownload_Response,
  T_SRdetailsReportDownload_Request,
  T_UserRegistrationReportByEmail_Request,
  T_UserRegistrationReportByEmail_Response,
} from "./T_SRRaiseReportsTypes";

export const SRRaiseReportsAPI = CSApiService.injectEndpoints({
  endpoints: (builder) => ({
    srDetailsReportApi: builder.query<
      T_ApiResponse<T_SRDetailsReport_response>,
      T_SRDetailsReport_Request
    >({
      query: (payload) => ({
        url: srDetailsReport,
        method: "POST",
        body: payload,
        headers: {
          Authorization: `Bearer ${API_TOKEN}`,
          authToken: `${Access_Token}`,
          traceId: "abc", // Add traceId to headers
          csUserId: "abc",
          source: "abc",
        },
      }),
    }),
    fetchUserRegistrationReportByEmail: builder.query<
      T_ApiResponse<T_UserRegistrationReportByEmail_Request>,
      T_UserRegistrationReportByEmail_Request
    >({
      query: (payload) => ({
        url: UserRegistrationReportByEmail,
        method: "POST",
        body: payload,
        headers: {
          Authorization: `Bearer ${API_TOKEN}`,
          authToken: `${Access_Token}`,
          traceId: "abc", // Add traceId to headers
          csUserId: "abc",
          source: "abc",
        },
      }),
    }),
    srDetailsDownloadReportApi: builder.query<
      T_ApiResponse<T_SrDetailsReportByDownload_Response>,
      T_SRdetailsReportDownload_Request
    >({
      query: (payload) => ({
        url: srDetailsReportDownload,
        method: "POST",
        body: payload,
        headers: {
          Authorization: `Bearer ${API_TOKEN}`,
          authToken: `${Access_Token}`,
          traceId: "abc", // Add traceId to headers
          csUserId: "abc",
          source: "abc",
        },
      }),
    }),
  }),
});

export const {
  useLazySrDetailsReportApiQuery,
  useLazyFetchUserRegistrationReportByEmailQuery,
  useLazySrDetailsDownloadReportApiQuery,
} = SRRaiseReportsAPI;
