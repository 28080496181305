import { BaseQueryApi, createApi, retry } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../axios/axios";
import {
  AxiosFetchQuery,
  T_ApiResponse,
  T_GenerateTokenRes,
} from "../globalTypes/GlobalTypes";
import { Mutex } from "async-mutex";
import { AxiosError } from "axios";
import { Api_GenerateToken } from "../utils/ApiEndpoints";
import { PayloadAction } from "@reduxjs/toolkit";
import { setError } from "./reducers/CsErrorSlice";
let store: any = undefined;
export const injectStoreApiService = (_store: any) => {
  store = _store;
};

let isGenerateTokenApiFailed: boolean = false;

// initialize an empty api service that we'll inject endpoints into later as needed
const mutex = new Mutex();
const handleForbiddenScenario = async (
  result: any,
  args: AxiosFetchQuery,
  api: BaseQueryApi,
  extraOptions: any
) => {
  if (!mutex.isLocked()) {
    const release = await mutex.acquire();
    try {
      // const isUserLoggedIn = store.getState()?.LoginSlice.isUserLoggedIn;
      const isUserLoggedIn = true;
      const generateTokenArgs: AxiosFetchQuery = {
        url: "",
        headers: {
          csUserId: "",
        },
      };
      const refreshResult = await axiosBaseQuery()(
        generateTokenArgs,
        api,
        extraOptions
      );
      if (refreshResult.data) {
        // retry the initial query
        result = await axiosBaseQuery()(args, api, extraOptions);
      } else {
      }
      return result;
    } catch (e) {
      console.log(e);
      return result;
    } finally {
      // release must be called once the mutex should be released again.
      release();
    }
  }
};

const getInitialToken = async (
  api: BaseQueryApi,
  extraOptions: any
): Promise<any | Error> => {
  return new Promise(async (res, rej) => {
    try {
      const generateTokenArgs: AxiosFetchQuery = {
        url: Api_GenerateToken,
      };
      const tokenResult = await axiosBaseQuery()(
        generateTokenArgs,
        api,
        extraOptions
      );
      if (tokenResult.data) {
        const actionObj: PayloadAction<T_GenerateTokenRes> = {
          type: "CsAppSlice/setTokenDetails",
          payload: (tokenResult.data as T_ApiResponse<T_GenerateTokenRes>)
            .payload,
        };
        api.dispatch(actionObj);
      }
      return res(tokenResult);
    } catch (e) {
      console.error(e);
      return rej(e as Error);
    }
  });
};
export const CSApiService = createApi({
  reducerPath: "ApiService",
  keepUnusedDataFor: 1800,
  baseQuery: retry(
    async (args: AxiosFetchQuery, api, extraOptions) => {
      if (mutex.isLocked()) {
        await mutex.waitForUnlock();
      }
      if (
        !store.getState()?.CsAppSlice?.tokenDetails?.internal?.authToken &&
        !isGenerateTokenApiFailed
      ) {
        if (!mutex.isLocked()) {
          const release = await mutex.acquire();
          const tokenResponse: any = await getInitialToken(api, {
            ...extraOptions,
            retry: 3,
          });
          if (tokenResponse.error) {
            isGenerateTokenApiFailed = true;
            api.dispatch(
              setError({
                error: tokenResponse.error,
                message: "Something went wrong!",
              })
            );
          }
          release();
        }
      }
      if (!isGenerateTokenApiFailed) {
        let result = await axiosBaseQuery()(args, api, extraOptions);
        /**Incase of any 401:unauthorized errror,
         * retry for token once and continue
         * incase of error in getting token do logout
         */
        // if (result.error && (result.error as AxiosError)?.status === 401) {
        //   result = await handleUnAuthScenario(result, args, api, extraOptions);
        // }
        if (
          result.error &&
          [401].includes((result.error as AxiosError)?.status || 200)
        ) {
          getInitialToken(api, {
            ...extraOptions,
            retry: 3,
          });
        }
        return result;
      } else {
        return { error: Error("Something went wrong!") };
      }
    },
    {
      maxRetries: 0,
    }
    // async (args: AxiosFetchQuery, api, extraOptions) => {
    //   await mutex.waitForUnlock();
    //   let result = await axiosBaseQuery()(args, api, extraOptions);
    //   /**Incase of any 403:Forbidden, 401:unauthorized errror, retry for token once and continue
    //    * incase of error in getting token do logout
    //    */
    //   if (
    //     result.error &&
    //     [403, 401].includes((result.error as AxiosError)?.status || 200)
    //   ) {
    //     result = await handleForbiddenScenario(result, args, api, extraOptions);
    //   }

    //   return result;
    // },
    // {
    //   maxRetries: 0,
    // }
  ),
  tagTypes: [],
  endpoints: () => ({}),
});

export const CSApiServiceStoreKey = CSApiService.reducerPath;
export const { reducer: CSApiServiceReducer } = CSApiService;
