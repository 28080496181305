import {
  UserLoginReport,
  API_TOKEN,
  Access_Token,
  UserLoginReportByEmail,
  UserLoginnReportByDownload,
} from "../../../utils/ApiEndpoints";
import { T_ApiResponse } from "../../../globalTypes/GlobalTypes";
import { CSApiService } from "../../../redux-store/CSApiService";
import {
  T_UserLoginReportByDownload_Request,
  T_UserLoginReportByDownload_Response,
  T_UserRegistrationReport_Request,
  T_UserRegistrationReport_Response,
  T_UserRegistrationReportByDownload_Request,
  T_UserRegistrationReportByDownload_Response,
  T_UserRegistrationReportByEmail_Request,
  T_UserRegistrationReportByEmail_Response,
} from "./T_UserLoginReport";

export const UserLoginApi = CSApiService.injectEndpoints({
  endpoints: (builder) => ({
    fetchUserLoginReport: builder.query<
      T_ApiResponse<T_UserRegistrationReport_Response>,
      T_UserRegistrationReport_Request
    >({
      query: (payload) => ({
        url: UserLoginReport,
        method: "POST",
        body: payload,
        headers: {
          Authorization: `Bearer ${API_TOKEN}`,
          authToken: `${Access_Token}`,
          traceId: "abc", // Add traceId to headers
          csUserId: "abc",
          source: "abc",
        },
      }),
    }),
    fetchUserRegistrationReportByEmail: builder.query<
      T_ApiResponse<T_UserRegistrationReportByEmail_Request>,
      T_UserRegistrationReportByEmail_Request
    >({
      query: (payload) => ({
        url: UserLoginReportByEmail,
        method: "POST",
        body: payload,
        headers: {
          Authorization: `Bearer ${API_TOKEN}`,
          authToken: `${Access_Token}`,
          traceId: "abc", // Add traceId to headers
          csUserId: "abc",
          source: "abc",
        },
      }),
    }),
    fetchUserRegistrationReportByDownload: builder.query<
      T_ApiResponse<T_UserRegistrationReportByDownload_Response>,
      T_UserRegistrationReportByDownload_Request
    >({
      query: (payload) => ({
        url: UserLoginnReportByDownload,
        method: "POST",
        body: payload,
        headers: {
          Authorization: `Bearer ${API_TOKEN}`,
          authToken: `${Access_Token}`,
          traceId: "abc", // Add traceId to headers
          csUserId: "abc",
          source: "abc",
        },
      }),
    }),
    fetchUserLoginReportByDownload: builder.query<
      T_ApiResponse<T_UserLoginReportByDownload_Response>,
      T_UserLoginReportByDownload_Request
    >({
      query: (payload) => ({
        url: UserLoginnReportByDownload,
        method: "POST",
        body: payload,
        headers: {
          Authorization: `Bearer ${API_TOKEN}`,
          authToken: `${Access_Token}`,
          traceId: "abc", // Add traceId to headers
          csUserId: "abc",
          source: "abc",
        },
      }),
    }),
  }),
});

export const {
  useLazyFetchUserLoginReportQuery,
  useLazyFetchUserRegistrationReportByEmailQuery,
  useLazyFetchUserRegistrationReportByDownloadQuery,
  useLazyFetchUserLoginReportByDownloadQuery,
} = UserLoginApi;
