import { FC, useState } from "react";
import {
  Box,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Divider,
  IconButton,
} from "@mui/material";

import { dashboardLobList, DetailsPageTabKeys } from "../../utils/constants";
import "./DetailsPage.scss";
// import Otp from "../../features/OtpManagement/OtpManagement";
import { useLocation } from "react-router-dom";
import FolderIcon from "../../assets/icons/Folder.svg";
import WhiteFolderIcon from "../../assets/icons/WhiteFolderIcon.svg";
import DropDownIcon from "../../assets/icons/drop-down.svg";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import CollapseIcon from "../../assets/icons/NavbarCollapseIcon.svg";
import { Colors } from "../../styles/constant";
// import SRRaiseReportDetailsPage from "../Reports/SRRaiseReport/SRRaiseReportDetailsPage";
// import OnboardingCompletionReport from "../Reports/OnboardingCompletionReport/OnboardingCompletionReport";
// import UserLoginReport from "../Reports/UserLoginReport/UserLoginReport";
// import FeatureManagementPage from "../Features/FeatureManagementPage";
import AddNewCustomer from "../../features/UserManagement/Customer/AddNewCustomer/AddNewCustomer";
import AddUserMobile from "../../features/UserManagement/Customer/AddUserMobile/AddUserMobile";
import FeatureManagementPage from "../../features/Features/FeatureManagementPage";
import SRRaiseReportDetailsPage from "../../features/Reports/SRRaiseReport/SRRaiseReportDetailsPage";
// import { UserLoginReport } from "../../features/Reports/UserLoginReport/UserLoginReport";
import UserLoginReport from "../../features/Reports/UserLoginReport/UserLoginReport";
import OnboardingCompletionReport from "../../features/Reports/OnboardingCompletionReport/OnboardingCompletionReport";
import AddNewProfile from "../../features/UserManagement/AdminProfileList/AddNewProfile/AddNewProfile";
import AddUser from "../../features/UserManagement/AddUser/AddUser";
import AdminProfileList from "../../features/UserManagement/AdminProfileList/AdminProfileList";
import CustomerUserManagement from "../../features/UserManagement/Customer/CustomerUserManagement/CustomerUserManagement";
import AdminAuditHistory from "../AdminAuditHistory/AdminAuditHistory";
import ManualAdditionReport from "../../features/Reports/ManualAdditionReport/ManualAdditionReport";
import RedirectionCountReport from "../../features/Reports/RedirectionCountReport/RedirectionCountReport";
import MPINChangeReport from "../../features/Reports/MPINChangeReport/MPINChangeReport";
const DetailsPage: FC = () => {
  const [activeTab, setActiveTab] = useState("");
  const [lobId, setLobId] = useState<string>("");
  const [tabName, setTabname] = useState<string>("");
  const [expanded, setExpanded] = useState<number | null>(null);
  const [nestedExpanded, setNestedExpanded] = useState<string | null>(null);
  const [selectedItem, setSelectedItem] = useState<string | null>(null); // Track the selected item
  const [drawerOpen, setDrawerOpen] = useState(true); // Manage drawer state
  const [showAdminAuditHistory, setShowAdminAuditHistory] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
    // setDrawerHeight(drawerOpen ? "60px" : "100%"); // Adjust height for partially collapsed state
  };
  console.log(showAdminAuditHistory, "showAdminAuditHistory>>>");
  const handleExpandClick = (id: number) => {
    if (id === 10) {
      setShowAdminAuditHistory(true);
    } else {
      setShowAdminAuditHistory(false);
    }

    setExpanded(expanded === id ? null : id);
    setActiveTab("");
    setLobId("");
    setNestedExpanded(null);
  };

  const handleNestedExpandClick = (section: string) => {
    setNestedExpanded(nestedExpanded === section ? null : section);
    setActiveTab("");
    setLobId("");
  };

  const handleDrawerItemClick = (value: string, lobID: string) => {
    setLobId(lobID);
    setActiveTab(value);
    setSelectedItem(value); // Set the selected item
  };

  return (
    <Box className="details-page" display="flex">
      <Drawer
        variant="permanent"
        // sx={{
        //   marginTop: "1rem",
        //   width: "20%",
        // }}
        sx={{
          marginTop: "1rem",
          width: drawerOpen ? "282px" : "78px", // Adjust width based on drawer state
          overflow: "hidden", // Prevent overflow when drawer is collapsed
        }}
        PaperProps={{
          sx: {
            position: "relative", // This ensures the Drawer is relative
            border: "none",
            backgroundColor: "#252525",
          },
        }}
      >
        <Box display="flex" justifyContent="flex-end" padding="0.5rem">
          <img
            src={CollapseIcon}
            alt="toggle-drawer"
            onClick={toggleDrawer}
            style={{
              cursor: "pointer",
              width: "24px", // Adjust size as needed
              height: "24px",
            }}
          />
        </Box>
        <List sx={{ marginLeft: "1rem", marginTop: "1rem" }}>
          {dashboardLobList.map((item) => (
            <Box key={item.id} marginBottom=".75rem">
              <ListItem
                onClick={() => handleExpandClick(item.id)}
                sx={{
                  border:
                    expanded === item.id ? ".0625rem solid #C7222A" : "none",
                  // paddingLeft: "12px",
                  // paddingRight: "12px",
                  borderRadius: "12px",
                  flexDirection: drawerOpen ? "row" : "column", // Change flexDirection based on drawer state
                  alignItems: drawerOpen ? "center" : "center", // Align items accordingly
                }}
              >
                <img
                  src={WhiteFolderIcon}
                  alt="right-arrow"
                  // style={{ color: Colors.red }}
                />
                <ListItemText
                  sx={{
                    marginX: "16px",
                    textAlign: "left",
                    color: "#FFFFFF",
                    // lineHeight: "12px",
                    // border:
                    //   selectedItem === item.title
                    //     ? ".0625rem solid #C7222A"
                    //     : "none",
                    // padding: ".25rem",
                  }}
                  primary={item.title}
                  primaryTypographyProps={{
                    style: { fontSize: drawerOpen ? "14px" : "10px" },
                  }}
                />

                {drawerOpen && (
                  <img
                    src={DropDownIcon}
                    alt="right-arrow"
                    style={{
                      color: Colors.red,
                      transform:
                        expanded === item.id ? "none" : "rotate(180deg)",
                      transition:
                        expanded === item.id ? "none" : "transform 0.3s ease",
                    }}
                  />
                )}
              </ListItem>
              {drawerOpen && (
                <Collapse
                  in={expanded === item.id}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    {/* {item.options.map((option) => (
                      <Box key={option.value}>
                        <ListItem
                          className={
                            activeTab === option.value ? "Mui-selected" : ""
                          }
                          onClick={() => {
                            if (option.options) {
                              handleNestedExpandClick(option.value);
                            } else {
                              handleDrawerItemClick(option.value, item.lobID);
                            }
                          }}
                          sx={{ pl: "3.25rem" }}
                        >
                          <ListItemText
                            sx={{
                              color:
                                activeTab === option.value ? "red" : "#FFFFFF", // Change text color to red if active
                            }}
                            primary={option.label}
                          />
                        </ListItem>

                        {option.options && (
                          <Collapse
                            in={nestedExpanded === option.value}
                            timeout="auto"
                            unmountOnExit
                          >
                            <List component="div" disablePadding>
                              {option.options.map((nestedOption) => (
                                <ListItem
                                  key={nestedOption.value}
                                  className={
                                    activeTab === nestedOption.value
                                      ? "Mui-selected"
                                      : ""
                                  }
                                  onClick={() =>
                                    handleDrawerItemClick(
                                      nestedOption.value,
                                      nestedOption.lobID
                                    )
                                  }
                                  sx={{ pl: 8 }}
                                >
                                  <ListItemText
                                    sx={{
                                      color:
                                        activeTab === nestedOption.value
                                          ? "red"
                                          : "#FFFFFF", // Change text color to red if active
                                    }}
                                    primary={nestedOption.label}
                                  />
                                </ListItem>
                              ))}
                            </List>
                          </Collapse>
                        )}
                      </Box>
                    ))} */}
                  </List>
                </Collapse>
              )}
            </Box>
          ))}
        </List>
      </Drawer>
      <Divider orientation="vertical" flexItem />

      <Box flex={1} padding="1rem">
        <Box marginTop="1rem">
          {/* {activeTab === DetailsPageTabKeys.Otp && <Otp />} */}
          {activeTab === DetailsPageTabKeys.FinancialYear && (
            <Typography variant="h4">{activeTab}</Typography>
          )}
          {activeTab === DetailsPageTabKeys.Features && (
            <FeatureManagementPage lobID={lobId} />
          )}
          {/* {activeTab === DetailsPageTabKeys.Reports && (
            <SRRaiseReportDetailsPage />
          )} */}

          {activeTab === DetailsPageTabKeys.LoginCountReport && (
            <UserLoginReport />
          )}
          {activeTab === DetailsPageTabKeys.OnboardingReport && (
            <OnboardingCompletionReport />
          )}
          {activeTab === DetailsPageTabKeys.SRRaiseReport && (
            <SRRaiseReportDetailsPage />
          )}

          {activeTab === DetailsPageTabKeys.ManualAdditionsReport && (
            <ManualAdditionReport />
          )}
          {activeTab === DetailsPageTabKeys.RedirectioncountReport && (
            <RedirectionCountReport />
          )}
          {activeTab === DetailsPageTabKeys.MpinChangeReport && (
            <MPINChangeReport />
          )}
          {/* {activeTab === DetailsPageTabKeys.UserManagement && (
            <CustomerUserManagement tabName={"customer"} />
          )} */}
          {activeTab === DetailsPageTabKeys.CustomerUserManagement && (
            <CustomerUserManagement />
          )}

          {showAdminAuditHistory && <AdminAuditHistory />}

          {/* {activeTab === DetailsPageTabKeys.AdminProfileManagement && (
            <AdminProfileManagement />
          )} */}
          {/* {activeTab === DetailsPageTabKeys.AddUserList && (
            <AdminProfileList tabName={"userList"} />
          )} */}
          {/* {activeTab === DetailsPageTabKeys.AddNewUserProfile && (
            <AddNewProfile />
          )} */}
        </Box>
      </Box>
    </Box>
  );
};

export default DetailsPage;
