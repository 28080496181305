import React, { useEffect, useState } from "react";
import "./FeatureManagementPage.scss";
import { Box, Divider, Grid, TextField, Typography } from "@mui/material";
import CustomFeature from "../../components/CustomFeature/CustomFeature";
import {
  useLazyFetchFeatureHistoryDetailsQuery,
  useLazyFetchFeatureLobDetailsQuery,
} from "./FeaturesDetails_Api";
import CustomLoader from "../../components/CustomLoader";
import DetailIcon from "../../assets/icons/Detail-icon.svg";
import { Colors } from "../../styles/constant";
import {
  Feature,
  dataFetchFeatureHistoryDetails,
} from "./T_FeaturesDetailsTypes";
import CustomVersionHistory from "../../components/CustomVersionHistory/CustomVersionHistory";
import CustomThreeDotsLoader from "../../components/CustomThreeDotsLoader/CustomThreeDotsLoader";
// Define an interface for the feature

interface Props {
  lobID: string;
}

const FeatureManagementPage: React.FC<Props> = ({ lobID = "01" }) => {
  const [features, setFeatures] = useState<Feature[]>([]);
  const [FeatureHistory, setFeatureHistory] = useState<
    dataFetchFeatureHistoryDetails[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false); // State for loading
  const [fetchFeatureLobDetails] = useLazyFetchFeatureLobDetailsQuery();
  const [fetchFeatureHistoryDetails] = useLazyFetchFeatureHistoryDetailsQuery();
  const [screen, setScreen] = useState<string>("DetailPage");
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageSize] = useState<number>(10);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await fetchFeatureLobDetails({
          lobMasterCode: lobID,
          pageNumber: 0,
          pageSize: 1000,
        }).unwrap();

        if (res.payload) {
          setFeatures(res?.payload?.data?.features);
        } else {
          console.error("Unexpected response structure:", res);
        }
      } catch (error) {
        console.error("Error fetching features:", error);
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    fetchData();
  }, [fetchFeatureLobDetails, lobID, pageSize, currentPage]);
  const handleVersionHistory = (featureId: number, lobId: string) => {
    const fetchFeatureHistory = async () => {
      setLoading(true);
      try {
        const res = await fetchFeatureHistoryDetails({
          featureId: featureId,
          masterLobCode: lobId,
          pageNumber: currentPage,
          pageSize: pageSize,
        }).unwrap();
        if (res.payload) {
          setFeatureHistory(res?.payload?.data);
        } else {
          console.error("Unexpected response structure:", res);
        }
      } catch (error) {
        console.error("Error fetching features:", error);
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    fetchFeatureHistory();

    setScreen("VersionHistoryPage");
  };
  const handleBackButtonClick = () => {
    setScreen("DetailPage");
  };
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };
  if (loading) {
    return (
      <Box sx={{ marginTop: "6.25rem" }}>
        <CustomThreeDotsLoader />
      </Box>
    );
  } else {
    return (
      <Box className="feature-management">
        {screen === "DetailPage" ? (
          <CustomFeature
            data={features}
            onVersionHistoryClick={handleVersionHistory}
          />
        ) : (
          <CustomVersionHistory
            dataHistory={FeatureHistory}
            onBackButtonClick={handleBackButtonClick}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        )}
      </Box>
    );
  }
};

export default FeatureManagementPage;
