import { FC, useState } from "react";
import {
  Box,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Divider,
} from "@mui/material";

import { dashboardLobList, DetailsPageTabKeys } from "../../utils/constants";
import "./Home.scss";
import FolderIcon from "../../assets/icons/Folder.svg";
import DropDownIcon from "../../assets/icons/drop-down.svg";
import { ArrowDropDown, Padding } from "@mui/icons-material";
import { Colors } from "../../styles/constant";
// import SRRaiseReportDetailsPage from "../Reports/SRRaiseReport/SRRaiseReportDetailsPage";
import SRRaiseReportDetailsPage from "../../features/Reports/SRRaiseReport/SRRaiseReportDetailsPage";
import OnboardingCompletionReport from "../../features/Reports/OnboardingCompletionReport/OnboardingCompletionReport";
import UserLoginReport from "../../features/Reports/UserLoginReport/UserLoginReport";
import FeatureManagementPage from "../../features/Features/FeatureManagementPage";
import CustomerUserManagement from "../../features/UserManagement/Customer/CustomerUserManagement/CustomerUserManagement";
import AdminProfileList from "../../features/UserManagement/AdminProfileList/AdminProfileList";

import AdminUserList from "../../features/UserManagement/AdminUserList/AdminUserList";
import AdminAddNewUser from "../../features/UserManagement/AdminUserList/AdminAddNewUser/AdminAddNewUser";
import AppBar from "@mui/material/AppBar";

import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";

import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";

import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";

import AdityaBirlaLogo from "../../assets/icons/adityabirlalogo.svg";
import WhiteFolderIcon from "../../assets/icons/WhiteFolderIcon.svg";
import WhiteDropdownIcon from "../../assets/icons/WhiteDropdownIcon.svg";
import ChevronIcon from "../../assets/icons/Chevron.svg";
import MasterManagement from "../../features/MasterManagement/MasterManagement";
import AdminAuditHistory from "../AdminAuditHistory/AdminAuditHistory";
import ManualAdditionReport from "../../features/Reports/ManualAdditionReport/ManualAdditionReport";
import RedirectionCountReport from "../../features/Reports/RedirectionCountReport/RedirectionCountReport";
import MPINChangeReport from "../../features/Reports/MPINChangeReport/MPINChangeReport";
import ChannelManagement from "../../features/UserManagement/ChannelManagement/ChannelManagement";

const settings = ["Profile", "Account", "Dashboard", "Logout"];

const Home: FC = () => {
  const [activeTab, setActiveTab] = useState("");
  const [lobId, setLobId] = useState<string>("");
  const [expanded, setExpanded] = useState<number | null>(null);
  const [nestedExpanded, setNestedExpanded] = useState<string | null>(null);
  const [drawerOpen, setDrawerOpen] = useState(true);

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [showAdminAuditHistory, setShowAdminAuditHistory] = useState(false);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  // Toggle drawer open/close
  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen); // Toggle the state
  };

  const handleDrawerHeaderClick = (
    id: number,
    lobID: string,
    options: any[]
  ) => {
    if (id === 10) {
      setShowAdminAuditHistory(true);
    } else {
      setShowAdminAuditHistory(false);
    }

    setExpanded(expanded === id ? null : id);
    setActiveTab("");
    setLobId("");
    setNestedExpanded(null);
    setLobId(lobID);
    // Set activeTab to the first option value from the options array
    setActiveTab(options[0].value);
  };
  const handleDrawerItemClick = (value: string, lobID: string) => {
    setLobId(lobID);
    setActiveTab(value);
  };

  return (
    <>
      <AppBar position="static" sx={{ backgroundColor: Colors.white }}>
        <Container maxWidth="xl">
          <Box
            sx={{
              display: "flex",
              padding: "20px 15px",
              borderRadius: 1,
            }}
          >
            <img src={AdityaBirlaLogo} alt="no-connection" />
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: "1.5rem",
                lineHeight: "1.8rem",
                color: Colors.black,
                marginLeft: "6.25rem",
                flexGrow: 1,
              }}
            >
              Servicing App Admin Console
            </Typography>
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem key={setting} onClick={handleCloseUserMenu}>
                    <Typography sx={{ textAlign: "center" }}>
                      {setting}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Box>
        </Container>
      </AppBar>
      <Box className="home-page" display="flex">
        <Drawer
          variant="permanent"
          sx={{
            // width: "20%",
            width: drawerOpen ? "20%" : "0",
            transition: "width 0.3s ease",
          }}
          PaperProps={{
            sx: {
              position: "relative", // This ensures the Drawer is relative
              border: "none",
              backgroundColor: "#252525",
            },
          }}
        >
          {/* Drawer Toggle Button */}
          {/* <img src={ChevronIcon} className="chevron-icon" /> */}

          <List sx={{ marginLeft: "1rem", marginTop: "1rem" }}>
            {dashboardLobList.map((item) => (
              <Box key={item.id} marginBottom=".75rem">
                <ListItem
                  // onClick={() => handleExpandClick(item.id)}
                  onClick={() =>
                    handleDrawerHeaderClick(item.id, item.lobID, item.options)
                  }
                  sx={{
                    border:
                      expanded === item.id ? ".0625rem solid #C7222A" : "none",
                    // paddingLeft: "12px",
                    // paddingRight: "12px",
                    borderRadius: "12px",
                    // flexDirection: drawerOpen ? "row" : "column", // Change flexDirection based on drawer state
                    // alignItems: drawerOpen ? "center" : "center", // Align items accordingly
                  }}
                >
                  <img
                    // src={FolderIcon}
                    src={expanded === item.id ? FolderIcon : WhiteFolderIcon}
                    alt="right-arrow"
                    style={{ color: Colors.red }}
                  />
                  <ListItemText
                    sx={{
                      marginX: "16px",
                      textAlign: "left",
                      color: "#FFFFFF",
                    }}
                    primary={item.title}
                  />
                  <img
                    // src={DropDownIcon}
                    src={
                      expanded === item.id ? DropDownIcon : WhiteDropdownIcon
                    }
                    alt="right-arrow"
                    style={{
                      // color: Colors.red,
                      color: expanded === item.id ? Colors.white : Colors.white,
                      transform:
                        expanded === item.id ? "none" : "rotate(180deg)",
                      transition:
                        expanded === item.id ? "none" : "transform 0.3s ease",
                    }}
                  />
                  {/* <img src={ChevronIcon} /> */}
                  {/* <ArrowDropDown
                    sx={{
                      color: expanded ? "#C7222A" : "#FFFFFF", // Red when expanded, white when collapsed
                      transform: expanded ? "rotate(0deg)" : "rotate(180deg)",
                      transition: "transform 0.3s ease, color 0.3s ease",
                    }}
                  /> */}
                </ListItem>
                <Collapse
                  in={expanded === item.id}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    {item.options.map((option) => (
                      <Box key={option.value}>
                        <ListItem
                          className={
                            activeTab === option.value ? "Mui-selected" : ""
                          }
                          onClick={() => {
                            // if (option.options) {
                            //   handleNestedExpandClick(option.value);
                            // } else {
                            //   handleDrawerItemClick(option.value, item.lobID);
                            // }
                            handleDrawerItemClick(option.value, item.lobID);
                          }}
                          // sx={{ pl: "3.25rem" }}
                          sx={{
                            pl: "3.25rem",
                            backgroundColor:
                              activeTab === option.value
                                ? "transparent"
                                : "none", // Ensure no background color
                            "&.Mui-selected": {
                              backgroundColor: "transparent", // Explicitly set background to transparent for selected item
                            },
                          }}
                        >
                          <ListItemText
                            primary={option.label}
                            // sx={{ color: "#FFFFFF" }}
                            sx={{
                              color:
                                activeTab === option.value
                                  ? "#C7222A"
                                  : "#FFFFFF", // Red for selected item
                              // transition: "color 0.3s ease", // Smooth transition for color change
                            }}
                          />
                        </ListItem>

                        {/* {option.options && (
                        <Collapse
                          in={nestedExpanded === option.value}
                          timeout="auto"
                          unmountOnExit
                        >
                          <List component="div" disablePadding>
                            {option.options.map((nestedOption) => (
                              <ListItem
                                key={nestedOption.value}
                                className={
                                  activeTab === nestedOption.value
                                    ? "Mui-selected"
                                    : ""
                                }
                                onClick={() =>
                                  handleDrawerItemClick(
                                    nestedOption.value,
                                    nestedOption.lobID
                                  )
                                }
                                sx={{ pl: 8 }}
                              >
                                <ListItemText primary={nestedOption.label} />
                              </ListItem>
                            ))}
                          </List>
                        </Collapse>
                      )} */}
                      </Box>
                    ))}
                  </List>
                </Collapse>
              </Box>
            ))}
          </List>
        </Drawer>
        <div className="divider-container">
          <div className="icon">
            <img
              src={ChevronIcon}
              onClick={handleDrawerToggle}
              alt="right-arrow"
              style={{ color: Colors.red }}
            />
          </div>
          <Divider orientation="vertical" flexItem />
        </div>

        <Box flex={1} padding="1rem">
          <Box marginTop="1rem">
            {activeTab === DetailsPageTabKeys.ChannelManagement && (
              <ChannelManagement />
            )}
            {activeTab === DetailsPageTabKeys.MasterManagement && (
              <MasterManagement />
            )}

            {activeTab === DetailsPageTabKeys.FinancialYear && (
              <Typography variant="h4">{activeTab}</Typography>
            )}
            {activeTab === DetailsPageTabKeys.Features && (
              <FeatureManagementPage lobID={lobId} />
            )}
            {/* {activeTab === DetailsPageTabKeys.Reports && (
              <SRRaiseReportDetailsPage />
            )} */}
            {activeTab === DetailsPageTabKeys.LoginCountReport && (
              <UserLoginReport />
            )}
            {activeTab === DetailsPageTabKeys.OnboardingReport && (
              <OnboardingCompletionReport />
            )}
            {activeTab === DetailsPageTabKeys.SRRaiseReport && (
              <SRRaiseReportDetailsPage />
            )}

            {activeTab === DetailsPageTabKeys.Reports && (
              <SRRaiseReportDetailsPage />
            )}

            {activeTab === DetailsPageTabKeys.ManualAdditionsReport && (
              <ManualAdditionReport />
            )}
            {activeTab === DetailsPageTabKeys.RedirectioncountReport && (
              <RedirectionCountReport />
            )}
            {activeTab === DetailsPageTabKeys.MpinChangeReport && (
              <MPINChangeReport />
            )}
            {activeTab === DetailsPageTabKeys.CustomerUserManagement && (
              <CustomerUserManagement />
            )}
            {activeTab === DetailsPageTabKeys.AddUserList && (
              // <AdminProfileList tabName={"userList"} />
              <AdminUserList />
            )}
            {activeTab === DetailsPageTabKeys.AddNewUserProfile && (
              <AdminProfileList />
            )}
            {/* {activeTab === DetailsPageTabKeys.AuditHistory && (
              <AdminAuditHistory />
            )} */}
            {showAdminAuditHistory && <AdminAuditHistory />}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Home;
