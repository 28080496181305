// import axios from "axios";
// import { USE_DEV_TEST_DATA } from "../utils/constants";

// let store: any = undefined;

// export const injectStore = (_store: any) => {
//   store = _store;
// };
// const axiosInstance = axios.create({
//   headers: {
//     "Content-Type": "application/json",
//     // Add any other headers or configurations you need
//   },
// });

// // Add a request interceptor

// // Add a request interceptor
// axiosInstance.interceptors.request.use(
//   (config) => {
//     // You can modify the request config here, e.g., add authentication headers
//     let source = undefined;
//     try {
//       const csAppSlice = store.getState()?.CsAppSlice;
//       console.log(csAppSlice, "csAppSlice");
//       source = csAppSlice?.deviceType;
//     } catch (err) {}
//     config.headers["source"] = source;
//     config.headers["traceId"] = store.getState()?.CsAppSlice.traceId;
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// // Add a response interceptor
// axiosInstance.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// export default axiosInstance;

import axios from "axios";
import { USE_DEV_TEST_DATA } from "../utils/constants";

let store: any = undefined;

export const injectStore = (_store: any) => {
  store = _store;
};
const axiosInstance = axios.create({
  headers: {
    "Content-Type": "application/json",
    // Add any other headers or configurations you need
  },
});

// Add a request interceptor

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // You can modify the request config here, e.g., add authentication headers
    let source = undefined;
    let internalToken = undefined;
    let externalToken = undefined;
    let adminId = undefined;

    try {
      const csAppSlice = store.getState()?.CsAppSlice;
      externalToken = csAppSlice.tokenDetails?.external.access_token;
      internalToken = csAppSlice.tokenDetails?.internal.authToken;
      adminId = csAppSlice.tokenDetails?.internal?.adminDetails?.adminId;
      source = csAppSlice?.deviceType;
    } catch (err) {}
    config.headers["source"] = source;
    config.headers["traceId"] = store.getState()?.CsAppSlice.traceId;
    config.headers["authToken"] = externalToken;
    config.headers["adminId"] = adminId;
    config.headers["Authorization"] = `Bearer ${internalToken}`;
    config.headers["pageName"] = window.location.pathname;

    if (config.method === "post" && JSON.parse(config.data)?.adminId) {
      config.data = { ...JSON.parse(config.data), adminId: adminId };
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
